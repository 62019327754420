// components/layout/Layout.jsx
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import Header from '../components/layout/Header';
import Sidebar from '../components/layout/Sidebar';
import { ProcessingErrorAlert } from '../components/ProcessingErrorAlert';
import UploadNotification from '../components/UploadNotification';
import GeneralUploadModal from '../components/GeneralUploadModal';
import HelpModal from '../components/HelpModal';
import { handleUpload } from '../utils/uploadHandlers';

const Layout = ({ children }) => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);
  const [processingErrors, setProcessingErrors] = useState([]);
  const [newCollaborations, setNewCollaborations] = useState(0);
  const [lastCheckedCollabs, setLastCheckedCollabs] = useState(
    localStorage.getItem('lastCheckedCollabs')
      ? new Date(localStorage.getItem('lastCheckedCollabs'))
      : new Date()
  );
  const [isUserLoaded, setIsUserLoaded] = useState(false);

  useEffect(() => {
    const isDark = localStorage.getItem('darkMode') === 'true';
    setIsDarkMode(isDark);
    if (isDark) {
      document.documentElement.classList.add('dark');
    }
  }, []);

  useEffect(() => {
    if (user?.id || user?._id) {
      setIsUserLoaded(true);
    }
  }, [user]);

  useEffect(() => {
    const checkNewCollaborations = async () => {
      if (!isUserLoaded || !user || (!user.id && !user._id)) {
        return;
      }

      try {
        const token = localStorage.getItem('token');
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/collaborative-documents`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) throw new Error('Failed to fetch documents');
        const documents = await response.json();

        const newCollabDocs = documents.filter((doc) => {
          const isCollaborator = doc.collaborators.some(
            (collab) => String(collab.userId?._id || collab.userId) === String(user.id || user._id)
          );
          const isNotOwner = String(doc.owner?._id || doc.owner) !== String(user.id || user._id);
          const isNewOrUpdated = new Date(doc.updatedAt) > new Date(lastCheckedCollabs);

          return isCollaborator && isNotOwner && isNewOrUpdated;
        });

        setNewCollaborations(newCollabDocs.length);
      } catch (err) {
        console.error('Failed to check new collaborations:', err);
      }
    };

    if (isUserLoaded) {
      checkNewCollaborations();
      const interval = setInterval(checkNewCollaborations, 60 * 1000);
      return () => clearInterval(interval);
    }
  }, [isUserLoaded, user, lastCheckedCollabs]);

  const handleLogout = useCallback(() => {
    try {
      logout();
      navigate('/login');
    } catch (error) {
      console.error('Logout error:', error);
    }
  }, [logout, navigate]);

  const handleFileUpload = useCallback(
    (file, projectId) => {
      handleUpload({
        file,
        projectId,
        setNotifications,
        setProcessingErrors,
        navigate,
        handleLogout,
      });
    },
    [navigate, handleLogout]
  );

  const dismissNotification = useCallback((id) => {
    setNotifications((prev) => prev.filter((n) => n.id !== id));
  }, []);

  const dismissProcessingError = useCallback((id) => {
    setProcessingErrors((prev) => prev.filter((error) => error.id !== id));
  }, []);

  return (
    <div className={`flex h-screen ${isDarkMode ? 'dark' : ''}`}>
      <Sidebar
        isOpen={isSidebarOpen}
        onClose={() => setIsSidebarOpen(false)}
        user={user}
        newCollaborations={newCollaborations}
        setLastCheckedCollabs={setLastCheckedCollabs}
        setNewCollaborations={setNewCollaborations}
        setIsUploadModalOpen={setIsUploadModalOpen}
      />

      <div className="flex-1 flex flex-col min-w-0">
        <Header
          toggleSidebar={() => setIsSidebarOpen(!isSidebarOpen)}
          isDarkMode={isDarkMode}
          toggleDarkMode={() => {
            setIsDarkMode(!isDarkMode);
            document.documentElement.classList.toggle('dark');
            localStorage.setItem('darkMode', (!isDarkMode).toString());
          }}
          notifications={notifications}
          user={user}
          handleLogout={handleLogout}
          setIsUploadModalOpen={setIsUploadModalOpen}
          setIsHelpModalOpen={setIsHelpModalOpen}
        />

        <main className="flex-1 overflow-auto bg-gray-50 dark:bg-gray-900">
          {processingErrors.length > 0 && (
            <div className="max-w-7xl mx-auto px-4 md:px-6 lg:px-8 pt-4">
              {processingErrors.map((error) => (
                <ProcessingErrorAlert
                  key={error.id}
                  fileName={error.fileName}
                  errorDetails={error.details}
                  onDismiss={() => dismissProcessingError(error.id)}
                />
              ))}
            </div>
          )}

          <div className="max-w-7xl mx-auto p-4 md:p-6 lg:p-8">{children}</div>
        </main>

        <div className="fixed bottom-4 right-4 space-y-2 z-50">
          {notifications.map((notification) => (
            <UploadNotification
              key={notification.id}
              notification={notification}
              onDismiss={() => dismissNotification(notification.id)}
            />
          ))}
        </div>
      </div>

      <GeneralUploadModal
        isOpen={isUploadModalOpen}
        onClose={() => setIsUploadModalOpen(false)}
        onUpload={handleFileUpload}
      />

      <HelpModal
        isOpen={isHelpModalOpen}
        onClose={() => setIsHelpModalOpen(false)}
      />
    </div>
  );
};

export default Layout;