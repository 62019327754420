import React from 'react';
import { Bot, Globe, Link } from 'lucide-react';

const WelcomeMessage = ({ isGeneral, isProjectWide, currentConversation }) => {
  return (
    <div className="flex items-center justify-center h-full max-w-2xl mx-auto px-4">
      <div className="text-center p-4 sm:p-6">
        {currentConversation?.isWebContent ? (
          <Globe size={40} className="text-indigo-600 dark:text-indigo-400 mb-4 mx-auto" />
        ) : (
          <Bot size={40} className="text-indigo-600 dark:text-indigo-400 mb-4 mx-auto" />
        )}
        <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">
          {isGeneral
            ? "General Chat"
            : isProjectWide
            ? "Project-Wide Chat"
            : currentConversation?.isWebContent
            ? currentConversation.fileName || "Web Content Chat"
            : `Document Chat: ${currentConversation?.fileName}`}
        </h3>
        {currentConversation?.isWebContent && currentConversation?.url && (
          <a
            href={currentConversation.url}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center justify-center gap-2 text-sm text-gray-500 hover:text-indigo-600 dark:text-gray-400 dark:hover:text-indigo-400 mb-4"
          >
            <Link size={16} />
            {new URL(currentConversation.url).hostname}
          </a>
        )}
        <p className="text-gray-500 dark:text-gray-400 text-sm">
          {isGeneral
            ? "Ask me anything! I'm here to help with any questions you have."
            : isProjectWide
            ? "Ask me anything about your project. I can search through all project documents to help answer your questions."
            : currentConversation?.isWebContent
            ? "Ask me anything about this webpage. I've analyzed its content and can help answer your questions."
            : "Ask me anything about this document. I'm here to help analyze and explain its contents."}
        </p>
      </div>
    </div>
  );
};

export default WelcomeMessage;