// components/layout/Footer.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import { Upload } from 'lucide-react';

const Footer = ({ setIsUploadModalOpen }) => {
  return (
    <div className="p-4 border-t border-gray-200/50 dark:border-gray-800/50 space-y-4 bg-gradient-to-t from-gray-50 dark:from-gray-800/50 pt-6">
      <button
        onClick={() => setIsUploadModalOpen(true)}
        className="flex items-center justify-center w-full p-2.5 bg-gradient-to-r from-violet-600 to-fuchsia-600 text-white rounded-xl hover:from-violet-700 hover:to-fuchsia-700 transition-all duration-300 shadow-lg shadow-violet-500/25"
      >
        <Upload size={20} />
        <span className="ml-2 font-medium">Upload Document</span>
      </button>

      <div className="text-xs text-gray-500 dark:text-gray-400 text-center space-y-2">
        <p className="font-medium">© 2024 Lambda AI</p>
        <div className="flex items-center justify-center gap-4">
          <Link
            to="/privacy-policy"
            className="hover:text-violet-500 dark:hover:text-violet-400 transition-colors"
          >
            Privacy Policy
          </Link>
          <Link
            to="/terms-of-service"
            className="hover:text-violet-500 dark:hover:text-violet-400 transition-colors"
          >
            Terms of Service
          </Link>
        </div>
        <p className="text-violet-500 dark:text-violet-400">
          <a href="mailto:lambdaa29@gmail.com">lambdaa29@gmail.com</a>
        </p>
      </div>
    </div>
  );
};

export default Footer;