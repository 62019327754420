import React from 'react';
import { Bot, ChevronLeft, Trash2 } from 'lucide-react';

const ChatHeader = ({ isGeneral, isProjectWide, currentConversation, onClearChat, hasMessages }) => {
  return (
    <div className="sticky top-0 z-20 bg-white dark:bg-gray-800 border-b border-gray-200 dark:border-gray-700 sm:hidden">
      <div className="flex items-center justify-between px-4 py-2">
        <div className="flex items-center gap-3">
          <button
            onClick={() => window.history.back()}
            className="p-1 -ml-1 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700"
          >
            <ChevronLeft size={20} className="text-gray-600 dark:text-gray-300" />
          </button>
          <h1 className="font-semibold text-gray-900 dark:text-gray-100 truncate">
            {isGeneral ? "General Chat" : isProjectWide ? "Project Chat" : currentConversation?.fileName || "Chat"}
          </h1>
        </div>
        <div className="flex items-center gap-2">
          {isProjectWide && hasMessages && (
            <button
              onClick={onClearChat}
              className="p-2 text-gray-600 dark:text-gray-400 hover:bg-red-50 dark:hover:bg-red-900/30 hover:text-red-600 dark:hover:text-red-400 rounded-lg transition-colors"
              title="Clear chat history"
            >
              <Trash2 size={16} />
            </button>
          )}
          <Bot size={20} className="text-indigo-600 dark:text-indigo-400" />
        </div>
      </div>
    </div>
  );
};

export default ChatHeader;