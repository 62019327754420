import React from 'react';
import { X } from 'lucide-react';

const HistoryModal = ({ isOpen, onClose, documentHistory }) => {
  if (!isOpen) return null;

  const formatDate = (dateString) => {
    return new Intl.DateTimeFormat('en-US', {
      dateStyle: 'medium',
      timeStyle: 'short'
    }).format(new Date(dateString));
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg w-[95%] max-w-2xl">
        <div className="flex items-center justify-between px-4 py-3 border-b border-gray-200 dark:border-gray-700">
          <h2 className="text-lg font-medium text-gray-900 dark:text-white">
            Document History
          </h2>
          <button
            onClick={onClose}
            className="p-2 text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 rounded-full transition-colors"
          >
            <X size={20} />
          </button>
        </div>
        <div className="max-h-[calc(100vh-12rem)] overflow-y-auto p-4">
          {documentHistory.map((change, index) => (
            <div
              key={change._id || index}
              className="py-3 border-b border-gray-200 dark:border-gray-700 last:border-0"
            >
              <div className="flex justify-between items-start">
                <div>
                  <p className="text-sm font-medium text-gray-900 dark:text-white">
                    Version {change.version}
                  </p>
                  <div className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                    {change.changes?.content && (
                      <div className="max-w-md overflow-hidden text-ellipsis">
                        Content updated
                      </div>
                    )}
                  </div>
                </div>
                <time className="text-sm text-gray-500 dark:text-gray-400">
                  {formatDate(change.timestamp)}
                </time>
              </div>
            </div>
          ))}

          {documentHistory.length === 0 && (
            <div className="text-center py-4 text-gray-500 dark:text-gray-400">
              No history available
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HistoryModal;