// components/layout/navigationItems.js
import {
    Database,
    FileText,
    Folders,
    Globe,
    Home,
    MessageSquare,
    Star,
    Users,
    Wand2
} from "lucide-react";

export const navigationItems = [
  {
    icon: Home,
    label: "Dashboard",
    path: "/dashboard",
    badge: "Admin",
    adminOnly: true,
  },
  {
    icon: FileText,
    label: "Documents",
    path: "/documents",
    badge: "Trending",
  },
  {
    icon: Wand2,
    label: "General Chat",
    path: "/general-chat",
    badge: "New",
  },
  {
    icon: Globe,
    label: "Web Content",
    path: "/webcontent",
    badge: "New",
  },
  {
    icon: Folders,
    label: "Projects",
    path: "/projects",
    disabled: false,
    badge: "New",
  },
  {
    icon: MessageSquare,
    label: "Chat",
    path: "/chat",
    badge: "Hot",
  },
  {
    icon: Users,
    label: "Collaborative",
    path: "/collaborative-documents",
    badge: "New",
  },
  {
    icon: Database,
    label: "Enterprise",
    path: "/database",
    badge: "Premium",
    disabled: true,
  },
  {
    icon: Users,
    label: "Team",
    path: "/team",
    disabled: true,
  },
  {
    icon: Star,
    label: "Favorites",
    path: "/favorites",
    disabled: true,
  },
];
