/**
 * Utility functions for copying formatted content to clipboard
 */

/**
 * Process cell content to handle markdown formatting
 */
const processCellContent = (content) => {
    return content
      // Handle bold + italic combinations first
      .replace(/\*\*\*([^*]+)\*\*\*/g, '<strong><em>$1</em></strong>')
      // Then handle bold
      .replace(/\*\*([^*]+)\*\*/g, '<strong>$1</strong>')
      // Then handle italic
      .replace(/\*([^*]+)\*/g, '<em>$1</em>')
      // Handle inline code
      .replace(/`([^`]+)`/g, '<code style="background-color: #f6f8fa; padding: 2px 4px; border-radius: 3px; font-family: monospace;">$1</code>')
      // Handle links
      .replace(/\[([^\]]+)\]\(([^)]+)\)/g, '<a href="$2">$1</a>');
  };
  
  /**
   * Parse markdown table into structured data
   */
  const parseMarkdownTable = (markdown) => {
    const lines = markdown.split('\n').filter(line => line.trim());
    if (lines.length < 2) return null;
  
    // Check if it's a table (contains | character)
    if (!lines[0].includes('|')) return null;
  
    // Parse headers
    const headers = lines[0]
      .split('|')
      .filter(cell => cell.trim())
      .map(cell => cell.trim());
  
    // Skip separator line
    const rows = lines.slice(2)
      .filter(line => line.includes('|'))
      .map(line => 
        line
          .split('|')
          .filter(cell => cell.trim())
          .map(cell => cell.trim())
      );
  
    return { headers, rows };
  };
  
  /**
   * Format table for HTML
   */
  const formatHTMLTable = (tableData) => {
    if (!tableData) return '';
    const { headers, rows } = tableData;
  
    const tableStyle = 'style="border-collapse: collapse; width: 100%;"';
    const headerCellStyle = 'style="border: 1px solid #666; padding: 8px; text-align: left; background: #f5f5f5;"';
    const cellStyle = 'style="border: 1px solid #666; padding: 8px; text-align: left;"';
  
    // Process headers with formatting
    const headerCells = headers
      .map(header => `<th ${headerCellStyle}>${processCellContent(header)}</th>`)
      .join('');
  
    // Process data cells with formatting
    const dataCells = rows
      .map(row => 
        `<tr>${row
          .map(cell => `<td ${cellStyle}>${processCellContent(cell)}</td>`)
          .join('')}</tr>`
      )
      .join('');
  
    return `
  <table ${tableStyle}>
    <thead>
      <tr>${headerCells}</tr>
    </thead>
    <tbody>
      ${dataCells}
    </tbody>
  </table>`;
  };
  
  /**
   * Format table for plain text
   */
  const formatPlainTextTable = (tableData) => {
    if (!tableData) return '';
    const { headers, rows } = tableData;
  
    // Clean formatting for width calculation
    const cleanContent = (text) => {
      return text
        .replace(/\*\*\*([^*]+)\*\*\*/g, '$1')
        .replace(/\*\*([^*]+)\*\*/g, '$1')
        .replace(/\*([^*]+)\*/g, '$1')
        .replace(/`([^`]+)`/g, '$1')
        .replace(/\[([^\]]+)\]\([^)]+\)/g, '$1');
    };
  
    // Calculate column widths using cleaned content
    const columnWidths = headers.map((header, index) => {
      const cellLengths = [
        cleanContent(header).length,
        ...rows.map(row => cleanContent(row[index] || '').length)
      ];
      return Math.max(...cellLengths);
    });
  
    // Format headers
    const headerRow = headers
      .map((header, i) => cleanContent(header).padEnd(columnWidths[i]))
      .join(' | ');
  
    // Format data rows
    const formattedRows = rows
      .map(row =>
        row
          .map((cell, i) => cleanContent(cell).padEnd(columnWidths[i]))
          .join(' | ')
      );
  
    const separator = columnWidths.map(width => '-'.repeat(width)).join('-+-');
  
    return [headerRow, separator, ...formattedRows].join('\n');
  };
  
  /**
   * Convert markdown to clean HTML with proper formatting
   */
  const convertToHTML = (markdown) => {
    let html = `<!DOCTYPE html><html><head><meta charset="utf-8"></head><body>`;
    
    // First handle tables
    const tableMatch = markdown.match(/\|.*\|[\s\S]*?\n\s*\n/g);
    if (tableMatch) {
      for (const tableContent of tableMatch) {
        const tableData = parseMarkdownTable(tableContent);
        if (tableData) {
          markdown = markdown.replace(
            tableContent,
            formatHTMLTable(tableData)
          );
        }
      }
    }
  
    // Process the rest of the markdown into HTML with proper styling
    html += markdown
      // Headers
      .replace(/^# (.*$)/gm, '<h1 style="font-size: 24px; font-weight: bold; margin: 16px 0;">$1</h1>')
      .replace(/^## (.*$)/gm, '<h2 style="font-size: 20px; font-weight: bold; margin: 14px 0;">$1</h2>')
      .replace(/^### (.*$)/gm, '<h3 style="font-size: 18px; font-weight: bold; margin: 12px 0;">$1</h3>')
      
      // Bold and Italic
      .replace(/\*\*\*([^*]+)\*\*\*/g, '<strong><em>$1</em></strong>')
      .replace(/\*\*([^*]+)\*\*/g, '<strong>$1</strong>')
      .replace(/\*([^*]+)\*/g, '<em>$1</em>')
      
      // Code blocks
      .replace(/```([\s\S]*?)```/g, (match, code) => 
        `<pre style="background-color: #f6f8fa; padding: 16px; border-radius: 6px; font-family: monospace;">${code}</pre>`
      )
      
      // Inline code
      .replace(/`([^`]+)`/g, '<code style="background-color: #f6f8fa; padding: 2px 4px; border-radius: 3px; font-family: monospace;">$1</code>')
      
      // Lists
      .replace(/^\s*[-*+]\s+(.*)$/gm, '<li style="margin: 4px 0;">$1</li>')
      .replace(/((^|\n)<li.*<\/li>\n?)+/g, '<ul style="margin: 8px 0; padding-left: 24px;">$&</ul>')
      
      // Numbered lists
      .replace(/^\d+\.\s+(.*)$/gm, '<li style="margin: 4px 0;">$1</li>')
      .replace(/((^|\n)<li.*<\/li>\n?)+/g, (match) => 
        match.includes('1.') ? `<ol style="margin: 8px 0; padding-left: 24px;">${match}</ol>` : match
      )
      
      // Blockquotes
      .replace(/^\s*>\s*(.*)$/gm, '<blockquote style="margin: 8px 0; padding-left: 16px; border-left: 4px solid #ddd;">$1</blockquote>')
      
      // Links
      .replace(/\[([^\]]+)\]\(([^)]+)\)/g, '<a href="$2">$1</a>')
      
      // Paragraphs
      .replace(/^(?!<[houbt]).*$/gm, (match) => 
        match.trim() ? `<p style="margin: 8px 0; line-height: 1.5;">${match}</p>` : ''
      )
      
      // Clean up extra newlines
      .replace(/\n{3,}/g, '\n\n');
  
    html += '</body></html>';
    return html;
  };
  
  /**
   * Convert markdown to plain text
   */
  const convertToPlainText = (markdown) => {
    // First check for tables
    const tableMatch = markdown.match(/\|.*\|[\s\S]*?\n\s*\n/g);
    if (tableMatch) {
      for (const tableContent of tableMatch) {
        const tableData = parseMarkdownTable(tableContent);
        if (tableData) {
          markdown = markdown.replace(
            tableContent,
            formatPlainTextTable(tableData)
          );
        }
      }
    }
  
    return markdown
      .replace(/^#{1,6}\s/gm, '')
      .replace(/\*\*\*([^*]+)\*\*\*/g, '$1')
      .replace(/\*\*([^*]+)\*\*/g, '$1')
      .replace(/\*([^*]+)\*/g, '$1')
      .replace(/`([^`]+)`/g, '$1')
      .replace(/^\s*[-*+]\s/gm, '• ')
      .replace(/^\s*\d+\.\s/gm, (match) => match)
      .replace(/\[([^\]]+)\]\(([^)]+)\)/g, '$1 ($2)')
      .replace(/\n{3,}/g, '\n\n')
      .trim();
  };
  
  /**
   * Main function to copy formatted content to clipboard
   */
  const copyWithFormatting = async (markdown) => {
    if (!markdown) return '';
    
    try {
      const plainText = convertToPlainText(markdown);
      const htmlContent = convertToHTML(markdown);
      
      const clipboardItem = new ClipboardItem({
        'text/plain': new Blob([plainText], { type: 'text/plain' }),
        'text/html': new Blob([htmlContent], { type: 'text/html' })
      });
      
      await navigator.clipboard.write([clipboardItem]);
      return true;
    } catch (err) {
      console.error('Copy failed:', err);
      // Fallback to basic text copy
      await navigator.clipboard.writeText(convertToPlainText(markdown));
      return false;
    }
  };
  
  export { copyWithFormatting };