// src/components/DocumentEditor/components/EditorFooter.jsx
import React, { useState } from 'react';
import { AlertCircle } from 'lucide-react';

export const EditorFooter = ({ onClose, onDownload }) => {
  const [format, setFormat] = useState('docx');
  const [isExporting, setIsExporting] = useState(false);
  const [error, setError] = useState(null);

  const handleDownload = async () => {
    try {
      setIsExporting(true);
      setError(null);
      await onDownload(format);
    } catch (err) {
      setError('Failed to export document. Please try again.');
    } finally {
      setIsExporting(false);
    }
  };

  return (
    <div className="border-t border-gray-200 dark:border-gray-700 p-4">
      {error && (
        <div className="mb-4 text-sm text-red-600 dark:text-red-400 flex items-center gap-2">
          <AlertCircle className="w-4 h-4" />
          {error}
        </div>
      )}
      
      <div className="flex justify-end items-center gap-3">
        <select
          value={format}
          onChange={(e) => setFormat(e.target.value)}
          className="px-3 py-2 bg-white dark:bg-gray-800 border border-gray-300 
            dark:border-gray-700 rounded-lg text-sm focus:ring-2 focus:ring-indigo-500 
            dark:focus:ring-indigo-400 focus:border-transparent"
        >
          <option value="docx">Word Document (.docx)</option>
          <option value="pdf">PDF Document (.pdf)</option>
          <option value="html">HTML Document (.html)</option>
        </select>

        <button
          onClick={onClose}
          className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-300 
            hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg transition-colors"
        >
          Cancel
        </button>

        <button
          onClick={handleDownload}
          disabled={isExporting}
          className={`px-4 py-2 text-sm font-medium text-white rounded-lg transition-colors
            ${isExporting 
              ? 'bg-indigo-400 cursor-not-allowed'
              : 'bg-indigo-600 hover:bg-indigo-700 dark:bg-indigo-500 dark:hover:bg-indigo-600'
            }`}
        >
          {isExporting ? (
            <span className="flex items-center gap-2">
              <svg className="animate-spin h-4 w-4" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none" />
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
              </svg>
              Exporting...
            </span>
          ) : (
            'Export'
          )}
        </button>
      </div>
    </div>
  );
};