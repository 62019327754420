// src/components/DocumentEditor/components/HeaderActions.jsx
import React from "react";
import { Users, X } from "lucide-react";

export const HeaderActions = ({ activeUsers, onClose }) => (
  <div className="flex items-center gap-2">
    <button
      className="p-2 text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-800 rounded-lg relative"
      title="Collaborators"
    >
      <Users className="w-4 h-4" />
      {activeUsers.length > 0 && (
        <span className="absolute -top-1 -right-1 w-4 h-4 bg-indigo-600 text-white text-xs flex items-center justify-center rounded-full">
          {activeUsers.length}
        </span>
      )}
    </button>
    <button
      onClick={onClose}
      className="p-2 text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-800 rounded-lg"
      title="Close"
    >
      <X className="w-4 h-4" />
    </button>
  </div>
);
