import { useState, useCallback, useEffect } from 'react';

const MAX_IMAGE_SIZE = 5 * 1024 * 1024;
const ACCEPTED_IMAGE_TYPES = ["image/jpeg", "image/png", "image/gif", "image/webp"];

export const useChat = ({ conversationId, projectId, isProjectWide, isGeneral, onChatUpdate }) => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [regenerating, setRegenerating] = useState(false);
  const [relevantDocs, setRelevantDocs] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [error, setError] = useState(null);

  const handleImageSelect = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    if (!ACCEPTED_IMAGE_TYPES.includes(file.type)) {
      setError("Please select a valid image file (JPEG, PNG, GIF, or WebP)");
      return;
    }

    if (file.size > MAX_IMAGE_SIZE) {
      setError("Image size must be less than 5MB");
      return;
    }

    setSelectedImage(file);
    const reader = new FileReader();
    reader.onloadend = () => setImagePreview(reader.result);
    reader.readAsDataURL(file);
  };

  const removeSelectedImage = () => {
    setSelectedImage(null);
    setImagePreview(null);
  };

  const clearChatHistory = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/projectwide/chat/${conversationId}/clear`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.error);
      }

      setMessages([]);
      setRelevantDocs([]);
      if (onChatUpdate) {
        onChatUpdate();
      }
    } catch (error) {
      throw error;
    }
  };

  const fetchChatHistory = useCallback(async () => {
    const token = localStorage.getItem("token");
    let endpoint;

    if (isGeneral) {
      endpoint = `/query/chat-history/${conversationId}`;
    } else if (projectId && isProjectWide) {
      endpoint = `/projectwide/chat/${projectId}/history`;
    } else if (conversationId && conversationId.startsWith("web-")) {
      endpoint = `/webcontent/chat/${conversationId}/history`;
    } else {
      endpoint = `/query/history/${conversationId}`;
    }

    const response = await fetch(`${process.env.REACT_APP_API_URL}${endpoint}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    const data = await response.json();
    if (!response.ok) throw new Error(data.error);

    const filteredMessages = (data.history || []).filter(m => m.question || m.answer);
    setMessages(filteredMessages);

    if (data.warning) {
      setError(data.warning);
    }
  }, [conversationId, projectId, isProjectWide, isGeneral]);

  useEffect(() => {
    fetchChatHistory();
    setRelevantDocs([]);
  }, [fetchChatHistory]);

  const regenerateResponse = async (questionText) => {
    setRegenerating(true);
    setError(null);

    try {
      // ... existing regenerateResponse logic ...
    } catch (error) {
      throw error;
    } finally {
      setRegenerating(false);
    }
  };

  return {
    messages,
    setMessages,
    loading,
    setLoading,
    regenerating,
    relevantDocs,
    setRelevantDocs,
    selectedImage,
    imagePreview,
    error,
    setError,
    handleImageSelect,
    removeSelectedImage,
    clearChatHistory,
    regenerateResponse,
  };
};
