// utils/uploadHandlers.js
export const handleUpload = async ({
  file,
  projectId,
  setNotifications,
  setProcessingErrors,
  navigate,
  handleLogout,
}) => {
  const notificationId = Date.now().toString();

  // Show upload progress
  setNotifications((prev) => [
    ...prev,
    {
      id: notificationId,
      title: "Uploading Document",
      message: file.name,
      status: "processing",
      progress: 0,
    },
  ]);

  const formData = new FormData();
  formData.append("document", file);

  if (projectId) {
    formData.append("projectId", projectId);
  }

  try {
    const token = localStorage.getItem("token");
    const endpoint = projectId
      ? "/projectwide/documents/upload"
      : "/documents/upload";

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}${endpoint}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      }
    );

    if (response.status === 401) {
      handleLogout();
      return;
    }

    const data = await response.json();
    if (!response.ok) throw new Error(data.error);

    // Update notification to show processing
    setNotifications((prev) =>
      prev.map((n) =>
        n.id === notificationId
          ? {
              id: notificationId,
              title: "Processing Document",
              message: `${file.name} is being processed`,
              status: "processing",
              progress: 0,
            }
          : n
      )
    );

    // Start monitoring processing status
    const checkProcessingStatus = async () => {
      try {
        const statusResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/documents/processing-status/${data.conversationId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const statusData = await statusResponse.json();
        console.log('Processing status data:', statusData);

        // Update notifications based on processing status
        if (statusData.status === "completed") {
          setNotifications((prev) =>
            prev.map((n) =>
              n.id === notificationId
                ? {
                    ...n,
                    title: "Processing Completed",
                    message: "Document processing completed successfully",
                    status: "completed",
                    progress: 100,
                  }
                : n
            )
          );

          // Navigate to chat page
          if (projectId) {
            navigate(`/projects/${projectId}`);
          } else {
            navigate(`/chat/${data.conversationId}`);
          }
        } else if (statusData.status === "failed") {
          setNotifications((prev) =>
            prev.map((n) =>
              n.id === notificationId
                ? {
                    ...n,
                    title: "Processing Failed",
                    message: statusData.errorDetails?.message || "Document processing failed",
                    status: "error",
                    errorDetails: statusData.errorDetails,
                  }
                : n
            )
          );

          // Add to processing errors
          setProcessingErrors((prev) => [
            ...prev,
            {
              id: notificationId,
              fileName: file.name,
              details: statusData.errorDetails?.message || "Document processing failed",
            },
          ]);
        } else if (statusData.error) {
          // Handle 'Conversation not found' error
          setNotifications((prev) =>
            prev.map((n) =>
              n.id === notificationId
                ? {
                    ...n,
                    title: "Processing Failed",
                    message: statusData.error.details,
                    status: "error",
                    errorDetails: {
                      type: 'UNKNOWN_ERROR',
                      message: statusData.error.details,
                    },
                  }
                : n
            )
          );

          // Add to processing errors
          setProcessingErrors((prev) => [
            ...prev,
            {
              id: notificationId,
              fileName: file.name,
              details: statusData.error.details,
            },
          ]);
        } else {
          // Continue checking if still processing
          setTimeout(checkProcessingStatus, 5000);
        }
      } catch (error) {
        // Handle fetch errors
        setNotifications((prev) =>
          prev.map((n) =>
            n.id === notificationId
              ? {
                  ...n,
                  title: "Processing Error",
                  message: error.message,
                  status: "error",
                }
              : n
          )
        );
      }
    };

    // Start checking processing status
    checkProcessingStatus();
  } catch (error) {
    // Show upload error
    setNotifications((prev) =>
      prev.map((n) =>
        n.id === notificationId
          ? {
              id: notificationId,
              title: "Upload Failed",
              message: error.message || "An error occurred during upload",
              status: "error",
            }
          : n
      )
    );
  }
};