import { AlertCircle, Loader, X } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const ProgressBar = ({ progress }) => (
  <div className="w-full bg-gray-200 dark:bg-gray-700 rounded-full h-2 mb-2">
    <div 
      className="bg-indigo-600 dark:bg-indigo-500 h-2 rounded-full transition-all duration-300"
      style={{ width: `${progress}%` }}
    />
  </div>
);

const AddWebsiteModal = ({ isOpen, onClose, onSuccess }) => {
  const navigate = useNavigate();
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [contentId, setContentId] = useState(null);
  const [processingTime, setProcessingTime] = useState(0);
  const [processingStatus, setProcessingStatus] = useState(null);
  const [statusCheckFailed, setStatusCheckFailed] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let timer;
    let statusCheck;
    let failedChecks = 0;
    const MAX_FAILED_CHECKS = 3;

    if (processingStatus === "processing" && contentId) {
      timer = setInterval(() => {
        setProcessingTime((prev) => prev + 1);
      }, 1000);

      statusCheck = setInterval(async () => {
        try {
          const token = localStorage.getItem("token");
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/webcontent/${contentId}?status=true`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );

          if (!response.ok) throw new Error("Failed to check status");
          const data = await response.json();

          const mainContent = data.mainContent;
          setProgress(mainContent.progress || 0);

          if (mainContent.processingStatus === "completed") {
            clearInterval(statusCheck);
            clearInterval(timer);
            setProcessingStatus("completed");
            onSuccess();
            const chatId = `web-${contentId}`;
            navigate(`/chat/${chatId}`);
          } else if (mainContent.processingStatus === "failed") {
            clearInterval(statusCheck);
            clearInterval(timer);
            setProcessingStatus("failed");
            setError(
              mainContent.errorDetails?.message ||
                "Processing failed. Please try again."
            );
            setLoading(false);
          }
        } catch (error) {
          failedChecks++;
          console.error("Status check error:", error);
          if (failedChecks >= MAX_FAILED_CHECKS) {
            clearInterval(statusCheck);
            clearInterval(timer);
            setStatusCheckFailed(true);
            setError(
              "Failed to check processing status. Please try again later."
            );
            setLoading(false);
          }
        }
      }, 2000);
    }

    return () => {
      if (timer) clearInterval(timer);
      if (statusCheck) clearInterval(statusCheck);
    };
  }, [processingStatus, contentId, navigate, onSuccess]);

  const handleClose = () => {
    if (!loading || error || statusCheckFailed || processingStatus === "failed") {
      setUrl("");
      setError(null);
      setContentId(null);
      setProcessingTime(0);
      setProcessingStatus(null);
      setStatusCheckFailed(false);
      setProgress(0);
      onClose();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);
    setProcessingTime(0);
    setProcessingStatus(null);
    setContentId(null);
    setStatusCheckFailed(false);
    setProgress(0);

    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/webcontent/scrape`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ url }),
        }
      );

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.error || "Failed to add website");
      }

      const data = await response.json();
      if (data.contentId) {
        setContentId(data.contentId);
        setProcessingStatus("processing");
      }
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs.toString().padStart(2, "0")}`;
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-md w-full mx-4">
        <div className="flex items-center justify-between p-4 border-b border-gray-200 dark:border-gray-700">
          <h2 className="text-lg font-semibold text-gray-900 dark:text-white">
            Add Website
          </h2>
          <button
            onClick={handleClose}
            className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
            disabled={loading && !error && !statusCheckFailed && processingStatus !== "failed"}
            title={loading && !error && !statusCheckFailed ? "Processing in progress..." : "Close"}
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="p-4 space-y-4">
          <div>
            <label
              htmlFor="url"
              className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1"
            >
              Website URL
            </label>
            <input
              type="url"
              id="url"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              placeholder="https://example.com"
              className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-700 dark:text-white text-sm"
              required
              disabled={loading}
            />
          </div>

          {error && (
            <div className="flex items-center gap-2 text-sm text-red-600 dark:text-red-400 bg-red-50 dark:bg-red-900/30 p-3 rounded-md">
              <AlertCircle className="w-4 h-4 flex-shrink-0" />
              <span>{error}</span>
            </div>
          )}

          {processingStatus === "processing" && (
            <div className="text-sm text-indigo-600 dark:text-indigo-400 bg-indigo-50 dark:bg-indigo-900/30 p-3 rounded-md">
              <div className="flex items-center mb-2">
                <Loader className="w-4 h-4 animate-spin mr-2" />
                <span>Processing website content...</span>
              </div>
              <ProgressBar progress={progress} />
              <div className="flex justify-between text-xs text-indigo-500 dark:text-indigo-300">
                <span>Progress: {progress}%</span>
                <span>Time elapsed: {formatTime(processingTime)}</span>
              </div>
            </div>
          )}

          <div className="flex justify-end gap-3">
            <button
              type="button"
              onClick={handleClose}
              className="px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-md"
              disabled={loading && !error && !statusCheckFailed && processingStatus !== "failed"}
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={loading}
              className="px-4 py-2 text-sm bg-indigo-600 text-white rounded-md hover:bg-indigo-700 disabled:opacity-50 flex items-center"
            >
              {loading ? (
                <>
                  <Loader className="w-4 h-4 animate-spin mr-2" />
                  Processing...
                </>
              ) : (
                "Add Website"
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddWebsiteModal;