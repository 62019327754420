import { Loader, Upload, X, Folder, Trash2, CheckCircle, AlertCircle, XCircle } from "lucide-react";
import React, { useCallback, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import {
  validateFile,
  getFileIcon,
  getFileCategory,
  generateAcceptedTypes
} from '../utils/fileConfig';

// Constants
const MAX_FILES = 10;
const MAX_TOTAL_SIZE = 50 * 1024 * 1024; // 50MB total limit
const MAX_POLLING_ATTEMPTS = 60; // 5 minutes with 5-second intervals

const UploadModal = ({ isOpen, onClose, projectId, projectName, onUploadComplete }) => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);
  const [dragActive, setDragActive] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({});
  const [errors, setErrors] = useState({});
  
  // New state for status tracking
  const [showStatusView, setShowStatusView] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fileStatuses, setFileStatuses] = useState({});
  const [overallStatus, setOverallStatus] = useState('processing');
  const [pollingCount, setPollingCount] = useState(0);


  const resetState = useCallback(() => {
    setFiles([]);
    setErrors({});
    setDragActive(false);
    setUploadProgress({});
    setShowStatusView(false);
    setUploadedFiles([]);
    setFileStatuses({});
    setOverallStatus('processing');
    setPollingCount(0);
  }, []);

  useEffect(() => {
    if (!isOpen) {
      resetState();
    }
  }, [isOpen, resetState]);

  // Status polling effect
useEffect(() => {
  if (!showStatusView || !uploadedFiles.length) return;

  const pollStatus = async () => {
    try {
      const token = localStorage.getItem('token');
      const promises = uploadedFiles.map(file =>
        fetch(`${process.env.REACT_APP_API_URL}/projectwide/documents/${file.documentId}/status`, {
          headers: { Authorization: `Bearer ${token}` }
        }).then(res => res.json())
      );

      const results = await Promise.all(promises);
      
      const newStatuses = { ...fileStatuses };
      let allCompleted = true;
      let anyFailed = false;

      results.forEach((result, index) => {
        const documentId = uploadedFiles[index].documentId;
        newStatuses[documentId] = {
          fileName: uploadedFiles[index].fileName,
          status: result.status,
          progress: result.progress
        };

        if (result.status === 'processing') allCompleted = false;
        if (result.status === 'failed') anyFailed = true;
      });

      setFileStatuses(newStatuses);
      
      if (allCompleted) {
        setOverallStatus(anyFailed ? 'completed_with_errors' : 'completed');
      } else if (pollingCount >= MAX_POLLING_ATTEMPTS) {
        setOverallStatus('timeout');
      }

      if (!allCompleted && pollingCount < MAX_POLLING_ATTEMPTS) {
        setPollingCount(prev => prev + 1);
      }
    } catch (error) {
      console.error('Error polling status:', error);
      setOverallStatus('error');
    }
  };

  const pollInterval = setInterval(pollStatus, 5000);
  pollStatus(); // Initial poll

  return () => clearInterval(pollInterval);
}, [showStatusView, uploadedFiles, fileStatuses, pollingCount]);
  const validateTotalSize = (newFiles) => {
    const totalSize = newFiles.reduce((sum, file) => sum + file.size, 0);
    if (totalSize > MAX_TOTAL_SIZE) {
      throw new Error(`Total size exceeds ${MAX_TOTAL_SIZE / 1024 / 1024}MB limit`);
    }
  };

  const handleFileValidation = (selectedFiles) => {
    const newFiles = [...files];
    const newErrors = {...errors};
    
    try {
      validateTotalSize([...files, ...selectedFiles]);
      
      Array.from(selectedFiles).forEach(file => {
        if (newFiles.length >= MAX_FILES) {
          newErrors.general = `Maximum ${MAX_FILES} files allowed`;
          return;
        }
        
        try {
          const validation = validateFile(file);
          if (validation.isValid) {
            if (!newFiles.some(f => f.name === file.name)) {
              newFiles.push(file);
            }
          } else {
            newErrors[file.name] = validation.error;
          }
        } catch (err) {
          newErrors[file.name] = err.message;
        }
      });
    } catch (err) {
      newErrors.general = err.message;
    }

    setFiles(newFiles);
    setErrors(newErrors);
  };

  const removeFile = (fileName) => {
    setFiles(files.filter(file => file.name !== fileName));
    const newErrors = {...errors};
    delete newErrors[fileName];
    setErrors(newErrors);
    
    const newProgress = {...uploadProgress};
    delete newProgress[fileName];
    setUploadProgress(newProgress);
  };

  const handleUpload = async () => {
    if (!files.length || !projectId) return;

    setUploading(true);
    setErrors({});

    try {
      const formData = new FormData();
      formData.append("projectId", projectId);
      
      files.forEach(file => {
        formData.append("documents", file);
      });

      const initialProgress = {};
      files.forEach(file => {
        initialProgress[file.name] = 0;
      });
      setUploadProgress(initialProgress);

      const token = localStorage.getItem("token");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/projectwide/documents/upload`,
        {
          method: "POST",
          headers: { 
            Authorization: `Bearer ${token}`
          },
          body: formData
        }
      );

      if (response.status === 401) {
        throw new Error("Unauthorized");
      }

      const data = await response.json();
      if (!response.ok) throw new Error(data.error);

      // Switch to status view and initialize tracking
      setUploadedFiles(data.uploads);
      setShowStatusView(true);
      
      const initialStatuses = {};
      data.uploads.forEach(file => {
        initialStatuses[file.documentId] = {
          fileName: file.fileName,
          status: 'processing',
          progress: 0
        };
      });
      setFileStatuses(initialStatuses);

    } catch (error) {
      if (error.message === "Unauthorized") {
        logout();
        navigate("/login");
        return;
      }
      setErrors({ general: error.message });
      setUploading(false);
    }
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case 'completed':
        return <CheckCircle className="h-5 w-5 text-green-500" />;
      case 'failed':
        return <XCircle className="h-5 w-5 text-red-500" />;
      case 'processing':
        return <Loader className="h-5 w-5 text-blue-500 animate-spin" />;
      default:
        return <AlertCircle className="h-5 w-5 text-yellow-500" />;
    }
  };

  const FileList = () => (
    <div className="space-y-2 max-h-64 overflow-y-auto pr-2">
      {files.map((file) => (
        <div 
          key={file.name} 
          className="flex items-center justify-between bg-gray-50 dark:bg-gray-700/50 p-2.5 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors"
        >
          <div className="flex items-center gap-3 min-w-0">
            {React.createElement(getFileIcon(file.name), {
              size: 20,
              className: "text-blue-500 flex-shrink-0"
            })}
            <div className="flex flex-col min-w-0">
              <span className="text-sm font-medium text-gray-900 dark:text-white truncate">
                {file.name}
              </span>
              <div className="flex items-center gap-2">
                <span className="text-xs text-gray-500 dark:text-gray-400">
                  {getFileCategory(file.name)} - {(file.size / 1024).toFixed(1)}KB
                </span>
                {uploadProgress[file.name] > 0 && uploadProgress[file.name] < 100 && (
                  <div className="h-1 w-16 bg-gray-200 rounded-full overflow-hidden">
                    <div 
                      className="h-full bg-blue-500 transition-all duration-300"
                      style={{ width: `${uploadProgress[file.name]}%` }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <button
            onClick={() => removeFile(file.name)}
            disabled={uploading}
            className="p-1 hover:bg-gray-200 dark:hover:bg-gray-600 rounded ml-2 flex-shrink-0 disabled:opacity-50"
          >
            <Trash2 size={16} className="text-gray-500 dark:text-gray-400" />
          </button>
        </div>
      ))}
    </div>
  );

  const canClose = !showStatusView || overallStatus !== 'processing';
  const handleClose = () => {
    if (canClose) {
      if (overallStatus === 'completed' || overallStatus === 'completed_with_errors') {
        onUploadComplete?.();
      }
      onClose();
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl w-full max-w-lg m-4">
        <div className="flex justify-between items-center p-4 border-b dark:border-gray-700">
          <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
            {showStatusView ? 'Processing Files' : 'Upload Documents'}
          </h2>
          {canClose && (
            <button
              onClick={handleClose}
              className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg"
            >
              <X size={20} className="text-gray-500 dark:text-gray-400" />
            </button>
          )}
        </div>

        {!showStatusView ? (
          <>
            <div className="px-4 py-3 bg-gray-50 dark:bg-gray-700/50 border-b dark:border-gray-700">
              <div className="flex items-center gap-2">
                <div className="p-1.5 bg-indigo-100 dark:bg-indigo-900/30 rounded-md">
                  <Folder size={16} className="text-indigo-600 dark:text-indigo-400" />
                </div>
                <div>
                  <p className="text-sm text-gray-600 dark:text-gray-300">Uploading to project:</p>
                  <p className="text-sm font-medium text-gray-900 dark:text-white">{projectName}</p>
                </div>
              </div>
            </div>

            <div className="p-4 space-y-4">
              <div
                className={`border-2 border-dashed rounded-lg ${
                  dragActive
                    ? "border-blue-500 bg-blue-50 dark:bg-blue-900/20"
                    : "border-gray-300 dark:border-gray-700"
                }`}
                onDragEnter={(e) => {
                  e.preventDefault();
                  setDragActive(true);
                }}
                onDragLeave={(e) => {
                  e.preventDefault();
                  setDragActive(false);
                }}
                onDragOver={(e) => {
                  e.preventDefault();
                  setDragActive(true);
                }}
                onDrop={(e) => {
                  e.preventDefault();
                  setDragActive(false);
                  handleFileValidation(e.dataTransfer.files);
                }}
              >
                <div className="p-4">
                  {files.length > 0 ? (
                    <FileList />
                  ) : (
                    <div className="text-center py-4">
                      <Upload className="mx-auto h-12 w-12 text-gray-400" />
                      <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                        Drop your files here, or click to browse
                      </p>
                    </div>
                  )}
                  
                  <div className="mt-4 text-center">
                    <input
                      type="file"
                      className="hidden"
                      onChange={(e) => {
                        if (e.target.files?.length) {
                          handleFileValidation(e.target.files);
                        }
                      }}
                      id="file-upload"
                      multiple
                      accept={generateAcceptedTypes()}
                      disabled={uploading}
                    />
                    <label
                      htmlFor="file-upload"
                      className={`cursor-pointer inline-flex items-center px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm text-sm font-medium text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 ${uploading ? 'opacity-50 cursor-not-allowed' : ''}`}
                    >
                      Browse files
                    </label>
                    <p className="text-xs text-gray-500 dark:text-gray-400 mt-2">
                      Upload up to {MAX_FILES} files (max {MAX_TOTAL_SIZE / 1024 / 1024}MB total)
                    </p>
                  </div>
                </div>
              </div>

              {Object.entries(errors).map(([key, error]) => (
                <div key={key} className="p-3 bg-red-50 dark:bg-red-900/30 text-red-600 dark:text-red-400 rounded-lg text-sm">
                  {key === 'general' ? error : `${key}: ${error}`}
                </div>
              ))}

              <div className="flex justify-end gap-3 pt-2">
                <button
                  onClick={handleClose}
                  disabled={uploading}
                  className="px-4 py-2 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg disabled:opacity-50"
                >
                  Cancel
                </button>
                <button
                  onClick={handleUpload}
                  disabled={!files.length || uploading}
                  className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50 flex items-center gap-2"
                >
                  {uploading ? (
                    <>
                      <Loader className="h-4 w-4 animate-spin" />
                      Uploading...
                    </>
                  ) : (
                    <>
                      <Upload className="h-4 w-4" />
                      Upload {files.length ? `(${files.length})` : ''}
                    </>
                  )}
                </button>
              </div>
            </div>
          </>
        ) : (
          <div className="p-4 space-y-4">
            {/* Status Alerts */}
            {overallStatus === 'timeout' && (
              <div className="p-4 rounded-lg bg-yellow-50 dark:bg-yellow-900/30 border border-yellow-200 dark:border-yellow-900">
                <div className="flex gap-3">
                  <AlertCircle className="h-5 w-5 text-yellow-600 dark:text-yellow-500 flex-shrink-0" />
                  <div>
                    <h3 className="text-sm font-medium text-yellow-800 dark:text-yellow-300">
                      Processing Timeout
                    </h3>
                    <p className="text-sm text-yellow-700 dark:text-yellow-400 mt-1">
                      Some files are taking longer than expected to process. You can close this window and check the status later.
                    </p>
                  </div>
                </div>
              </div>
            )}

            {overallStatus === 'error' && (
              <div className="p-4 rounded-lg bg-red-50 dark:bg-red-900/30 border border-red-200 dark:border-red-900">
                <div className="flex gap-3">
                  <AlertCircle className="h-5 w-5 text-red-600 dark:text-red-500 flex-shrink-0" />
                  <div>
                    <h3 className="text-sm font-medium text-red-800 dark:text-red-300">
                      Error
                    </h3>
                    <p className="text-sm text-red-700 dark:text-red-400 mt-1">
                      An error occurred while processing the files. Please try again.
                    </p>
                  </div>
                </div>
              </div>
            )}

            {/* File Status List */}
            <div className="space-y-3 max-h-64 overflow-y-auto">
              {Object.entries(fileStatuses).map(([documentId, file]) => (
                <div
                  key={documentId}
                  className="flex items-center justify-between p-3 bg-gray-50 dark:bg-gray-700/50 rounded-lg"
                >
                  <div className="flex items-center gap-3 min-w-0">
                    {getStatusIcon(file.status)}
                    <div className="flex flex-col min-w-0">
                      <span className="text-sm font-medium text-gray-900 dark:text-white truncate">
                        {file.fileName}
                      </span>
                      <span className="text-xs text-gray-500 dark:text-gray-400">
                        {file.status === 'processing' ? `${file.progress}% complete` : file.status}
                      </span>
                    </div>
                  </div>
                  {file.status === 'processing' && (
                    <div className="w-20 bg-gray-200 dark:bg-gray-600 rounded-full h-1.5 overflow-hidden">
                      <div 
                        className="h-full bg-blue-500 transition-all duration-300"
                        style={{ width: `${file.progress}%` }}
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>

            {/* Action Buttons */}
            <div className="flex justify-end gap-3 pt-2">
              {canClose ? (
                <button
                  onClick={handleClose}
                  className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                >
                  Close
                </button>
              ) : (
                <button
                  disabled
                  className="px-4 py-2 bg-gray-100 dark:bg-gray-700 text-gray-400 dark:text-gray-500 rounded-lg cursor-not-allowed flex items-center gap-2"
                >
                  <Loader className="animate-spin h-4 w-4" />
                  Processing...
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UploadModal;