// src/components/DocumentEditor/components/ErrorDisplay.jsx
import React from 'react';
import { AlertCircle } from 'lucide-react';

export const ErrorDisplay = ({ error }) => (
  <div className="fixed bottom-20 left-1/2 transform -translate-x-1/2 bg-red-50 dark:bg-red-900/30 text-red-600 dark:text-red-400 px-4 py-2 rounded-lg shadow-lg flex items-center gap-2">
    <AlertCircle className="h-4 w-4" />
    <span className="text-sm">{error}</span>
  </div>
);
