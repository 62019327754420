import React, { useState } from 'react';
import { Database, History, Table, Terminal } from 'lucide-react';
import DatabaseConnection from './DatabaseConnection';

const DatabasePage = () => {
  const [queryHistory, setQueryHistory] = useState([]);
  const [activeTab, setActiveTab] = useState('connection');
  const [currentResults, setCurrentResults] = useState(null);

  const handleQueryExecute = async (query) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${process.env.REACT_APP_API_URL}/database/query`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ query })
      });

      const data = await response.json();
      if (!response.ok) throw new Error(data.error);

      setCurrentResults(data.results);
      setQueryHistory(prev => [...prev, {
        query: data.query,
        timestamp: new Date(),
        status: 'success',
        rowCount: data.results.length
      }]);
    } catch (error) {
      setQueryHistory(prev => [...prev, {
        query,
        timestamp: new Date(),
        status: 'error',
        error: error.message
      }]);
    }
  };

  const tabs = [
    { id: 'connection', label: 'Connection', icon: Database },
    { id: 'history', label: 'History', icon: History },
    { id: 'results', label: 'Results', icon: Table, disabled: !currentResults }
  ];

  return (
    <div className="h-full">
      <div className="mb-6">
        <h1 className="text-2xl font-semibold text-gray-900 dark:text-white">Database Integration</h1>
        <p className="mt-2 text-gray-600 dark:text-gray-400">
          Connect to your database and execute queries using natural language.
        </p>
      </div>

      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm overflow-hidden">
        <div className="border-b border-gray-200 dark:border-gray-700">
          <div className="flex">
            {tabs.map((tab) => (
              <button
                key={tab.id}
                onClick={() => !tab.disabled && setActiveTab(tab.id)}
                disabled={tab.disabled}
                className={`flex items-center gap-2 px-4 py-3 text-sm font-medium ${
                  activeTab === tab.id
                    ? 'border-b-2 border-indigo-500 text-indigo-600 dark:text-indigo-400'
                    : 'text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300'
                } ${tab.disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
              >
                {React.createElement(tab.icon, { size: 16 })}
                {tab.label}
              </button>
            ))}
          </div>
        </div>

        <div className="p-6">
          {activeTab === 'connection' && (
            <DatabaseConnection onQueryExecute={handleQueryExecute} />
          )}

          {activeTab === 'history' && (
            <div className="space-y-4">
              {queryHistory.length === 0 ? (
                <div className="text-center py-12">
                  <Terminal className="mx-auto h-12 w-12 text-gray-400" />
                  <h3 className="mt-2 text-sm font-medium text-gray-900 dark:text-white">No queries yet</h3>
                  <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                    Start by connecting to a database and executing some queries.
                  </p>
                </div>
              ) : (
                <div className="space-y-4">
                  {queryHistory.map((item, index) => (
                    <div
                      key={index}
                      className="p-4 bg-gray-50 dark:bg-gray-900 rounded-lg"
                    >
                      <div className="flex items-center justify-between">
                        <span className="text-xs text-gray-500 dark:text-gray-400">
                          {new Date(item.timestamp).toLocaleString()}
                        </span>
                        <span
                          className={`text-xs px-2 py-1 rounded-full ${
                            item.status === 'success'
                              ? 'bg-green-100 text-green-600 dark:bg-green-900 dark:text-green-400'
                              : 'bg-red-100 text-red-600 dark:bg-red-900 dark:text-red-400'
                          }`}
                        >
                          {item.status === 'success' ? `${item.rowCount} rows` : 'Error'}
                        </span>
                      </div>
                      <pre className="mt-2 text-sm text-gray-700 dark:text-gray-300 overflow-x-auto">
                        {item.query}
                      </pre>
                      {item.error && (
                        <p className="mt-2 text-sm text-red-600 dark:text-red-400">
                          {item.error}
                        </p>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}

          {activeTab === 'results' && currentResults && (
            <div className="overflow-x-auto">
              {currentResults.length > 0 ? (
                <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                  <thead className="bg-gray-50 dark:bg-gray-900">
                    <tr>
                      {Object.keys(currentResults[0]).map((key) => (
                        <th
                          key={key}
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider"
                        >
                          {key}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
                    {currentResults.map((row, i) => (
                      <tr key={i}>
                        {Object.values(row).map((value, j) => (
                          <td
                            key={j}
                            className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white"
                          >
                            {value?.toString() || 'null'}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p className="text-center py-4 text-gray-500 dark:text-gray-400">
                  No results to display
                </p>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DatabasePage;