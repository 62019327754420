import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 py-12">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-8">
          <h1 className="text-3xl font-bold text-gray-900 dark:text-white mb-8">Privacy Policy</h1>
          
          <div className="space-y-6 text-gray-600 dark:text-gray-300">
            <section>
              <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">1. Information Collection and Use</h2>
              <p>We collect information when you:</p>
              <ul className="list-disc pl-5 mt-2 space-y-2">
                <li>Register on our platform</li>
                <li>Upload documents for processing</li>
                <li>Use our AI chat features</li>
                <li>Connect with Google services</li>
              </ul>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">2. Google Integration</h2>
              <p>When you use our Google Docs integration:</p>
              <ul className="list-disc pl-5 mt-2 space-y-2">
                <li>We request access to create and edit Google Docs</li>
                <li>We only access documents we create on your behalf</li>
                <li>We do not store your Google credentials</li>
                <li>You can revoke access at any time</li>
              </ul>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">3. Data Storage and Security</h2>
              <p>We protect your data by:</p>
              <ul className="list-disc pl-5 mt-2 space-y-2">
                <li>Using encryption for data transmission</li>
                <li>Securely storing your documents</li>
                <li>Limiting access to authorized personnel</li>
                <li>Regular security audits</li>
              </ul>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">4. Contact</h2>
              <p>For privacy concerns, contact us at:</p>
              <p className="mt-2">lambdaa29@gmail.com</p>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;