import React, { useState, useEffect } from "react";
import { useAuth } from "../../context/AuthContext";
import {
  Plus,
  File,
  Loader,
  Search,
  ChevronDown,
} from "lucide-react";
import {
  HistoryModal,
  CollaboratorModal,
  ConfirmationModal,
  DocumentEditorModal
} from "./modals";
import DocumentCard from "../cards/DocumentCard";

// Utility function
const isOwner = (user, owner) => {
  if (!user || !owner) return false;
  return (
    user._id === owner._id ||
    String(user._id) === String(owner._id) ||
    user.id === owner._id ||
    String(user.id) === String(owner._id)
  );
};

const CollaborativeDocumentList = () => {
  // State Management
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState("updatedAt");
  
  // UI State
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [isDocumentEditorOpen, setIsDocumentEditorOpen] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [documentHistory, setDocumentHistory] = useState([]);
  const [showCollaboratorModal, setShowCollaboratorModal] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [documentToDelete, setDocumentToDelete] = useState(null);
  
  // Collaborator Management State
  const [newCollaboratorEmail, setNewCollaboratorEmail] = useState("");
  const [collaboratorRole, setCollaboratorRole] = useState("editor");
  const [selectedCollaborator, setSelectedCollaborator] = useState(null);
  const [showRemoveCollaboratorConfirm, setShowRemoveCollaboratorConfirm] = useState(false);

  const { user } = useAuth();

  // API Calls
  const fetchDocuments = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/collaborative-documents`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) throw new Error("Failed to fetch documents");
      const data = await response.json();
      setDocuments(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const createNewDocument = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/collaborative-documents`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            title: "Untitled Document",
            content: "",
          }),
        }
      );

      if (!response.ok) throw new Error("Failed to create document");
      const newDoc = await response.json();
      setDocuments([newDoc, ...documents]);
      handleDocumentClick(newDoc);
    } catch (err) {
      setError(err.message);
    }
  };

  const deleteDocument = async (documentId) => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/collaborative-documents/${documentId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) throw new Error("Failed to delete document");
      setDocuments(documents.filter((doc) => doc.documentId !== documentId));
      setShowDeleteConfirm(false);
      setDocumentToDelete(null);
    } catch (err) {
      setError(err.message);
    }
  };

  const fetchDocumentHistory = async (documentId) => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/collaborative-documents/${documentId}/history`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) throw new Error("Failed to fetch document history");
      const data = await response.json();
      setDocumentHistory(data);
      setShowHistory(true);
    } catch (err) {
      setError(err.message);
    }
  };

  // Collaborator Management
  const addCollaborator = async (documentId) => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/collaborative-documents/${documentId}/collaborators`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: newCollaboratorEmail,
            role: collaboratorRole,
          }),
        }
      );

      if (!response.ok) throw new Error("Failed to add collaborator");
      const updatedDoc = await response.json();
      setDocuments(
        documents.map((doc) =>
          doc.documentId === documentId ? updatedDoc : doc
        )
      );
      setShowCollaboratorModal(false);
      setNewCollaboratorEmail("");
      setCollaboratorRole("editor");
    } catch (err) {
      setError(err.message);
    }
  };

  const removeCollaborator = async (documentId, userId) => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/collaborative-documents/${documentId}/collaborators/${userId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) throw new Error("Failed to remove collaborator");
      const updatedDoc = await response.json();
      setDocuments(
        documents.map((doc) =>
          doc.documentId === documentId ? updatedDoc : doc
        )
      );
      setShowRemoveCollaboratorConfirm(false);
      setSelectedCollaborator(null);
    } catch (err) {
      setError(err.message);
    }
  };

  // Event Handlers
  const handleDocumentClick = (doc) => {
    setSelectedDocument(doc);
    setIsDocumentEditorOpen(true);
  };

  const handleDeleteClick = (doc) => {
    setDocumentToDelete(doc);
    setShowDeleteConfirm(true);
  };

  const handleMenuAction = (action, doc) => {
    switch (action) {
      case 'history':
        fetchDocumentHistory(doc.documentId);
        break;
      case 'addCollaborator':
        setSelectedDocument(doc);
        setShowCollaboratorModal(true);
        break;
      case 'delete':
        handleDeleteClick(doc);
        break;
      default:
        break;
    }
  };

  const handleRemoveCollaboratorClick = (doc, collaborator) => {
    setSelectedDocument(doc);
    setSelectedCollaborator(collaborator);
    setShowRemoveCollaboratorConfirm(true);
  };

  const closeDocumentEditor = () => {
    setIsDocumentEditorOpen(false);
    setSelectedDocument(null);
  };

  // Filter and Sort
  const filteredDocuments = documents
    .filter(
      (doc) =>
        doc.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        doc.owner?.firstName?.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => {
      if (sortBy === "title") {
        return a.title.localeCompare(b.title);
      }
      const dateA = new Date(sortBy === "updatedAt" ? (a.lastModified || a.updatedAt) : a[sortBy]);
      const dateB = new Date(sortBy === "updatedAt" ? (b.lastModified || b.updatedAt) : b[sortBy]);
      return dateB - dateA;
    });

  // Effects
  useEffect(() => {
    fetchDocuments();
  }, []);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Loader className="w-6 h-6 animate-spin text-indigo-600" />
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 py-6">
      {/* Header Section */}
      <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4 mb-6">
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
          Collaborative Documents
        </h1>
        <button
          onClick={createNewDocument}
          className="flex items-center justify-center gap-2 px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
        >
          <Plus className="w-4 h-4" />
          New Document
        </button>
      </div>

      {/* Search and Sort Section */}
      <div className="mb-6 flex flex-col sm:flex-row gap-4">
        <div className="relative flex-1">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-4 h-4" />
          <input
            type="text"
            placeholder="Search documents..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full pl-10 pr-4 py-2 border border-gray-200 dark:border-gray-700 rounded-lg focus:ring-2 focus:ring-indigo-500 dark:bg-gray-800 dark:text-white"
          />
        </div>
        <div className="relative w-full sm:w-48">
          <select
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value)}
            className="appearance-none w-full px-4 py-2 border border-gray-200 dark:border-gray-700 rounded-lg focus:ring-2 focus:ring-indigo-500 dark:bg-gray-800 dark:text-white pr-10"
          >
            <option value="updatedAt">Last Modified</option>
            <option value="createdAt">Created Date</option>
            <option value="title">Title</option>
          </select>
          <ChevronDown className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-4 h-4 pointer-events-none" />
        </div>
      </div>

      {/* Error Display */}
      {error && (
        <div className="mb-6 p-4 bg-red-50 dark:bg-red-900/30 text-red-600 dark:text-red-400 rounded-lg">
          {error}
        </div>
      )}

      {/* Document List */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {filteredDocuments.map((doc) => (
          <DocumentCard
            key={doc.documentId}
            doc={doc}
            user={user}
            onDocumentClick={handleDocumentClick}
            onMenuAction={handleMenuAction}
            isOwner={isOwner}
          />
        ))}

        {filteredDocuments.length === 0 && (
          <div className="col-span-full text-center py-12">
            <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-gray-100 dark:bg-gray-800 mb-4">
              <File className="w-8 h-8 text-gray-400" />
            </div>
            <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-1">
              No documents found
            </h3>
            <p className="text-gray-500 dark:text-gray-400">
              {searchTerm 
                ? "Try adjusting your search terms"
                : "Create your first document to get started"
              }
            </p>
          </div>
        )}
      </div>

      {/* Modals */}
      <HistoryModal
        isOpen={showHistory}
        onClose={() => setShowHistory(false)}
        documentHistory={documentHistory}
      />

      <CollaboratorModal
        isOpen={showCollaboratorModal}
        onClose={() => setShowCollaboratorModal(false)}
        email={newCollaboratorEmail}
        setEmail={setNewCollaboratorEmail}
        role={collaboratorRole}
        setRole={setCollaboratorRole}
        onAdd={() => addCollaborator(selectedDocument?.documentId)}
      />

      <ConfirmationModal
        isOpen={showDeleteConfirm}
        onClose={() => {
          setShowDeleteConfirm(false);
          setDocumentToDelete(null);
        }}
        onConfirm={() => deleteDocument(documentToDelete?.documentId)}
        title="Delete Document"
        message={`Are you sure you want to delete "${documentToDelete?.title}"? This action cannot be undone.`}
        confirmText="Delete"
        confirmButtonClass="bg-red-600 hover:bg-red-700"
      />

      <ConfirmationModal
        isOpen={showRemoveCollaboratorConfirm}
        onClose={() => {
          setShowRemoveCollaboratorConfirm(false);
          setSelectedCollaborator(null);
        }}
        onConfirm={() => removeCollaborator(selectedDocument?.documentId, selectedCollaborator?.userId)}
        title="Remove Collaborator"
        message={`Are you sure you want to remove ${selectedCollaborator?.firstName} ${selectedCollaborator?.lastName} from "${selectedDocument?.title}"?`}
        confirmText="Remove"
        confirmButtonClass="bg-red-600 hover:bg-red-700"
      />

      <DocumentEditorModal
        isOpen={isDocumentEditorOpen}
        document={selectedDocument}
        onClose={closeDocumentEditor}
      />
    </div>
  );
};

export default CollaborativeDocumentList;