import React, { useState, useCallback, useEffect } from 'react';
import { 
  FileText, 
  MessageSquare,
  MoreVertical,
  Folder,
  Calendar,
  ChevronRight,
  Edit2,
  Trash2
} from 'lucide-react';
import DeleteConfirmationModal from './DeleteConfirmationModal';

const ActionMenu = ({
  isOpen,
  onClose,
  onEdit,
  onDelete,
  isLoading,
  projectTitle
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleDeleteClick = (e) => {
    e.stopPropagation();
    setShowDeleteModal(true);
    onClose();
  };

  if (!isOpen && !showDeleteModal) return null;

  return (
    <>
      {isOpen && (
        <div
          className="absolute right-0 mt-1 w-48 bg-white/80 backdrop-blur-sm dark:bg-gray-800/90 rounded-xl shadow-lg border border-gray-200/50 dark:border-gray-700/50 py-1 z-10"
          onClick={(e) => e.stopPropagation()}
        >
          <button
            onClick={(e) => {
              e.stopPropagation();
              onEdit();
              onClose();
            }}
            className="w-full px-4 py-2 text-left text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100/50 dark:hover:bg-gray-700/50 flex items-center gap-2 rounded-lg mx-1"
          >
            <Edit2 className="w-4 h-4" />
            Edit Project
          </button>
          <button
            onClick={handleDeleteClick}
            disabled={isLoading}
            className="w-full px-4 py-2 text-left text-sm text-red-600 dark:text-red-400 hover:bg-red-50/50 dark:hover:bg-red-900/30 flex items-center gap-2 rounded-lg mx-1 mt-1"
          >
            <Trash2 className="w-4 h-4" />
            Delete Project
          </button>
        </div>
      )}

      <DeleteConfirmationModal
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={onDelete}
        loading={isLoading}
        title={projectTitle}
      />
    </>
  );
};

const ProjectCard = ({ project, onClick, onEdit, onDelete }) => {
  const [loading, setLoading] = useState(false);
  const [showActions, setShowActions] = useState(false);

  const handleClickOutside = useCallback(
    (e) => {
      if (showActions) setShowActions(false);
    },
    [showActions]
  );

  useEffect(() => {
    if (showActions) {
      document.addEventListener("click", handleClickOutside);
      return () => document.removeEventListener("click", handleClickOutside);
    }
  }, [showActions, handleClickOutside]);

  const handleDelete = async () => {
    setLoading(true);
    try {
      await onDelete(project);
    } catch (error) {
      console.error("Delete error:", error);
    } finally {
      setLoading(false);
      setShowActions(false);
    }
  };

  const formatDate = (date) => {
    const now = new Date();
    const projectDate = new Date(date);
    const diffTime = Math.abs(now - projectDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays === 0) return 'Today';
    if (diffDays === 1) return 'Yesterday';
    if (diffDays < 7) return `${diffDays} days ago`;
    return projectDate.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: projectDate.getFullYear() !== now.getFullYear() ? 'numeric' : undefined
    });
  };

  return (
    <div 
      onClick={onClick}
      className="group relative bg-gradient-to-br from-white to-gray-50 dark:from-gray-800 dark:to-gray-900 rounded-xl shadow-sm border border-gray-200/50 dark:border-gray-700/50 hover:border-violet-500 dark:hover:border-violet-500/50 transition-all duration-200 hover:shadow-lg hover:shadow-violet-500/10"
    >
      <div className="p-4 relative overflow-hidden">
        {/* Decorative gradient blob */}
        <div className="absolute -top-12 -right-12 w-24 h-24 bg-gradient-to-br from-violet-500/20 to-fuchsia-500/20 blur-2xl rounded-full" />
        
        <div className="flex items-start justify-between mb-3 relative">
          <div className="flex items-start gap-3">
            <div className="p-2 bg-gradient-to-br from-violet-500 to-fuchsia-500 rounded-lg shadow-sm">
              <Folder className="h-5 w-5 text-white" />
            </div>
            <div className="flex-1 min-w-0">
              <h3 className="font-medium text-gray-900 dark:text-white truncate group-hover:text-violet-500 dark:group-hover:text-violet-400 transition-colors">
                {project.name}
              </h3>
              {project.description && (
                <p className="mt-1 text-sm text-gray-500 dark:text-gray-400 line-clamp-1">
                  {project.description}
                </p>
              )}
            </div>
          </div>

          <div className="relative flex-shrink-0">
            <button
              onClick={(e) => {
                e.stopPropagation();
                setShowActions(!showActions);
              }}
              className="p-1.5 hover:bg-gray-100 dark:hover:bg-gray-700/50 rounded-lg transition-colors"
            >
              <MoreVertical className="w-4 h-4 text-gray-500 dark:text-gray-400" />
            </button>
            <ActionMenu
              isOpen={showActions}
              onClose={() => setShowActions(false)}
              onEdit={() => onEdit(project)}
              onDelete={handleDelete}
              isLoading={loading}
              projectTitle={project.name}
            />
          </div>
        </div>

        <div className="flex items-center gap-4 text-sm">
          <div className="flex items-center gap-1.5 text-gray-500 dark:text-gray-400 hover:text-violet-500 dark:hover:text-violet-400 transition-colors">
            <FileText className="w-4 h-4" />
            <span>{project.documentCount || 0} documents</span>
          </div>
          <div className="flex items-center gap-1.5 text-gray-500 dark:text-gray-400 hover:text-violet-500 dark:hover:text-violet-400 transition-colors">
            <MessageSquare className="w-4 h-4" />
            <span>{project.projectWideConversation?.history?.length || 0} messages</span>
          </div>
        </div>

        <div className="mt-4 pt-4 border-t border-gray-100 dark:border-gray-800 flex items-center justify-between text-sm">
          <div className="flex items-center gap-1.5 text-gray-500 dark:text-gray-400">
            <Calendar className="w-4 h-4" />
            {formatDate(project.createdAt)}
          </div>
          <span className="flex items-center cursor-pointer font-medium text-violet-600 dark:text-violet-400 group-hover:translate-x-0.5 transition-all duration-300">
            View Project
            <ChevronRight className="w-4 h-4 ml-1 group-hover:ml-2 transition-all duration-300" />
          </span>
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;