import React, { useRef } from 'react';
import { ImageIcon, Loader, Send, Smile, X } from 'lucide-react';

const ChatInput = ({
  inputMessage,
  setInputMessage,
  selectedImage,
  onImageSelect,
  onRemoveImage,
  loading,
  onSubmit,
  isGeneral,
  isProjectWide
}) => {
  const inputRef = useRef(null);
  const fileInputRef = useRef(null);

  return (
    <form
      onSubmit={onSubmit}
      className="sticky bottom-0 border-t border-gray-200 dark:border-gray-800 bg-white dark:bg-gray-900 p-2 sm:p-4 z-10"
    >
      <div className="max-w-3xl mx-auto relative">
        {selectedImage && (
          <div className="mb-2 p-2 bg-gray-100 dark:bg-gray-800 rounded-lg">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <ImageIcon size={16} className="text-gray-500" />
                <span className="text-sm text-gray-600 dark:text-gray-300 truncate">
                  {selectedImage.name}
                </span>
              </div>
              <button
                type="button"
                onClick={onRemoveImage}
                className="p-1 hover:bg-gray-200 dark:hover:bg-gray-700 rounded-full"
              >
                <X size={14} className="text-gray-500" />
              </button>
            </div>
          </div>
        )}

        <div className="relative flex items-center gap-2">
          <input
            type="file"
            ref={fileInputRef}
            accept="image/*"
            onChange={onImageSelect}
            className="hidden"
          />
          <button
            type="button"
            onClick={() => fileInputRef.current?.click()}
            className="p-1.5 sm:p-2 text-gray-400 hover:text-gray-600 dark:text-gray-500 dark:hover:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-full transition-colors cursor-pointer"
          >
            <ImageIcon size={18} />
          </button>
          <button
            type="button"
            className="p-1.5 sm:p-2 text-gray-400 hover:text-gray-600 dark:text-gray-500 dark:hover:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-full transition-colors cursor-pointer"
          >
            <Smile size={18} />
          </button>
          <div className="relative flex-1">
            <textarea
              ref={inputRef}
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              placeholder={
                isGeneral
                  ? "Ask me anything..."
                  : isProjectWide
                  ? "Ask about your project..."
                  : "Ask about this document..."
              }
              className="w-full p-3 pr-12 bg-white dark:bg-gray-800 border border-gray-200 
              dark:border-gray-700 rounded-lg focus:outline-none focus:ring-2 
              focus:ring-indigo-500 dark:focus:ring-indigo-400 resize-none text-sm 
              transition-all duration-200 text-gray-900 dark:text-white 
              placeholder-gray-500 dark:placeholder-gray-400"
              style={{ minHeight: "40px", maxHeight: "120px" }}
              disabled={loading}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault();
                  if ((inputMessage.trim() || selectedImage) && !loading) {
                    onSubmit(e);
                  }
                }
              }}
            />
            <button
              type="submit"
              disabled={loading || (!inputMessage.trim() && !selectedImage)}
              className="absolute right-2 top-1/2 -translate-y-1/2 p-1.5 bg-indigo-600 
              text-white rounded-full hover:bg-indigo-700 dark:hover:bg-indigo-500 
              disabled:opacity-50 disabled:cursor-not-allowed transition-colors cursor-pointer
              focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:focus:ring-offset-gray-800"
            >
              {loading ? (
                <Loader className="h-4 w-4 animate-spin" />
              ) : (
                <Send size={14} />
              )}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ChatInput;