import React from 'react';

const TermsOfService = () => {
  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 py-12">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-8">
          <h1 className="text-3xl font-bold text-gray-900 dark:text-white mb-8">Terms of Service</h1>
          
          <div className="space-y-6 text-gray-600 dark:text-gray-300">
            <section>
              <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">1. Terms of Use</h2>
              <p>By accessing Lambda AI, you agree to:</p>
              <ul className="list-disc pl-5 mt-2 space-y-2">
                <li>Use the service legally and responsibly</li>
                <li>Not misuse or attempt to damage the service</li>
                <li>Maintain the security of your account</li>
                <li>Comply with all applicable laws and regulations</li>
              </ul>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">2. Service Usage</h2>
              <p>Our service provides:</p>
              <ul className="list-disc pl-5 mt-2 space-y-2">
                <li>Document processing and analysis</li>
                <li>AI-powered chat interactions</li>
                <li>Google Docs integration</li>
                <li>Project management features</li>
              </ul>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">3. User Obligations</h2>
              <p>Users must:</p>
              <ul className="list-disc pl-5 mt-2 space-y-2">
                <li>Provide accurate registration information</li>
                <li>Protect account credentials</li>
                <li>Not share accounts with others</li>
                <li>Report security concerns promptly</li>
              </ul>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">4. Limitations</h2>
              <p>Lambda AI reserves the right to:</p>
              <ul className="list-disc pl-5 mt-2 space-y-2">
                <li>Modify or terminate services</li>
                <li>Remove content that violates terms</li>
                <li>Suspend accounts for violations</li>
                <li>Update these terms as needed</li>
              </ul>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">5. Contact</h2>
              <p>For questions about these terms:</p>
              <p className="mt-2">lambdaa29@gmail.com</p>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsOfService;