import React from 'react';
import { Bot, User, Loader } from 'lucide-react';
import MessageActions from './MessageActions';
import ResponseRenderer from '../ResponseRenderer';
import { formatTime } from '../../utils/dateUtils';
import RelevantDocuments from '../relevant-docs/RelevantDocuments';

const ChatMessage = ({
  message,
  index,
  isLoading,
  isRegenerating,
  onCopy,
  onShare,
  onRegenerate,
  onDownload,
  onClear,
  relevantDocs,
  isProjectWide,
  user,
  imagePreview,
  isLastMessage,
  getLoadingMessage
}) => {
  const renderMessageContent = (message) => (
    <div className="space-y-2">
      <p className="text-sm leading-relaxed break-words">{message.question}</p>
      {message.hasImage && (
        <div className="mt-2 relative">
          <img
            src={imagePreview}
            alt="Uploaded content"
            className="max-w-xs rounded-lg"
          />
        </div>
      )}
    </div>
  );

  const renderAIResponse = () => {
    if ((isLoading && isLastMessage) || (isRegenerating && isLastMessage)) {
      return (
        <div className="flex items-center gap-2">
          <Loader className="h-4 w-4 animate-spin text-gray-500 dark:text-gray-400" />
          <p className="text-xs italic text-gray-500 dark:text-gray-400">
            {isRegenerating ? "Regenerating response..." : getLoadingMessage()}
          </p>
        </div>
      );
    }

    return (
      <div className="text-gray-900 dark:text-gray-100 break-words text-sm">
        <ResponseRenderer content={message.answer || ""} />
        {isLastMessage && relevantDocs.length > 0 && (
          <RelevantDocuments docs={relevantDocs} />
        )}
      </div>
    );
  };

  return (
    <div className="space-y-4 sm:space-y-6">
      {/* User Message */}
      <div className="flex flex-col items-end gap-1 sm:gap-2 group">
        <div className="flex items-start justify-end gap-2 w-full">
          <div className="flex flex-col items-end">
            <div className="bg-indigo-600 text-white rounded-2xl rounded-tr-none py-2 px-3 sm:px-4 max-w-[85%] sm:max-w-[90%] shadow-sm">
              {renderMessageContent(message)}
            </div>
            <span className="text-[10px] sm:text-xs text-gray-400 dark:text-gray-500 mt-1">
              {user.firstName} • {formatTime(message.timestamp)}
            </span>
          </div>
          <div className="w-6 h-6 sm:w-8 sm:h-8 bg-indigo-600 bg-opacity-10 dark:bg-opacity-20 rounded-full flex items-center justify-center flex-shrink-0">
            <User size={14} className="text-indigo-600 dark:text-indigo-400" />
          </div>
        </div>
      </div>

      {/* AI Response */}
      <div className="flex items-start gap-2 group">
        <div className="w-6 h-6 sm:w-8 sm:h-8 bg-purple-100 dark:bg-purple-900 rounded-full flex items-center justify-center flex-shrink-0">
          <Bot size={14} className="text-purple-600 dark:text-purple-400" />
        </div>
        <div className="flex flex-col flex-1 min-w-0">
          <div className="bg-white dark:bg-gray-800 rounded-2xl rounded-tl-none py-2 px-3 sm:px-4 max-w-[85%] sm:max-w-[90%] shadow-sm">
            {renderAIResponse()}
          </div>
          <div className="flex items-center gap-2 mt-1">
            <span className="text-[10px] sm:text-xs text-gray-400 dark:text-gray-500">
              Lambda AI • {formatTime(message.timestamp)}
            </span>
            <MessageActions
              onCopy={() => onCopy(message.answer)}
              onShare={() => onShare(message.question, message.answer)}
              onRegenerate={() => onRegenerate(message.question)}
              onDownload={() => onDownload(message.answer)}
              onClear={onClear}
              isResponse
              isRegenerating={isRegenerating && isLastMessage}
              content={message.answer}
              documentIds={relevantDocs.map((doc) => doc.id)}
              isProjectWide={isProjectWide}
              isLastMessage={isLastMessage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatMessage;