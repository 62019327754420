import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Check, X } from 'lucide-react';
import CodeBlock from './CodeBlock';

// Extended language map
const languageMap = {
  js: "javascript",
  jsx: "jsx",
  py: "python",
  html: "html",
  java: "java",
  cpp: "cpp",
  "c++": "cpp",
  ts: "typescript",
  tsx: "tsx",
  css: "css",
  ruby: "ruby",
  rb: "ruby",
  go: "go",
  rust: "rust",
  php: "php",
  swift: "swift",
  kotlin: "kotlin",
  scala: "scala",
  r: "r",
  shell: "bash",
  bash: "bash",
  sql: "sql",
  yaml: "yaml",
  yml: "yaml",
  json: "json",
  xml: "xml",
  markdown: "markdown",
  md: "markdown",
  dockerfile: "dockerfile",
  graphql: "graphql",
  matlab: "matlab",
  perl: "perl",
  powershell: "powershell",
  sass: "sass",
  scss: "scss",
  solidity: "solidity",
  toml: "toml",
  vue: "vue",
  wasm: "wasm",
};

// Task list item component
const TaskListItem = ({ checked, children }) => (
  <li className="flex items-start space-x-2 -ml-6">
    <div className="flex items-center h-6 mt-1">
      {checked ? (
        <Check className="w-4 h-4 text-green-500" />
      ) : (
        <X className="w-4 h-4 text-gray-400" />
      )}
    </div>
    <div className="flex-1">{children}</div>
  </li>
);

// Definition list components
const DefinitionList = ({ children }) => (
  <dl className="space-y-4 my-4">
    {children}
  </dl>
);

const DefinitionTerm = ({ children }) => (
  <dt className="font-semibold text-gray-900 dark:text-white">
    {children}
  </dt>
);

const DefinitionDescription = ({ children }) => (
  <dd className="pl-4 text-gray-700 dark:text-gray-300">
    {children}
  </dd>
);

// Enhanced code component with more features
const CodeComponent = React.memo(({ inline, className, children, meta }) => {
  if (inline || !className) {
    return (
      <code className="px-1 py-0.5 rounded bg-gray-100 dark:bg-gray-800 text-gray-800 dark:text-gray-200 text-sm sm:text-sm font-mono">
        {children}
      </code>
    );
  }

  const match = /language-(\w+)/.exec(className || "");
  const lang = match && match[1] ? match[1].toLowerCase() : "";
  const language = languageMap[lang] || lang || "text";
  
  // Parse meta information (e.g., ```js {1-3} title="example.js")
  const metaInfo = meta ? parseCodeMeta(meta) : {};

  return (
    <div className="relative group">
      {metaInfo.title && (
        <div className="text-xs text-gray-500 dark:text-gray-400 mb-1">
          {metaInfo.title}
        </div>
      )}
      <CodeBlock
        language={language}
        value={String(children).replace(/\n$/, "")}
        highlightLines={metaInfo.highlightLines}
        showLineNumbers={metaInfo.showLineNumbers}
      />
    </div>
  );
});

CodeComponent.displayName = 'CodeComponent';

// Helper function to parse code block meta information
const parseCodeMeta = (meta) => {
  const result = {
    title: null,
    highlightLines: [],
    showLineNumbers: false,
  };

  if (!meta) return result;

  // Match title="example.js" or title='example.js'
  const titleMatch = meta.match(/title=["'](.+?)["']/);
  if (titleMatch) {
    result.title = titleMatch[1];
  }

  // Match line numbers {1,3-5}
  const lineMatch = meta.match(/{(.+?)}/);
  if (lineMatch) {
    result.highlightLines = parseLineNumbers(lineMatch[1]);
  }

  // Check for line numbers flag
  if (meta.includes('lineNumbers')) {
    result.showLineNumbers = true;
  }

  return result;
};

// Helper function to parse line numbers from string like "1,3-5"
const parseLineNumbers = (str) => {
  const numbers = [];
  str.split(',').forEach(part => {
    if (part.includes('-')) {
      const [start, end] = part.split('-').map(Number);
      for (let i = start; i <= end; i++) {
        numbers.push(i);
      }
    } else {
      numbers.push(Number(part));
    }
  });
  return numbers;
};

const components = {
  code: CodeComponent,
  
  // Support for task lists
  li: ({ checked, children, ...props }) => {
    if (typeof checked === 'boolean') {
      return <TaskListItem checked={checked}>{children}</TaskListItem>;
    }
    return <li className="text-xs sm:text-sm text-gray-700 dark:text-gray-300" {...props}>{children}</li>;
  },

  // Support for definition lists
  dl: ({ children }) => <DefinitionList>{children}</DefinitionList>,
  dt: ({ children }) => <DefinitionTerm>{children}</DefinitionTerm>,
  dd: ({ children }) => <DefinitionDescription>{children}</DefinitionDescription>,

  // Support for details/summary
  details: ({ children }) => (
    <details className="border rounded-lg p-2 my-2 bg-gray-50 dark:bg-gray-800/50">
      {children}
    </details>
  ),
  
  summary: ({ children }) => (
    <summary className="font-semibold cursor-pointer hover:text-indigo-600 dark:hover:text-indigo-400">
      {children}
    </summary>
  ),

  table: ({ children }) => (
    <div className="overflow-x-auto my-4">
      <table className="min-w-full divide-y divide-gray-300 dark:divide-gray-700 text-xs sm:text-sm border-collapse border border-gray-300 dark:border-gray-700 rounded-lg">
        {children}
      </table>
    </div>
  ),
  
  thead: ({ children }) => (
    <thead className="bg-gray-50 dark:bg-gray-800">
      {children}
    </thead>
  ),

  tbody: ({ children }) => (
    <tbody className="divide-y divide-gray-300 dark:divide-gray-700 bg-white dark:bg-gray-800">
      {children}
    </tbody>
  ),

  tr: ({ children }) => (
    <tr className="hover:bg-gray-50 dark:hover:bg-gray-700/50">
      {children}
    </tr>
  ),
  
  th: ({ children }) => (
    <th className="px-2 py-1 sm:px-3 sm:py-2 text-left text-xs font-semibold text-gray-900 dark:text-white bg-gray-100 dark:bg-gray-700 border border-gray-300 dark:border-gray-600">
      {children}
    </th>
  ),
  
  td: ({ children }) => (
    <td className="px-2 py-1 sm:px-3 sm:py-2 text-xs sm:text-sm text-gray-700 dark:text-gray-300 border border-gray-300 dark:border-gray-600">
      {children}
    </td>
  ),

  // Support for keyboard shortcuts
  kbd: ({ children }) => (
    <kbd className="px-2 py-1 text-xs font-semibold text-gray-800 bg-gray-100 border border-gray-200 rounded-lg dark:text-gray-200 dark:bg-gray-800 dark:border-gray-700">
      {children}
    </kbd>
  ),

  // Enhanced image support with figure and caption
  figure: ({ children }) => (
    <figure className="my-4">
      {children}
    </figure>
  ),
  
  figcaption: ({ children }) => (
    <figcaption className="text-center text-sm text-gray-500 dark:text-gray-400 mt-2">
      {children}
    </figcaption>
  ),

  img: ({ src, alt }) => (
    <img 
      src={src} 
      alt={alt} 
      className="max-w-full h-auto rounded-lg shadow-md dark:shadow-gray-800 my-4" 
    />
  ),

  p: ({ children }) => {
    const containsHeading = React.Children.toArray(children).some(
      child => typeof child === 'object' && child.type && /^h[1-6]$/i.test(child.type)
    );

    if (containsHeading) {
      return children;
    }

    return (
      <p className="text-xs sm:text-sm mb-2 text-gray-700 dark:text-gray-300">{children}</p>
    );
  },

  a: ({ href, children }) => (
    <a
      href={href}
      className="text-indigo-600 dark:text-indigo-400 hover:underline text-xs sm:text-sm"
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  ),
  
  blockquote: ({ children }) => (
    <blockquote className="pl-2 sm:pl-4 border-l-4 border-indigo-500 dark:border-indigo-400 italic text-gray-700 dark:text-gray-300 text-xs sm:text-sm">
      {children}
    </blockquote>
  ),
  
  ul: ({ children }) => (
    <ul className="list-disc pl-4 sm:pl-5 space-y-1 text-xs sm:text-sm text-gray-700 dark:text-gray-300">
      {children}
    </ul>
  ),
  
  ol: ({ children }) => (
    <ol className="list-decimal pl-4 sm:pl-5 space-y-1 text-xs sm:text-sm text-gray-700 dark:text-gray-300">
      {children}
    </ol>
  ),
  
  h1: ({ children }) => (
    <h1 className="text-xl sm:text-2xl font-bold mt-6 mb-4 text-gray-900 dark:text-white">
      {children}
    </h1>
  ),
  
  h2: ({ children }) => (
    <h2 className="text-lg sm:text-xl font-bold mt-5 mb-3 text-gray-900 dark:text-white">
      {children}
    </h2>
  ),
  
  h3: ({ children }) => (
    <h3 className="text-base sm:text-lg font-bold mt-4 mb-2 text-gray-900 dark:text-white">
      {children}
    </h3>
  ),
  
  h4: ({ children }) => (
    <h4 className="text-sm sm:text-base font-bold mt-3 mb-2 text-gray-900 dark:text-white">
      {children}
    </h4>
  ),
  
  h5: ({ children }) => (
    <h5 className="text-xs sm:text-sm font-bold mt-2 mb-2 text-gray-900 dark:text-white">
      {children}
    </h5>
  ),
  
  h6: ({ children }) => (
    <h6 className="text-xs font-bold mt-2 mb-2 text-gray-900 dark:text-white">
      {children}
    </h6>
  ),

  hr: () => (
    <hr className="my-4 border-gray-200 dark:border-gray-700" />
  ),
};

export const ResponseRenderer = ({ content }) => {
  return (
    <div className="text-xs sm:text-sm max-w-none prose prose-sm dark:prose-invert prose-pre:bg-transparent prose-pre:p-0">
      <ReactMarkdown 
        remarkPlugins={[remarkGfm]}
        components={components}
      >
        {content}
      </ReactMarkdown>
    </div>
  );
};

ResponseRenderer.propTypes = {
  content: PropTypes.string.isRequired,
};

export default ResponseRenderer;