// src/config/extensions.js
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import Heading from '@tiptap/extension-heading'
import Bold from '@tiptap/extension-bold'
import Italic from '@tiptap/extension-italic'
import Strike from '@tiptap/extension-strike'
import Code from '@tiptap/extension-code'
import CodeBlock from '@tiptap/extension-code-block'
import Blockquote from '@tiptap/extension-blockquote'
import HardBreak from '@tiptap/extension-hard-break'
import HorizontalRule from '@tiptap/extension-horizontal-rule'
import ListItem from '@tiptap/extension-list-item'
import BulletList from '@tiptap/extension-bullet-list'
import OrderedList from '@tiptap/extension-ordered-list'
import TaskList from '@tiptap/extension-task-list'
import TaskItem from '@tiptap/extension-task-item'
import Image from '@tiptap/extension-image'
import Link from '@tiptap/extension-link'
import Subscript from '@tiptap/extension-subscript'
import Superscript from '@tiptap/extension-superscript'
import Table from '@tiptap/extension-table'
import TableRow from '@tiptap/extension-table-row'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TextAlign from '@tiptap/extension-text-align'
import Underline from '@tiptap/extension-underline'
import Placeholder from '@tiptap/extension-placeholder'
import CharacterCount from '@tiptap/extension-character-count'
import Typography from '@tiptap/extension-typography'
import StarterKit from '@tiptap/starter-kit'
import { Markdown } from 'tiptap-markdown'
import Color from '@tiptap/extension-color'
import TextStyle from '@tiptap/extension-text-style'
import Highlight from '@tiptap/extension-highlight'

const editorExtensions = [
  StarterKit.configure({
    document: false,
    paragraph: false,
    text: false,
    bold: false,
    italic: false,
    strike: false,
    code: false,
    codeBlock: false,
    blockquote: false,
    hardBreak: false,
    horizontalRule: false,
    listItem: false,
    bulletList: false,
    orderedList: false,
  }),
  Document,
  Paragraph,
  Text,
  Heading.configure({
    levels: [1, 2, 3],
    HTMLAttributes: {
      class: 'font-bold',
    },
  }),
  Bold.configure({
    HTMLAttributes: {
      class: 'font-bold',
    },
  }),
  Italic.configure({
    HTMLAttributes: {
      class: 'italic',
    },
  }),
  Underline,
  Strike,
  Code.configure({
    HTMLAttributes: {
      class: 'rounded-md bg-gray-100 px-1 py-0.5 font-mono text-sm dark:bg-gray-800',
    },
  }),
  CodeBlock.configure({
    HTMLAttributes: {
      class: 'rounded-md bg-gray-100 p-4 font-mono text-sm dark:bg-gray-800',
    },
  }),
  Blockquote.configure({
    HTMLAttributes: {
      class: 'border-l-4 border-gray-300 pl-4 dark:border-gray-700',
    },
  }),
  HardBreak,
  HorizontalRule,
  ListItem,
  BulletList.configure({
    HTMLAttributes: {
      class: 'list-disc list-outside ml-4',
    },
  }),
  OrderedList.configure({
    HTMLAttributes: {
      class: 'list-decimal list-outside ml-4',
    },
  }),
  TaskList.configure({
    HTMLAttributes: {
      class: 'list-none pl-2',
    },
  }),
  TaskItem.configure({
    nested: true,
  }),
  Link.configure({
    openOnClick: false,
    HTMLAttributes: {
      class: 'text-blue-500 hover:text-blue-700 underline',
    },
  }),
  Image.configure({
    allowBase64: true,
    HTMLAttributes: {
      class: 'rounded-lg max-w-full',
    },
  }),
  Table.configure({
    resizable: true,
    HTMLAttributes: {
      class: 'border-collapse table-auto w-full',
    },
  }),
  TableRow,
  TableHeader.configure({
    HTMLAttributes: {
      class: 'border border-gray-300 dark:border-gray-700 p-2 bg-gray-100 dark:bg-gray-800',
    },
  }),
  TableCell.configure({
    HTMLAttributes: {
      class: 'border border-gray-300 dark:border-gray-700 p-2',
    },
  }),
  TextAlign.configure({
    types: ['heading', 'paragraph'],
  }),
  Subscript,
  Superscript,
  Color.configure({
    types: ['textStyle'],
  }),
  TextStyle,
  Highlight.configure({
    multicolor: true,
  }),
  Markdown.configure({
    html: true,
    transformPastedText: true,
    transformCopiedText: true,
  }),
  Typography,
  Placeholder.configure({
    placeholder: 'Start writing or type "/" for commands...',
  }),
  CharacterCount.configure({
    limit: null,
  }),
];

export default editorExtensions;