// components/layout/Sidebar.jsx
import { X } from 'lucide-react';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Footer from './Footer';
import { navigationItems } from './navigationItems';

const Sidebar = ({ 
  isOpen, 
  onClose, 
  user, 
  newCollaborations, 
  setLastCheckedCollabs, 
  setNewCollaborations,
  setIsUploadModalOpen
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <>
      {/* Backdrop with blur effect */}
      {isOpen && (
        <div
          className="lg:hidden fixed inset-0 bg-black/30 backdrop-blur-sm z-40 transition-all duration-300"
          onClick={onClose}
        />
      )}

      <div
        className={`fixed lg:relative z-50 ${
          isOpen ? 'translate-x-0' : '-translate-x-full lg:translate-x-0'
        } w-64 lg:w-72 h-full bg-gradient-to-b from-white via-gray-50 to-white dark:from-gray-900/95 dark:via-gray-900 dark:to-gray-900/95 border-r border-gray-200/50 dark:border-gray-800/50 transition-all duration-300 flex flex-col shadow-xl lg:shadow-none backdrop-blur-xl`}
      >
        {/* Logo Section with enhanced styling */}
        <div className="h-16 flex items-center px-6 border-b border-gray-200/50 dark:border-gray-800/50 bg-white/80 dark:bg-gray-900/80 backdrop-blur-sm relative">
          <div className="relative group">
            <div className="absolute -inset-1 bg-gradient-to-r from-violet-600 to-fuchsia-600 rounded-xl blur opacity-25 group-hover:opacity-40 transition duration-200"></div>
            <div className="relative p-1.5 bg-gradient-to-br from-violet-500 to-fuchsia-500 rounded-xl">
              <img src="/logo.svg" alt="Logo" className="h-6 w-6" />
            </div>
          </div>
          <h1 className="ml-3 text-xl font-semibold bg-gradient-to-br from-violet-600 to-fuchsia-600 text-transparent bg-clip-text">
            Lambda AI
          </h1>
          <button
            onClick={onClose}
            className="lg:hidden ml-auto p-2 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-800/50 rounded-lg transition-all duration-200"
          >
            <X size={20} />
          </button>
        </div>

        {/* Navigation with enhanced styling */}
        <nav className="flex-1 py-4 overflow-y-auto px-3 space-y-0.5">
          {navigationItems.map((item) => {
            if (item.adminOnly && user?.role !== 'admin') {
              return null;
            }

            const isActive = location.pathname.startsWith(item.path);
            const isDisabled = item.disabled;

            return (
              <button
                key={item.path}
                onClick={() => {
                  if (!isDisabled) {
                    navigate(item.path);
                    if (item.path === '/collaborative-documents' && newCollaborations > 0) {
                      setLastCheckedCollabs(new Date());
                      localStorage.setItem('lastCheckedCollabs', new Date().toISOString());
                      setNewCollaborations(0);
                    }
                  }
                }}
                disabled={isDisabled}
                className={`
                  group flex items-center justify-between w-full px-3 py-2.5 rounded-xl
                  transition-all duration-200 relative overflow-hidden
                  ${isDisabled ? 'opacity-60 cursor-not-allowed' :
                    isActive ? 
                    'bg-gradient-to-r from-violet-500/10 to-fuchsia-500/10 text-violet-600 dark:text-violet-400' :
                    'text-gray-700 dark:text-gray-300 hover:bg-gray-100/50 dark:hover:bg-gray-800/50'
                  }
                `}
              >
                {/* Enhanced glow effect */}
                {isActive && (
                  <div className="absolute inset-0 bg-gradient-to-r from-violet-500/10 to-fuchsia-500/10 blur-xl animate-pulse-slow" />
                )}

                <div className="flex items-center relative z-10">
                  <div
                    className={`
                      p-1.5 rounded-lg transition-all duration-200
                      ${isActive ?
                        'bg-gradient-to-br from-violet-500 to-fuchsia-500 text-white shadow-lg shadow-violet-500/25' :
                        'text-gray-500 dark:text-gray-400 group-hover:text-violet-500 dark:group-hover:text-violet-400 group-hover:bg-violet-100/50 dark:group-hover:bg-violet-900/50'
                      }
                    `}
                  >
                    {React.createElement(item.icon, { 
                      size: 18,
                      className: 'transform group-hover:scale-110 transition-transform duration-200'
                    })}
                  </div>
                  <span
                    className={`
                      ml-3 font-medium transition-colors duration-200
                      ${isActive ? 'text-violet-600 dark:text-violet-400' : ''}
                    `}
                  >
                    {item.label}
                  </span>
                </div>

                {/* Enhanced badges */}
                {isDisabled ? (
                  <span className="text-xs bg-gray-100/70 dark:bg-gray-800/70 text-gray-600 dark:text-gray-400 px-2 py-0.5 rounded-full backdrop-blur-sm">
                    Coming Soon
                  </span>
                ) : newCollaborations > 0 && item.path === '/collaborative-documents' ? (
                  <span className="text-xs bg-red-100 dark:bg-red-900/50 text-red-600 dark:text-red-400 px-2.5 py-0.5 rounded-full animate-pulse shadow-sm">
                    {newCollaborations} new
                  </span>
                ) : item.badge ? (
                  <span className="text-xs bg-gradient-to-r from-green-500/10 to-emerald-500/10 text-green-600 dark:text-green-400 px-2.5 py-0.5 rounded-full shadow-sm backdrop-blur-sm">
                    {item.badge}
                  </span>
                ) : null}
              </button>
            );
          })}
        </nav>

        {/* Footer with subtle separator */}
        <div className="relative">
          <div className="absolute inset-x-0 -top-6 h-6 bg-gradient-to-t from-white dark:from-gray-900 to-transparent" />
          <Footer setIsUploadModalOpen={setIsUploadModalOpen} />
        </div>
      </div>
    </>
  );
};

export default Sidebar;