// File: src/components/chat/ChatInterface.jsx
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AlertCircle } from 'lucide-react';
import { useAuth } from '../context/AuthContext';
import { useChat } from '../hooks/useChat';
import { useChatActions } from '../hooks/useChatActions';
import ChatHeader from './chat/ChatHeader';
import WelcomeMessage from './chat/WelcomeMessage';
import ChatInput from './chat/ChatInput';
import ChatMessage from './chat/ChatMessage';

const ChatInterface = ({
  conversationId,
  projectId,
  isProjectWide,
  currentConversation,
  isGeneral = false,
  onChatUpdate,
}) => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [inputMessage, setInputMessage] = useState("");
  const messagesEndRef = useRef(null);

  const {
    messages,
    setMessages,
    loading,
    setLoading,
    regenerating,
    relevantDocs,
    setRelevantDocs,
    selectedImage,
    imagePreview,
    error,
    setError,
    handleImageSelect,
    removeSelectedImage,
    clearChatHistory,
    regenerateResponse,
  } = useChat({
    conversationId,
    projectId,
    isProjectWide,
    isGeneral,
    onChatUpdate,
  });

  const {
    showShareAlert,
    handleError,
    copyMessage,
    shareResponse,
    downloadResponse,
  } = useChatActions(navigate, logout);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  React.useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const getLoadingMessage = () => {
    if (messages.length > 10) {
      return "This conversation is becoming too long. Lambda AI might take a bit longer to respond...";
    }
    if (isGeneral) return "Lambda AI is thinking...";
    if (isProjectWide) return "Searching documents...";
    if (currentConversation?.isWebContent) return "Analyzing webpage content...";
    return "Lambda AI is thinking...";
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if ((!inputMessage.trim() && !selectedImage) || loading) return;

    const messageText = inputMessage;
    setInputMessage("");
    setLoading(true);
    setError(null);

    const newMessage = {
      question: messageText,
      answer: "",
      timestamp: new Date().toISOString(),
      hasImage: !!selectedImage,
    };
    setMessages((prev) => [...prev, newMessage]);

    try {
      const token = localStorage.getItem("token");
      let endpoint = "";

      const formData = new FormData();
      formData.append("question", messageText);

      if (selectedImage) {
        formData.append("image", selectedImage);
      }

      // Handle different endpoint types
      if (projectId && isProjectWide) {
        endpoint = "/projectwide/chat/project-query";
        formData.append("projectId", projectId);

        // Use streaming for projectwide endpoint
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}${endpoint}`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          }
        );

        if (response.status === 401) {
          throw new Error("Unauthorized");
        }

        const reader = response.body.getReader();
        const updateMessage = (response) => {
          setMessages((prev) => {
            const newMessages = [...prev];
            newMessages[newMessages.length - 1].answer = response;
            return newMessages;
          });
        };

        let accumulatedResponse = "";
        let buffer = "";

        while (true) {
          const { value, done } = await reader.read();
          if (done) break;

          buffer += new TextDecoder().decode(value);

          const lines = buffer.split("\n");
          buffer = lines.pop() || "";

          for (const line of lines) {
            if (line.trim() && line.startsWith("data: ")) {
              try {
                const data = JSON.parse(line.slice(6));

                if (data.error) {
                  throw new Error(data.error);
                }

                if (data.chunk) {
                  accumulatedResponse += data.chunk;
                  updateMessage(accumulatedResponse);
                }

                if (data.done) {
                  setMessages(data.history);
                  if (data.relevantDocuments) {
                    setRelevantDocs(data.relevantDocuments);
                  }
                  break;
                }
              } catch (parseError) {
                console.warn("Error parsing SSE data:", parseError);
                continue;
              }
            }
          }
        }
      } else {
        // Handle other endpoints with regular fetch
        if (isGeneral) {
          endpoint = "/query/general-query";
          formData.append("conversationId", conversationId);
        } else if (conversationId?.startsWith("web-")) {
          endpoint = "/webcontent/chat";
          formData.append("chatId", conversationId);
        } else if (conversationId) {
          endpoint = "/query/process";
          formData.append("conversationId", conversationId);
        }

        const response = await fetch(
          `${process.env.REACT_APP_API_URL}${endpoint}`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          }
        );

        if (response.status === 401) {
          throw new Error("Unauthorized");
        }

        const data = await response.json();
        if (!response.ok) throw new Error(data.error);

        setMessages(data.history);
        if (data.relevantDocuments) {
          setRelevantDocs(data.relevantDocuments);
        }

        if (data.warning) {
          setError(data.warning);
        }
      }

      removeSelectedImage();
      if (onChatUpdate) {
        onChatUpdate();
      }
    } catch (err) {
      console.error("Chat submission error:", err);
      handleError(err);
      setMessages((prev) => prev.filter((msg) => msg !== newMessage));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col h-full bg-gray-50 dark:bg-gray-900">
      <ChatHeader
        isGeneral={isGeneral}
        isProjectWide={isProjectWide}
        currentConversation={currentConversation}
        onClearChat={clearChatHistory}
        hasMessages={messages.length > 0}
      />

      <div className="flex-1 overflow-y-auto">
        <div className="max-w-5xl mx-auto px-2 sm:px-6 py-4 sm:py-6 space-y-4 sm:space-y-6">
          {messages.length > 0 && messages.some((m) => m.question || m.answer) ? (
            messages
              .filter((m) => m.question || m.answer)
              .map((message, index) => (
                <ChatMessage
                  key={index}
                  message={message}
                  index={index}
                  isLoading={loading}
                  isRegenerating={regenerating}
                  onCopy={copyMessage}
                  onShare={shareResponse}
                  onRegenerate={regenerateResponse}
                  onDownload={downloadResponse}
                  onClear={clearChatHistory}
                  relevantDocs={relevantDocs}
                  isProjectWide={isProjectWide}
                  user={user}
                  imagePreview={imagePreview}
                  isLastMessage={index === messages.length - 1}
                  getLoadingMessage={getLoadingMessage}
                />
              ))
          ) : (
            <WelcomeMessage
              isGeneral={isGeneral}
              isProjectWide={isProjectWide}
              currentConversation={currentConversation}
            />
          )}
          <div ref={messagesEndRef} />
        </div>
      </div>

      {error && (
        <div className="fixed bottom-[72px] sm:bottom-[80px] left-0 right-0 px-4 py-2 bg-yellow-50 dark:bg-yellow-900/30 border-t border-yellow-100 dark:border-yellow-900">
          <p className="text-yellow-800 dark:text-yellow-300 text-sm max-w-3xl mx-auto">
            {error}
          </p>
        </div>
      )}

      {showShareAlert && (
        <div className="fixed bottom-20 left-1/2 transform -translate-x-1/2 bg-gray-800 text-white px-4 py-2 rounded-lg shadow-lg flex items-center gap-2 z-50">
          <AlertCircle className="h-4 w-4" />
          <span className="text-sm">Response copied to clipboard</span>
        </div>
      )}

      <ChatInput
        inputMessage={inputMessage}
        setInputMessage={setInputMessage}
        selectedImage={selectedImage}
        onImageSelect={handleImageSelect}
        onRemoveImage={removeSelectedImage}
        loading={loading}
        onSubmit={handleSubmit}
        isGeneral={isGeneral}
        isProjectWide={isProjectWide}
      />
    </div>
  );
};

export default ChatInterface;