import { Filter, Folder, Loader, Plus, Search, SortAsc } from "lucide-react";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import CreateProjectModal from "../CreateProjectModal";
import EditProjectModal from "../EditProjectModal";
import ProjectCard from "./ProjectCard";

const ProjectsPage = () => {
  const { user, logout } = useAuth();
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editingProject, setEditingProject] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortBy, setSortBy] = useState("date");
  const navigate = useNavigate();

  const fetchProjects = useCallback(async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/projects`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 401) {
        logout();
        navigate("/login");
        return;
      }

      const data = await response.json();
      if (!response.ok) throw new Error(data.error);

      setProjects(data.projects);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, [logout, navigate]);

  useEffect(() => {
    fetchProjects();
  }, [fetchProjects]);

  const handleEditProject = useCallback((project) => {
    setEditingProject(project);
    setIsEditModalOpen(true);
  }, []);

  const handleUpdateProject = useCallback((updatedProject) => {
    setProjects((prev) =>
      prev.map((p) =>
        p.projectId === updatedProject.projectId ? updatedProject : p
      )
    );
    setIsEditModalOpen(false);
    setEditingProject(null);
  }, []);

  const handleDeleteProject = useCallback(
    async (project) => {
      try {
        const token = localStorage.getItem("token");
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/projects/${project.projectId}`,
          {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
  
        if (response.status === 401) {
          logout();
          navigate("/login");
          return;
        }
  
        if (!response.ok) {
          throw new Error("Failed to delete project");
        }
  
        setProjects((prev) =>
          prev.filter((p) => p.projectId !== project.projectId)
        );
      } catch (error) {
        console.error("Error deleting project:", error);
      }
    },
    [logout, navigate]
  );

  const filteredProjects = projects
    .filter(
      (project) =>
        project.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        project.description?.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .sort((a, b) => {
      if (sortBy === "name") {
        return a.name.localeCompare(b.name);
      }
      return new Date(b.createdAt) - new Date(a.createdAt);
    });

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <Loader className="h-8 w-8 animate-spin text-[#464EB8]" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4 bg-red-50 dark:bg-red-900/30 text-red-600 dark:text-red-400 rounded-lg font-medium">
        {error}
      </div>
    );
  }

  return (
    <div className="space-y-4 sm:space-y-5 container mx-auto px-4 sm:px-6 max-w-[1920px]">
      {/* Header Section */}
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
        <div className="flex flex-col sm:flex-row sm:items-center gap-2 sm:gap-4">
          <h1 className="text-xl sm:text-2xl font-bold text-gray-900 dark:text-white">
            Projects
          </h1>
          <div className="inline-flex items-center px-2.5 py-1 bg-gray-100 dark:bg-gray-800 rounded-full text-sm text-gray-600 dark:text-gray-400 font-medium">
            {projects.length} total
          </div>
        </div>
        <button
          onClick={() => setIsCreateModalOpen(true)}
          className="w-full sm:w-auto flex items-center justify-center px-4 py-2 bg-[#464EB8] text-white rounded-lg hover:bg-[#373E93] transition-colors gap-2"
        >
          <Plus size={18} />
          <span>New Project</span>
        </button>
      </div>

      {/* Search and Filter Section */}
      <div className="flex flex-col sm:flex-row gap-4">
        <div className="relative flex-1">
          <Search
            className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
            size={18}
          />
          <input
            type="text"
            placeholder="Search projects..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full pl-10 pr-4 py-2 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#464EB8] dark:focus:ring-[#464EB8] focus:border-transparent"
          />
        </div>
        <div className="flex items-center gap-2 shrink-0">
          <button
            onClick={() => setSortBy(sortBy === "date" ? "name" : "date")}
            className="flex items-center gap-2 px-3 py-2 text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors"
          >
            <SortAsc size={18} />
            <span className="text-sm whitespace-nowrap">Sort: {sortBy}</span>
          </button>
          <button className="p-2 text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors">
            <Filter size={18} />
          </button>
        </div>
      </div>

      {/* Projects Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2">
        {filteredProjects.map((project) => (
          <ProjectCard
            key={project.projectId}
            project={project}
            onClick={() => navigate(`/projects/${project.projectId}`)}
            onEdit={handleEditProject}
            onDelete={handleDeleteProject}
          />
        ))}
      </div>

      {/* Empty States */}
      {projects.length === 0 && (
        <div className="text-center py-10 sm:py-12 bg-gray-50 dark:bg-gray-800 rounded-lg border border-dashed border-gray-200 dark:border-gray-700">
          <Folder
            size={48}
            className="mx-auto text-gray-400 dark:text-gray-600 mb-4"
          />
          <h3 className="text-lg font-medium text-gray-900 dark:text-white">
            Welcome, {user.firstName}!
          </h3>
          <p className="text-gray-500 dark:text-gray-400 mt-1 max-w-sm mx-auto">
            Create your first project to organize your documents and
            conversations
          </p>
        </div>
      )}

      {projects.length > 0 && filteredProjects.length === 0 && (
        <div className="text-center py-10 sm:py-12 bg-gray-50 dark:bg-gray-800 rounded-lg">
          <Search
            size={48}
            className="mx-auto text-gray-400 dark:text-gray-600 mb-4"
          />
          <h3 className="text-lg font-medium text-gray-900 dark:text-white">
            No matching projects
          </h3>
          <p className="text-gray-500 dark:text-gray-400 mt-1">
            Try adjusting your search terms
          </p>
        </div>
      )}

      {/* Modals */}
      <CreateProjectModal
        isOpen={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
        onCreate={(newProject) => {
          setProjects([newProject, ...projects]);
          navigate(`/projects/${newProject.projectId}`);
        }}
      />

      {editingProject && (
        <EditProjectModal
          isOpen={isEditModalOpen}
          onClose={() => {
            setIsEditModalOpen(false);
            setEditingProject(null);
          }}
          project={editingProject}
          onUpdate={handleUpdateProject}
        />
      )}
    </div>
  );
};

export default ProjectsPage;
