// File: src/utils/dateUtils.js

export const formatTime = (timestamp) => {
    return new Date(timestamp).toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  };
  
  export const formatDate = (timestamp) => {
    return new Date(timestamp).toLocaleDateString([], {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };
  
  export const formatDateTime = (timestamp) => {
    return new Date(timestamp).toLocaleString([], {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  };