// components/auth/LoginForm.jsx
import axios from "axios";
import { AnimatePresence, motion } from "framer-motion";
import {
  ArrowRight,
  Bot,
  Code,
  Download,
  Eye,
  EyeOff,
  File,
  FileText,
  Folders,
  Globe,
  Lock,
  Mail,
  MessageSquare,
  RefreshCw,
  Sparkles,
  X
} from "lucide-react";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";

const FeatureCard = ({ feature, isActive, allFeatures }) => {
  return (
    <motion.div
      initial={{ opacity: 0, x: 20 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -20 }}
      transition={{ duration: 0.5 }}
      className="bg-white/5 backdrop-blur-sm rounded-2xl p-8 border border-white/10 h-full"
    >
      <div className="h-full flex flex-col">
        {/* Header */}
        <div className="mb-6">
          <div
            className={`w-16 h-16 rounded-xl bg-gradient-to-r ${feature.gradient} p-4`}
          >
            {React.createElement(feature.icon, {
              className: "w-full h-full text-white",
            })}
          </div>
          <h3 className="text-2xl font-bold mt-4">{feature.title}</h3>
          <p className="text-gray-400 mt-2">{feature.description}</p>
        </div>

        {/* Details Grid */}
        <div className="grid gap-4 flex-grow">
          {feature.details.map((detail, idx) => (
            <motion.div
              key={detail.format}
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: idx * 0.1 }}
              className="bg-white/5 rounded-xl p-4 hover:bg-white/10 transition-colors"
            >
              <div className="flex items-center gap-3 mb-3">
                {React.createElement(detail.icon, {
                  className: `w-5 h-5 text-gray-400`,
                })}
                <h4 className="font-medium">{detail.format}</h4>
              </div>
              <ul className="space-y-2">
                {detail.capabilities.map((capability, capIdx) => (
                  <motion.li
                    key={capIdx}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: idx * 0.1 + capIdx * 0.05 }}
                    className="text-sm text-gray-400 flex items-center gap-2"
                  >
                    <span className="w-1 h-1 rounded-full bg-gray-400" />
                    {capability}
                  </motion.li>
                ))}
              </ul>
            </motion.div>
          ))}
        </div>

        {/* Progress Indicators */}
        <div className="flex justify-between items-center mt-6">
          <div className="flex space-x-2">
            {allFeatures.map((_, idx) => (
              <div
                key={idx}
                className={`w-8 h-1 rounded-full transition-colors duration-300 ${
                  idx === (isActive ? isActive : 0)
                    ? `bg-gradient-to-r ${feature.gradient}`
                    : "bg-white/10"
                }`}
              />
            ))}
          </div>
          <ArrowRight
            className={`w-6 h-6 bg-gradient-to-r ${feature.gradient} bg-clip-text text-transparent`}
          />
        </div>
      </div>
    </motion.div>
  );
};
const LoginForm = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [showVerificationPrompt, setShowVerificationPrompt] = useState(false);
  const [resetEmail, setResetEmail] = useState("");
  const [isResetting, setIsResetting] = useState(false);
  const [isResendingVerification, setIsResendingVerification] = useState(false);
  const [unverifiedEmail, setUnverifiedEmail] = useState("");
  const [activeFeature, setActiveFeature] = useState(0);
  const navigate = useNavigate();
  const { login } = useAuth();

  const features = [
    {
      icon: Folders,
      title: "Project Management",
      description: "Organize and manage your documents intelligently",
      gradient: "from-purple-500 to-indigo-500",
      details: [
        {
          icon: Folders,
          format: "Project Organization",
          capabilities: [
            "Create and manage projects",
            "Group related documents",
            "Project-wide conversations",
            "Multi-document search",
          ],
        },
        {
          icon: MessageSquare,
          format: "Project Chat",
          capabilities: [
            "Chat across all project documents",
            "Smart document cross-referencing",
            "Interactive chat history",
            "Context-aware responses",
          ],
        },
      ],
    },
    {
      icon: FileText,
      title: "Document Processing",
      description: "Comprehensive support for multiple document formats",
      gradient: "from-blue-500 to-indigo-500",
      details: [
        {
          icon: File,
          format: "Documents & Spreadsheets",
          capabilities: [
            "Process PDFs, Word, .ppt, .pptx and Excel files",
            "Handle complex layouts and data",
            "Extract structured content",
            "Preserve document formatting",
          ],
        },
        {
          icon: Code,
          format: "Code & Configuration Files",
          capabilities: [
            "Support for multiple programming languages",
            "Syntax-aware processing and analysis",
            "Handle various file encodings",
            "Process configuration and build files",
          ],
        }
      ],
    },
    {
      icon: Globe,
      title: "Web Content",
      description: "Process and analyze web content intelligently",
      gradient: "from-green-500 to-teal-500",
      details: [
        {
          icon: Globe,
          format: "Web Processing",
          capabilities: [
            "Extract webpage content",
            "Handle dynamic content",
            "Process web metadata",
            "Smart content extraction",
          ],
        },
        {
          icon: MessageSquare,
          format: "Web Chat",
          capabilities: [
            "Chat about web content",
            "Track chat history",
            "Source verification",
            "Interactive responses",
          ],
        },
      ],
    },
    {
      icon: MessageSquare,
      title: "Chat Features",
      description: "Advanced conversation capabilities",
      gradient: "from-rose-500 to-pink-500",
      details: [
        {
          icon: Bot,
          format: "AI Interaction",
          capabilities: [
            "Natural language queries",
            "Context-aware responses",
            "Document references",
            "Follow-up questions",
          ],
        },
        {
          icon: Download,
          format: "Response Actions",
          capabilities: [
            "Download responses as DOCX",
            "Copy to clipboard",
            "Open The AI response in a text editor",
            "Regenerate responses",
          ],
        },
      ],
    },
  ];

  // Auto-rotate features
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveFeature((prev) => (prev + 1) % features.length);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const loadingToast = toast.loading("Signing in...");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/login`,
        formData
      );

      if (response.data.requiresVerification) {
        setUnverifiedEmail(formData.email);
        toast.error("Please verify your email to continue", {
          id: loadingToast,
        });
        setShowVerificationPrompt(true);
        return;
      }

      if (response.data.requiresOTP) {
        toast.success("OTP sent to your email", { id: loadingToast });
        navigate(`/verify-otp/${response.data.userId}`);
        return;
      }

      login(response.data.user, response.data.token);
      toast.success("Welcome back!", { id: loadingToast });
      navigate("/documents");
    } catch (error) {
      if (
        error.response?.status === 403 &&
        error.response?.data?.requiresVerification
      ) {
        setUnverifiedEmail(formData.email);
        toast.error("Please verify your email to continue", {
          id: loadingToast,
        });
        setShowVerificationPrompt(true);
      } else if (error.response?.status === 423) {
        toast.error(error.response.data.message, {
          id: loadingToast,
          duration: 5000,
        });
      } else if (error.response?.status === 401) {
        const message = error.response.data.attemptsLeft
          ? `Invalid credentials. ${error.response.data.attemptsLeft} attempts remaining`
          : error.response.data.error;
        toast.error(message, {
          id: loadingToast,
          duration: 3000,
        });
      } else {
        toast.error(error.response?.data?.error || "Login failed", {
          id: loadingToast,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };
  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setIsResetting(true);
    const loadingToast = toast.loading("Processing request...");

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/forgot-password`,
        {
          email: resetEmail,
        }
      );
      toast.success(
        "If an account exists, password reset instructions have been sent to your email",
        {
          id: loadingToast,
        }
      );
      setShowForgotPassword(false);
    } catch (error) {
      toast.error("Failed to process request", { id: loadingToast });
    } finally {
      setIsResetting(false);
    }
  };

  const handleResendVerification = async () => {
    setIsResendingVerification(true);
    const loadingToast = toast.loading("Resending verification email...");

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/resend-verification`,
        {
          email: unverifiedEmail,
        }
      );
      toast.success("Verification email resent successfully", {
        id: loadingToast,
      });
    } catch (error) {
      toast.error("Failed to resend verification email", { id: loadingToast });
    } finally {
      setIsResendingVerification(false);
    }
  };

  return (
    <div className="min-h-screen bg-[#0A0A0A] text-white relative overflow-hidden">
      {/* Animated Background */}
      <div className="absolute inset-0 -z-10">
        <div className="absolute inset-0 bg-[linear-gradient(to_right,#0A0A0A_1px,transparent_1px),linear-gradient(to_bottom,#0A0A0A_1px,transparent_1px)] bg-[size:4rem_4rem] [mask-image:radial-gradient(ellipse_80%_50%_at_50%_0%,#000_70%,transparent_110%)] opacity-25">
          <div className="absolute inset-0 bg-gradient-to-r from-[#00FFB2]/20 to-[#00D1FF]/20" />
        </div>
        {/* Animated gradient orbs */}
        <div className="absolute top-0 -left-4 w-96 h-96 bg-gradient-to-r from-[#00FFB2]/30 to-[#00D1FF]/30 rounded-full filter blur-3xl animate-pulse" />
        <div className="absolute bottom-0 right-0 w-96 h-96 bg-gradient-to-r from-[#00D1FF]/30 to-[#00FFB2]/30 rounded-full filter blur-3xl animate-pulse delay-1000" />
      </div>

      <div className="relative max-w-7xl mx-auto px-4 py-16 grid lg:grid-cols-2 gap-16 items-center">
        {/* Left Column - Features */}
        <div className="space-y-12">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="space-y-4"
          >
            <div className="inline-flex items-center space-x-2 bg-white/5 rounded-full px-4 py-2 border border-white/10">
              <span className="w-2 h-2 rounded-full bg-[#00FFB2] animate-pulse" />
              <span className="text-sm">AI-Powered Platform</span>
            </div>
            <h1 className="text-6xl font-bold leading-tight">
              Welcome to
              <motion.span
                className="block text-transparent bg-clip-text bg-gradient-to-r from-[#00FFB2] to-[#00D1FF]"
                animate={{
                  backgroundPosition: ["0%", "100%"],
                  opacity: [0.5, 1, 0.5],
                }}
                transition={{
                  duration: 3,
                  repeat: Infinity,
                  repeatType: "reverse",
                }}
              >
                Lambda AI
              </motion.span>
            </h1>
          </motion.div>

          {/* Animated Feature Cards */}
          <div className="relative h-[600px]">
            <AnimatePresence mode="wait">
              <FeatureCard
                key={activeFeature}
                feature={features[activeFeature]}
                isActive={activeFeature}
                allFeatures={features}
              />
            </AnimatePresence>
          </div>
        </div>
        {/* Right Column - Login Form */}
        <motion.div
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.6, delay: 0.3 }}
          className="bg-white/5 backdrop-blur-sm rounded-2xl border border-white/10 p-12 relative overflow-hidden"
        >
          <div className="absolute inset-0 bg-gradient-to-r from-[#00FFB2]/10 to-[#00D1FF]/10 opacity-50" />

          <div className="relative space-y-8">
            <motion.div
              className="text-center space-y-4"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
            >
              <div className="w-16 h-16 bg-gradient-to-r from-[#00FFB2] to-[#00D1FF] rounded-2xl mx-auto flex items-center justify-center">
                <Sparkles className="w-8 h-8 text-black" />
              </div>
              <h2 className="text-3xl font-bold">Sign In</h2>
              <p className="text-gray-400">Access your Lambda AI workspace</p>
            </motion.div>

            <form onSubmit={handleSubmit} className="space-y-6">
              <motion.div
                className="space-y-4"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5 }}
              >
                <div className="relative group">
                  <Mail className="absolute left-4 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400 group-hover:text-[#00FFB2] transition-colors" />
                  <input
                    name="email"
                    type="email"
                    required
                    placeholder="Email address"
                    value={formData.email}
                    onChange={handleChange}
                    className="w-full bg-white/5 border border-white/10 rounded-xl px-11 py-4 focus:ring-2 focus:ring-[#00FFB2] outline-none text-white placeholder:text-gray-500 transition-all group-hover:border-[#00FFB2]"
                  />
                </div>

                <div className="relative group">
                  <Lock className="absolute left-4 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400 group-hover:text-[#00FFB2] transition-colors" />
                  <input
                    name="password"
                    type={showPassword ? "text" : "password"}
                    required
                    placeholder="Password"
                    value={formData.password}
                    onChange={handleChange}
                    className="w-full bg-white/5 border border-white/10 rounded-xl px-11 py-4 focus:ring-2 focus:ring-[#00FFB2] outline-none text-white placeholder:text-gray-500 transition-all group-hover:border-[#00FFB2]"
                  />
                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className="absolute right-4 top-1/2 -translate-y-1/2 text-gray-400 hover:text-white transition-colors"
                  >
                    {showPassword ? (
                      <EyeOff className="w-5 h-5" />
                    ) : (
                      <Eye className="w-5 h-5" />
                    )}
                  </button>
                </div>
              </motion.div>

              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.6 }}
                className="flex justify-end"
              >
                <button
                  type="button"
                  onClick={() => setShowForgotPassword(true)}
                  className="text-sm text-[#00FFB2] hover:text-[#00D1FF] transition-colors"
                >
                  Forgot password?
                </button>
              </motion.div>

              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.7 }}
                type="submit"
                disabled={isLoading}
                className="relative w-full bg-gradient-to-r from-[#00FFB2] to-[#00D1FF] text-black font-medium py-4 rounded-xl flex items-center justify-center gap-2 group hover:opacity-90 transition-all"
              >
                <span>{isLoading ? "Signing in..." : "Sign in"}</span>
                <ArrowRight className="w-4 h-4 group-hover:translate-x-1 transition-transform" />
              </motion.button>

              <motion.p
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.8 }}
                className="text-center text-gray-400"
              >
                Don't have an account?{" "}
                <button
                  type="button"
                  onClick={() => navigate("/register")}
                  className="text-[#00FFB2] hover:text-[#00D1FF] transition-colors"
                >
                  Sign up now
                </button>
              </motion.p>
            </form>
          </div>
          <div className="text-center mt-8">
            <p className="text-sm text-gray-400">
              © 2024 Lambda AI • Developed by{" "}
              <a
                href="mailto:lambdaa29@gmail.com"
                className="text-[#00FFB2] hover:text-[#00D1FF] transition-colors"
              >
                lambdaa29@gmail.com
              </a>
            </p>
          </div>
        </motion.div>
      </div>
      {/* Forgot Password Modal */}
      {showForgotPassword && (
        <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            transition={{ duration: 0.2 }}
            className="bg-[#0A0A0A] border border-white/10 rounded-2xl p-8 w-full max-w-md relative"
          >
            <button
              onClick={() => setShowForgotPassword(false)}
              className="absolute right-4 top-4 text-gray-400 hover:text-white"
            >
              <X className="w-5 h-5" />
            </button>

            <div className="text-center mb-6">
              <h3 className="text-xl font-bold">Reset Password</h3>
              <p className="text-gray-400 mt-2">
                Enter your email address and we'll send you instructions to
                reset your password.
              </p>
            </div>

            <form onSubmit={handleForgotPassword} className="space-y-6">
              <div className="relative">
                <Mail className="absolute left-4 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
                <input
                  type="email"
                  required
                  placeholder="Email address"
                  value={resetEmail}
                  onChange={(e) => setResetEmail(e.target.value)}
                  className="w-full bg-white/5 border border-white/10 rounded-xl px-11 py-3 focus:ring-2 focus:ring-[#00FFB2] outline-none text-white placeholder:text-gray-500 transition-all"
                />
              </div>

              <div className="flex gap-4">
                <motion.button
                  whileHover={{ scale: 1.01 }}
                  whileTap={{ scale: 0.99 }}
                  type="submit"
                  disabled={isResetting}
                  className="flex-1 bg-gradient-to-r from-[#00FFB2] to-[#00D1FF] text-black font-medium py-3 rounded-xl"
                >
                  {isResetting ? "Sending..." : "Send Instructions"}
                </motion.button>
                <motion.button
                  whileHover={{ scale: 1.01 }}
                  whileTap={{ scale: 0.99 }}
                  type="button"
                  onClick={() => setShowForgotPassword(false)}
                  className="flex-1 border border-white/10 py-3 rounded-xl hover:bg-white/5"
                >
                  Cancel
                </motion.button>
              </div>
            </form>
          </motion.div>
        </div>
      )}

      {/* Email Verification Modal */}
      {showVerificationPrompt && (
        <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            transition={{ duration: 0.2 }}
            className="bg-[#0A0A0A] border border-white/10 rounded-2xl p-8 w-full max-w-md relative"
          >
            <button
              onClick={() => setShowVerificationPrompt(false)}
              className="absolute right-4 top-4 text-gray-400 hover:text-white"
            >
              <X className="w-5 h-5" />
            </button>

            <div className="text-center mb-6">
              <div className="w-12 h-12 bg-gradient-to-r from-[#00FFB2] to-[#00D1FF] rounded-xl mx-auto flex items-center justify-center mb-4">
                <Mail className="w-6 h-6 text-black" />
              </div>
              <h3 className="text-xl font-bold">Verify Your Email</h3>
              <p className="text-gray-400 mt-2">
                Please check your email ({unverifiedEmail}) and click the
                verification link to activate your account.
              </p>
            </div>

            <div className="space-y-4">
              <motion.button
                whileHover={{ scale: 1.01 }}
                whileTap={{ scale: 0.99 }}
                onClick={handleResendVerification}
                disabled={isResendingVerification}
                className="w-full bg-white/5 border border-white/10 rounded-xl py-3 flex items-center justify-center gap-2 hover:bg-white/10 transition-all"
              >
                <RefreshCw
                  className={`w-4 h-4 ${
                    isResendingVerification ? "animate-spin" : ""
                  }`}
                />
                <span>
                  {isResendingVerification
                    ? "Resending..."
                    : "Resend Verification Email"}
                </span>
              </motion.button>

              <motion.button
                whileHover={{ scale: 1.01 }}
                whileTap={{ scale: 0.99 }}
                onClick={() => setShowVerificationPrompt(false)}
                className="w-full bg-gradient-to-r from-[#00FFB2] to-[#00D1FF] text-black font-medium py-3 rounded-xl"
              >
                Got it
              </motion.button>
            </div>
          </motion.div>
        </div>
      )}
    </div>
  );
};

export default LoginForm;
