import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Bot, 
  Loader, 
  MessageSquare, 
  Plus, 
  Search, 
  Clock, 
  ChevronRight,
  Trash2
} from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import ChatInterface from '../ChatInterface';
import toast from 'react-hot-toast';

const ChatCard = ({ chat, onSelect, onDelete }) => {
  const formatDate = (dateString) => {
    const now = new Date();
    const date = new Date(dateString);
    const diffTime = Math.abs(now - date);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays === 0) return 'Today';
    if (diffDays === 1) return 'Yesterday';
    if (diffDays < 7) return `${diffDays} days ago`;
    return date.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: date.getFullYear() !== now.getFullYear() ? 'numeric' : undefined
    });
  };

  const getLastMessage = (history) => {
    if (!history || history.length === 0) return null;
    const lastMessage = history[history.length - 1];
    return lastMessage.question;
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    toast((t) => (
      <div className="flex flex-col space-y-2">
        <p className="font-medium">Delete this conversation?</p>
        <div className="flex space-x-2">
          <button
            className="px-3 py-1 bg-red-600 text-white rounded-md text-sm hover:bg-red-700"
            onClick={() => {
              onDelete(chat.conversationId);
              toast.dismiss(t.id);
            }}
          >
            Delete
          </button>
          <button
            className="px-3 py-1 bg-gray-200 text-gray-800 rounded-md text-sm hover:bg-gray-300"
            onClick={() => toast.dismiss(t.id)}
          >
            Cancel
          </button>
        </div>
      </div>
    ));
  };

  return (
    <button
      onClick={() => onSelect(chat.conversationId)}
      className="group relative bg-gradient-to-br from-white to-gray-50 dark:from-gray-800 dark:to-gray-900 rounded-xl shadow-sm border border-gray-200/50 dark:border-gray-700/50 hover:border-violet-500 dark:hover:border-violet-500/50 transition-all duration-200 hover:shadow-lg hover:shadow-violet-500/10 text-left w-full overflow-hidden"
    >
      <div className="p-4 relative">
        {/* Decorative gradient blob */}
        <div className="absolute -top-12 -right-12 w-24 h-24 bg-gradient-to-br from-violet-500/20 to-fuchsia-500/20 blur-2xl rounded-full" />
        
        <div className="relative">
          <div className="flex items-center gap-3 mb-3">
            <div className="p-2 bg-gradient-to-br from-violet-500 to-fuchsia-500 rounded-lg shadow-sm">
              <MessageSquare size={16} className="text-white" />
            </div>
            <span className="font-medium text-gray-900 dark:text-white flex-1 group-hover:text-violet-500 dark:group-hover:text-violet-400 transition-colors">
              {chat.fileName}
            </span>
            <div className="opacity-0 group-hover:opacity-100 transition-opacity">
              <button
                onClick={handleDelete}
                className="p-1.5 text-gray-400 hover:text-red-500 hover:bg-red-50 dark:hover:bg-red-900/30 rounded-lg transition-colors"
                title="Delete conversation"
              >
                <Trash2 size={16} />
              </button>
            </div>
          </div>

          {getLastMessage(chat.history) && (
            <p className="text-sm text-gray-500 dark:text-gray-400 line-clamp-2 mb-3">
              {getLastMessage(chat.history)}
            </p>
          )}

          <div className="mt-4 pt-4 border-t border-gray-100 dark:border-gray-800 flex items-center justify-between text-xs">
            <div className="flex items-center text-gray-400 dark:text-gray-500">
              <Clock size={14} className="mr-1.5" />
              {formatDate(chat.createdAt)}
            </div>
            <div className="flex items-center gap-1 text-violet-500 dark:text-violet-400">
              <MessageSquare size={14} />
              <span>{chat.history?.length || 0} messages</span>
            </div>
          </div>
        </div>
      </div>
    </button>
  );
};

const GeneralChatPage = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [conversations, setConversations] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  const fetchGeneralConversations = useCallback(async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/conversations/general/list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 401) {
        logout();
        navigate("/login");
        return;
      }

      const data = await response.json();
      if (!response.ok) throw new Error(data.error);

      setConversations(data.documents || []);
    } catch (err) {
      setError(err.message);
      toast.error("Failed to load conversations");
    } finally {
      setLoading(false);
    }
  }, [logout, navigate]);

  useEffect(() => {
    fetchGeneralConversations();
  }, [fetchGeneralConversations]);

  const handleDeleteChat = async (conversationId) => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/conversations/${conversationId}`,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 401) {
        logout();
        navigate("/login");
        return;
      }

      if (!response.ok) throw new Error("Failed to delete conversation");

      setConversations(prev => prev.filter(conv => conv.conversationId !== conversationId));
      toast.success("Conversation deleted successfully");
    } catch (err) {
      toast.error(err.message);
    }
  };

  const createNewChat = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/conversations/new-general`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            fileName: `General Chat ${new Date().toLocaleDateString()}`
          })
        }
      );

      if (response.status === 401) {
        logout();
        navigate("/login");
        return;
      }

      const data = await response.json();
      if (!response.ok) throw new Error(data.error);

      setSelectedChat(data.conversation.conversationId);
      fetchGeneralConversations();
      toast.success("New chat created");
    } catch (err) {
      toast.error(err.message);
    }
  };

  const getLastMessage = (history) => {
    if (!history || history.length === 0) return null;
    const lastMessage = history[history.length - 1];
    return lastMessage.question;
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <Loader className="h-8 w-8 animate-spin text-indigo-600" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-6 bg-red-50 dark:bg-red-900/30 text-red-600 dark:text-red-400 rounded-lg">
        <p className="font-medium">{error}</p>
      </div>
    );
  }

  if (selectedChat) {
    return (
      <div className="h-[calc(100vh-12rem)]">
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm p-4 mb-6">
          <div className="flex items-center gap-4">
            <button
              onClick={() => setSelectedChat(null)}
              className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors"
            >
              <ChevronRight className="rotate-180" size={20} />
            </button>
            <div className="p-2 bg-purple-50 dark:bg-purple-900/50 text-purple-600 dark:text-purple-400 rounded-lg">
              <Bot size={20} />
            </div>
            <div>
              <h1 className="text-xl font-semibold text-gray-900 dark:text-white">
                General Chat
              </h1>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                Ask me anything! This conversation isn't tied to any specific document or project.
              </p>
            </div>
          </div>
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm h-full overflow-hidden">
          <ChatInterface
            conversationId={selectedChat}
            isGeneral={true}
            currentConversation={{
              fileName: "General Chat",
              isGeneralChat: true
            }}
          />
        </div>
      </div>
    );
  }

  return (
    <div>
      {/* Header */}
      <div className="mb-6">
        <h1 className="text-2xl font-semibold text-gray-900 dark:text-white mb-2">General Chats</h1>
        <p className="text-gray-500 dark:text-gray-400">
          Chat with Lambda AI about any topic or ask any question.
        </p>
      </div>

      {/* Search and New Chat */}
      <div className="flex gap-4 mb-6">
        <div className="flex-1 relative">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
          <input
            type="text"
            placeholder="Search conversations..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full pl-10 pr-4 py-2 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg"
          />
        </div>
        <button
          onClick={createNewChat}
          className="flex items-center px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
        >
          <Plus size={20} className="mr-2" />
          New Chat
        </button>
      </div>

      {/* Conversations List */}
      <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        {conversations.length > 0 ? (
          conversations
            .filter(chat => 
              chat.fileName.toLowerCase().includes(searchQuery.toLowerCase()) ||
              (getLastMessage(chat.history) && 
               getLastMessage(chat.history).toLowerCase().includes(searchQuery.toLowerCase()))
            )
            .map(chat => (
              <ChatCard
                key={chat.conversationId}
                chat={chat}
                onSelect={setSelectedChat}
                onDelete={handleDeleteChat}
              />
            ))
        ) : (
          <div className="col-span-full flex flex-col items-center justify-center py-12">
            <MessageSquare size={48} className="text-gray-300 dark:text-gray-600 mb-4" />
            <p className="text-gray-600 dark:text-gray-400 text-center mb-4">
              No conversations yet
            </p>
            <button
              onClick={createNewChat}
              className="flex items-center px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
            >
              <Plus size={20} className="mr-2" />
              Start a New Chat
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default GeneralChatPage;