// src/components/google-docs/constants.js
export const DEFAULT_EDITOR_CONTENT = "";
export const AUTOSAVE_DELAY = 1000;

export const generateDefaultTitle = () => {
  return `Lambda AI Document - ${new Date().toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  })}`;
};