// src/components/DocumentEditor/components/ConnectionStatus.jsx
import React from 'react';
import { Wifi, WifiOff } from 'lucide-react';

export const ConnectionStatus = ({ isConnected, activeUsers, saving }) => (
  <div className="flex items-center gap-2">
    {isConnected ? (
      <Wifi className="w-4 h-4 text-green-500" />
    ) : (
      <WifiOff className="w-4 h-4 text-red-500" />
    )}
    <span className="text-sm text-gray-500 dark:text-gray-400">
      {activeUsers.length} {activeUsers.length === 1 ? "user" : "users"} online
    </span>
    {saving && (
      <span className="text-sm text-gray-500 dark:text-gray-400">
        Saving...
      </span>
    )}
  </div>
);