import React, { useState, useRef, useCallback, useEffect } from "react";
import { EditorContent as TiptapEditorContent } from "@tiptap/react";
import axios from "axios";
import { debounce } from "lodash";

const AUTOCOMPLETE_TRIGGER_CHARS = 3;
const CONTEXT_WINDOW_SIZE = 500;
const DEBOUNCE_DELAY = 180000;

export const EditorContent = ({ editor }) => {
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const suggestionBoxRef = useRef(null);
  const cursorCoords = useRef(null);
  const currentWordRef = useRef("");

  const positionSuggestionBox = useCallback(() => {
    if (!suggestionBoxRef.current || !editor || !cursorCoords.current) return;

    const { left: cursorLeft, top: cursorTop } = cursorCoords.current;

    const absoluteLeft = cursorLeft;
    const absoluteTop = cursorTop + 24;

    const boxWidth = 300;
    const boxHeight = Math.min(suggestions.length * 36, 200);
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    let left = absoluteLeft;
    let top = absoluteTop;

    if (left + boxWidth > windowWidth) {
      left = windowWidth - boxWidth - 10;
    }

    if (top + boxHeight > windowHeight) {
      top = cursorTop - boxHeight - 10;
    }

    Object.assign(suggestionBoxRef.current.style, {
      position: "fixed",
      left: `${left}px`,
      top: `${top}px`,
      zIndex: 50,
    });
  }, [editor, suggestions.length]);

  const fetchSuggestions = useCallback(
    (currentWord, precedingText, documentId) => {
      if (!documentId || !currentWord.trim()) return;

      const fetchData = async () => {
        try {
          const token = localStorage.getItem("token");
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/collaborative-documents/${documentId}/autocomplete`,
            {
              text: currentWord,
              precedingContent: precedingText,
              cursorPosition: editor?.state.selection.from,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          );

          if (response.data.suggestions?.length) {
            const filteredSuggestions = response.data.suggestions
              .map(suggestion => suggestion.replace(/['"]/g, '').trim())
              .filter(suggestion => suggestion.length > 0);

            if (filteredSuggestions.length > 0) {
              setSuggestions(filteredSuggestions);
              setShowSuggestions(true);
              setSelectedIndex(0);
              requestAnimationFrame(positionSuggestionBox);
            }
          }
        } catch (error) {
          console.error("Error fetching suggestions:", error);
        }
      };

      // Use the 3-minute debounce
      debounce(fetchData, DEBOUNCE_DELAY, { maxWait: DEBOUNCE_DELAY })();
    },
    [editor, positionSuggestionBox]
  );

  const applySuggestion = useCallback(
    (suggestion) => {
      if (!editor) return;

      const { from } = editor.state.selection;
      const text = editor.getText();

      // Enhanced word boundary detection with better space handling
      const findWordBoundaries = (pos) => {
        let start = pos;
        let end = pos;

        // Comprehensive word boundary pattern including spaces
        const boundaryPattern =
          /[\s\u00A0\u1680\u2000-\u200A\u2028\u2029\u202F\u205F\u3000\uFEFF,.!?;:()[\]{}"'`]/;

        // Find word start, including any preceding space
        while (start > 0) {
          const char = text[start - 1];
          // Stop if we hit a non-space boundary
          if (boundaryPattern.test(char) && !/\s/.test(char)) break;
          // Stop if we hit a word character after finding a space
          if (/\w/.test(char) && /\s/.test(text[start])) break;
          start--;
        }

        // Find word end, including any following space
        while (end < text.length) {
          const char = text[end];
          // Stop if we hit a non-space boundary
          if (boundaryPattern.test(char) && !/\s/.test(char)) break;
          // Stop if we hit a space after a word character
          if (/\s/.test(char) && /\w/.test(text[end - 1])) {
            end++; // Include the space
            break;
          }
          end++;
        }

        return { start, end };
      };

      // Get word boundaries based on cursor position
      const { start: wordStart, end: wordEnd } = findWordBoundaries(from);

      // Clean and prepare the suggestion text
      const cleanSuggestion = (text) => {
        // Remove quotes and trim
        let cleaned = text.replace(/['"]/g, "").trim();

        // Ensure proper spacing with surrounding text
        const prevChar =
          wordStart > 0 ? editor.getText(wordStart - 1, wordStart) : "";
        const nextChar =
          wordEnd < editor.state.doc.content.size
            ? editor.getText(wordEnd, wordEnd + 1)
            : "";

        // Add space before if needed
        if (/\w/.test(prevChar) && !/\s$/.test(prevChar)) {
          cleaned = " " + cleaned;
        }

        // Add space after if needed
        if (/\w/.test(nextChar) && !/^\s/.test(nextChar)) {
          cleaned = cleaned + " ";
        }

        return cleaned;
      };

      const cleanedSuggestion = cleanSuggestion(suggestion);

      // Create the transaction
      editor
        .chain()
        .focus()
        .command(({ tr }) => {
          // Replace the text while preserving formatting
          const marks = tr.doc.resolve(wordStart).marks();
          tr.replaceWith(
            wordStart,
            wordEnd,
            editor.schema.text(cleanedSuggestion)
          );

          // Restore marks if any existed
          if (marks.length) {
            tr.addMark(
              wordStart,
              wordStart + cleanedSuggestion.length,
              marks[0]
            );
          }

          return true;
        })
        .run();

      setShowSuggestions(false);
    },
    [editor]
  );

  useEffect(() => {
    if (!editor) return;

    const handleKeyDown = (event) => {
      if (!showSuggestions) return;

      switch (event.key) {
        case "ArrowDown":
          event.preventDefault();
          setSelectedIndex((prev) =>
            Math.min(prev + 1, suggestions.length - 1)
          );
          break;
        case "ArrowUp":
          event.preventDefault();
          setSelectedIndex((prev) => Math.max(prev - 1, 0));
          break;
        case "Enter":
          event.preventDefault();
          if (suggestions[selectedIndex]) {
            applySuggestion(suggestions[selectedIndex]);
          }
          break;
        case "Escape":
          event.preventDefault();
          setShowSuggestions(false);
          break;
        case "Tab":
          if (showSuggestions) {
            event.preventDefault();
            if (suggestions[selectedIndex]) {
              applySuggestion(suggestions[selectedIndex]);
            }
          }
          break;
        default:
          break;
      }
    };

    const handleUpdate = debounce(() => {
      if (!editor.storage.document?.id) return;

      const { from } = editor.state.selection;
      cursorCoords.current = editor.view.coordsAtPos(from);

      const text = editor.getText();
      let wordStart = from;
      while (wordStart > 0 && !/\s/.test(text[wordStart - 1])) {
        wordStart--;
      }

      const currentWord = text.slice(wordStart, from);
      currentWordRef.current = currentWord;

      const precedingText = text.slice(
        Math.max(0, wordStart - CONTEXT_WINDOW_SIZE),
        wordStart
      );

      if (currentWord.length >= AUTOCOMPLETE_TRIGGER_CHARS) {
        fetchSuggestions(
          currentWord,
          precedingText,
          editor.storage.document.id
        );
      } else {
        setShowSuggestions(false);
      }
    }, 100);

    const handleClickOutside = (event) => {
      if (
        suggestionBoxRef.current &&
        !suggestionBoxRef.current.contains(event.target)
      ) {
        setShowSuggestions(false);
      }
    };

    editor.on("selectionUpdate", handleUpdate);
    editor.on("update", handleUpdate);
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      editor.off("selectionUpdate", handleUpdate);
      editor.off("update", handleUpdate);
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("mousedown", handleClickOutside);
      handleUpdate.cancel();
    };
  }, [
    editor,
    showSuggestions,
    suggestions,
    selectedIndex,
    applySuggestion,
    fetchSuggestions,
    positionSuggestionBox,
  ]);

  useEffect(() => {
    if (showSuggestions) {
      requestAnimationFrame(positionSuggestionBox);
    }
  }, [showSuggestions, positionSuggestionBox]);

  return (
    <div className="flex-1 overflow-auto p-4 relative">
      <div className="max-w-4xl mx-auto">
        <TiptapEditorContent editor={editor} />
        {showSuggestions && suggestions.length > 0 && (
          <div
            ref={suggestionBoxRef}
            className="fixed bg-white dark:bg-gray-800 shadow-lg rounded-md border border-gray-200 dark:border-gray-700"
            style={{
              width: "300px",
              maxHeight: "200px",
              overflowY: "auto",
            }}
          >
            {suggestions.map((suggestion, index) => (
              <div
                key={index}
                className={`
                  cursor-pointer p-2 text-sm border-b last:border-b-0
                  ${
                    index === selectedIndex
                      ? "bg-blue-50 dark:bg-gray-700"
                      : "hover:bg-gray-50 dark:hover:bg-gray-700"
                  }
                  dark:text-gray-200 dark:border-gray-700
                `}
                onClick={() => applySuggestion(suggestion)}
              >
                {suggestion}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
