// src/components/DocumentEditor/hooks/useWebSocket.js
import { useState, useEffect } from 'react';

export const useWebSocket = ({ documentId, user, editor, onActiveUsersChange }) => {
  const [isConnected, setIsConnected] = useState(false);
  const [error, setError] = useState(null);
  const [ws, setWs] = useState(null);

  useEffect(() => {
    if (!documentId) return;

    const wsUrl = `${process.env.REACT_APP_WS_URL}/ws/documents/${documentId}`;
    const socket = new WebSocket(wsUrl);

    socket.onopen = () => {
      setIsConnected(true);
      socket.send(JSON.stringify({
        type: "join",
        documentId,
        userId: user._id,
      }));
    };

    socket.onclose = () => setIsConnected(false);
    socket.onerror = () => {
      setIsConnected(false);
      setError("WebSocket connection failed");
    };

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      
      switch (data.type) {
        case "join_confirmed":
        case "user_joined":
        case "user_left":
          onActiveUsersChange(data.activeUsers);
          break;
        case "change":
          if (editor && data.userId !== user._id) {
            editor.commands.setContent(data.changes.content);
          }
          break;
        case "error":
          setError(data.message);
          break;
      }
    };

    setWs(socket);
    return () => socket.readyState === WebSocket.OPEN && socket.close();
  }, [documentId, user._id, editor, onActiveUsersChange]);

  return { isConnected, error, ws };
};
