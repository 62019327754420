import React, { useState } from 'react';
import { FileText, ChevronDown, ChevronUp, ChevronRight, ChevronLeft } from 'lucide-react';

const DOCS_PER_PAGE = 5;

const RelevantDocuments = ({ docs }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(docs.length / DOCS_PER_PAGE);
  const startIndex = (currentPage - 1) * DOCS_PER_PAGE;
  const visibleDocs = isExpanded 
    ? docs.slice(startIndex, startIndex + DOCS_PER_PAGE)
    : docs.slice(0, 3);

  const handleNextPage = () => {
    setCurrentPage(prev => Math.min(prev + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage(prev => Math.max(prev - 1, 1));
  };

  return (
    <div className="mt-3 pt-3 border-t border-gray-200 dark:border-gray-700">
      <div className="flex items-center justify-between mb-2">
        <p className="text-xs text-gray-500 dark:text-gray-400">
          Sources ({docs.length} documents)
        </p>
        {docs.length > 3 && (
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className="flex items-center gap-1 text-xs text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
          >
            {isExpanded ? (
              <>
                Show Less <ChevronUp size={14} />
              </>
            ) : (
              <>
                Show All <ChevronDown size={14} />
              </>
            )}
          </button>
        )}
      </div>

      <div className="space-y-2">
        {visibleDocs.map((doc, idx) => (
          <div
            key={idx}
            className="flex items-center gap-2 text-xs text-gray-600 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-800 rounded-lg p-1.5 transition-colors"
          >
            <FileText size={12} />
            <span className="truncate flex-1">{doc.fileName}</span>
            {doc.chunkIndex !== undefined && (
              <span className="text-gray-400 flex-shrink-0">
                (Chunk {doc.chunkIndex}
                {doc.similarity && `, ${(doc.similarity * 100).toFixed(1)}%`})
              </span>
            )}
          </div>
        ))}
      </div>

      {isExpanded && totalPages > 1 && (
        <div className="flex items-center justify-between mt-4 text-xs text-gray-500 dark:text-gray-400">
          <div className="flex items-center gap-2">
            <button
              onClick={handlePrevPage}
              disabled={currentPage === 1}
              className="p-1 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <ChevronLeft size={14} />
            </button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
              className="p-1 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <ChevronRight size={14} />
            </button>
          </div>
          <span>
            Showing {startIndex + 1}-{Math.min(startIndex + DOCS_PER_PAGE, docs.length)} of {docs.length}
          </span>
        </div>
      )}
    </div>
  );
};

export default RelevantDocuments;