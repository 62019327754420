import { useState, useCallback } from 'react';
import { copyWithFormatting } from '../utils/markdownUtils';

export const useChatActions = (navigate, logout) => {
  const [error, setError] = useState(null);
  const [showShareAlert, setShowShareAlert] = useState(false);

  const handleError = useCallback((err) => {
    if (err.message === "Unauthorized") {
      logout();
      navigate("/login");
    } else {
      setError(err.message);
    }
  }, [logout, navigate]);

  const copyMessage = async (text) => {
    try {
      const cleanedText = copyWithFormatting(text);
      await navigator.clipboard.writeText(cleanedText);
    } catch (err) {
      console.error("Failed to copy:", err);
      throw err;
    }
  };

  const shareResponse = async (question, answer) => {
    try {
      const shareData = {
        title: "Lambda AI Response",
        text: `Q: ${question}\n\nA: ${answer}`,
        url: window.location.href,
      };

      if (navigator.share) {
        await navigator.share(shareData);
      } else {
        await navigator.clipboard.writeText(shareData.text);
        setShowShareAlert(true);
        setTimeout(() => setShowShareAlert(false), 3000);
      }
    } catch (error) {
      console.error("Error sharing:", error);
      throw new Error("Failed to share response");
    }
  };

  const downloadResponse = async (content) => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/download/docx`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ content }),
        }
      );

      if (!response.ok) throw new Error("Download failed");

      const timestamp = new Date().toISOString().replace(/[:.]/g, "-");
      const uuid = crypto.randomUUID();
      const filename = `Lambda-AI-Assistant-${uuid}-${timestamp}.docx`;

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error("Download error:", error);
      throw error;
    }
  };

  return {
    error,
    setError,
    showShareAlert,
    handleError,
    copyMessage,
    shareResponse,
    downloadResponse,
  };
};