// src/components/google-docs/editor/EditorHeader.jsx
import React from 'react';
import { Wifi, WifiOff, Users, X } from 'lucide-react';
import { ConnectionStatus } from './ConnectionStatus';
import { HeaderActions } from './HeaderActions';

export const EditorHeader = ({
  documentName,
  onTitleChange,
  isConnected,
  activeUsers,
  saving,
  onClose
}) => (
  <div className="flex items-center justify-between px-4 py-2 border-b border-gray-200 dark:border-gray-700">
    <div className="flex items-center gap-4">
      <input
        type="text"
        value={documentName}
        onChange={onTitleChange}
        className="bg-transparent border-none focus:outline-none text-lg font-medium text-gray-900 dark:text-white"
      />
      <ConnectionStatus isConnected={isConnected} activeUsers={activeUsers} saving={saving} />
    </div>
    <HeaderActions activeUsers={activeUsers} onClose={onClose} />
  </div>
);