import React, { useState, useEffect, useCallback } from 'react';
import { FileText, Trash2, MessageSquare, Loader, File, AlertCircle } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import toast from 'react-hot-toast';

const DocumentCard = ({ doc, onDelete, onChat, userName }) => {
  const getFileIcon = (fileName) => {
    const extension = fileName.split('.').pop().toLowerCase();
    const commonTypes = {
      pdf: 'from-red-500 to-rose-500',
      doc: 'from-blue-500 to-indigo-500',
      docx: 'from-blue-500 to-indigo-500',
      txt: 'from-gray-500 to-slate-500'
    };

    return commonTypes[extension] || 'from-violet-500 to-fuchsia-500';
  };

  const formatDate = (date) => {
    const now = new Date();
    const docDate = new Date(date);
    const diffTime = Math.abs(now - docDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays === 0) return 'Today';
    if (diffDays === 1) return 'Yesterday';
    if (diffDays < 7) return `${diffDays} days ago`;
    return new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: 'numeric',
      year: docDate.getFullYear() !== now.getFullYear() ? 'numeric' : undefined
    }).format(docDate);
  };

  const formatTime = (date) => {
    return new Intl.DateTimeFormat('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    }).format(new Date(date));
  };

  return (
    <div className="group bg-gradient-to-br from-white to-gray-50 dark:from-gray-800 dark:to-gray-900 rounded-xl shadow-sm border border-gray-200/50 dark:border-gray-700/50 hover:border-violet-500 dark:hover:border-violet-500/50 transition-all duration-200 hover:shadow-lg hover:shadow-violet-500/10 relative overflow-hidden">
      {/* Decorative gradient blob */}
      <div className="absolute -top-12 -right-12 w-24 h-24 bg-gradient-to-br from-violet-500/20 to-fuchsia-500/20 blur-2xl rounded-full" />
      
      <div className="p-4 relative">
        <div className="flex items-start gap-3">
          <div className={`p-2 bg-gradient-to-br ${getFileIcon(doc.fileName)} rounded-lg shadow-sm`}>
            <File size={20} className="text-white" />
          </div>
          <div className="flex-1 min-w-0">
            <h3 className="font-medium text-gray-900 dark:text-white truncate group-hover:text-violet-500 dark:group-hover:text-violet-400 transition-colors" title={doc.fileName}>
              {doc.fileName}
            </h3>
            <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
              Added {formatDate(doc.createdAt)}
            </p>
          </div>
        </div>

        <div className="mt-4 pt-4 border-t border-gray-100 dark:border-gray-800 flex items-center justify-between">
          <div className="text-xs text-gray-500 dark:text-gray-400 truncate flex-1">
            {doc.history?.length > 0 ? (
              <div className="flex items-center gap-1.5">
                <MessageSquare size={14} />
                <span title={new Date(doc.history[doc.history.length - 1].timestamp).toLocaleString()}>
                  Last chat: {formatTime(doc.history[doc.history.length - 1].timestamp)}
                </span>
              </div>
            ) : (
              <div className="flex items-center gap-1.5 text-gray-400 dark:text-gray-500">
                <MessageSquare size={14} />
                <span>No interactions yet</span>
              </div>
            )}
          </div>
          
          <div className="flex gap-1">
            <button
              onClick={() => onChat(doc.conversationId)}
              className="p-2 text-gray-400 hover:text-violet-500 hover:bg-violet-50 dark:hover:bg-violet-900/30 rounded-lg transition-all"
              title="Chat with document"
            >
              <MessageSquare size={18} />
            </button>
            <button
              onClick={() => onDelete(doc.conversationId)}
              className="p-2 text-gray-400 hover:text-red-500 hover:bg-red-50 dark:hover:bg-red-900/30 rounded-lg transition-all"
              title="Delete document"
            >
              <Trash2 size={18} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const DocumentsPage = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalDocuments, setTotalDocuments] = useState(0);

  const fetchDocuments = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/document-operations/list?page=${currentPage}&limit=6`,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );

      if (response.status === 401) {
        logout();
        navigate('/login');
        return;
      }
      
      const data = await response.json();
      if (!response.ok) throw new Error(data.error);
      
      setDocuments(data.documents);
      setTotalPages(data.totalPages);
      setTotalDocuments(data.totalDocuments);
    } catch (err) {
      toast.error(err.message);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, [currentPage, logout, navigate]);

  useEffect(() => {
    fetchDocuments();
  }, [fetchDocuments]);

  const handleDelete = async (conversationId) => {
    toast((t) => (
      <div className="flex flex-col space-y-2">
        <p className="font-medium">Delete this document?</p>
        <div className="flex space-x-2">
          <button
            className="px-3 py-1 bg-red-600 text-white rounded-md text-sm hover:bg-red-700"
            onClick={() => {
              deleteDocument(conversationId);
              toast.dismiss(t.id);
            }}
          >
            Delete
          </button>
          <button
            className="px-3 py-1 bg-gray-200 text-gray-800 rounded-md text-sm hover:bg-gray-300"
            onClick={() => toast.dismiss(t.id)}
          >
            Cancel
          </button>
        </div>
      </div>
    ));
  };

  const deleteDocument = async (conversationId) => {
    const loadingToast = toast.loading('Deleting document...');
    
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/document-operations/delete/${conversationId}`,
        {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );

      if (response.status === 401) {
        logout();
        navigate('/login');
        return;
      }
      
      if (!response.ok) throw new Error('Failed to delete document');
      
      setDocuments(docs => docs.filter(doc => doc.conversationId !== conversationId));
      toast.success('Document deleted successfully', { id: loadingToast });
      
      // Update total documents count
      setTotalDocuments(prev => prev - 1);
    } catch (err) {
      toast.error(err.message, { id: loadingToast });
    }
  };

  const handlePagination = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-[60vh]">
        <Loader className="h-8 w-8 animate-spin text-violet-600 dark:text-violet-400" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 dark:bg-red-900/30 text-red-600 dark:text-red-400 rounded-lg flex items-center gap-2 p-4">
        <AlertCircle className="h-5 w-5 flex-shrink-0" />
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center gap-4">
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
          Welcome, {user.firstName}
        </h1>
        <p className="text-gray-500 dark:text-gray-400">
          {totalDocuments} {totalDocuments === 1 ? 'document' : 'documents'}
        </p>
      </div>
      
      <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3">
        {documents.map((doc) => (
          <DocumentCard
            key={doc.conversationId}
            doc={doc}
            onDelete={handleDelete}
            onChat={(conversationId) => navigate(`/chat/${conversationId}`)}
            userName={`${user.firstName} ${user.lastName}`}
          />
        ))}
      </div>
      
      {documents.length === 0 && (
        <div className="text-center py-12 bg-gradient-to-br from-white to-gray-50 dark:from-gray-800 dark:to-gray-900 rounded-xl border border-dashed border-gray-200 dark:border-gray-700">
          <FileText size={48} className="mx-auto text-gray-400 dark:text-gray-500 mb-4" />
          <h3 className="text-lg font-medium text-gray-900 dark:text-white">
            No documents yet, {user.firstName}
          </h3>
          <p className="text-gray-500 dark:text-gray-400 mt-1 max-w-sm mx-auto">
            Upload your first document to start chatting with your content
          </p>
        </div>
      )}
      
      {totalPages > 1 && (
        <div className="flex justify-center items-center gap-4 mt-6">
          <button
            onClick={() => handlePagination(currentPage - 1)}
            disabled={currentPage === 1}
            className="px-4 py-2 text-sm font-medium bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700/50 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
          >
            Previous
          </button>
          <span className="text-sm font-medium text-gray-700 dark:text-gray-300">
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={() => handlePagination(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="px-4 py-2 text-sm font-medium bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700/50 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
};

export default DocumentsPage;