import { AlertCircle, Globe, Loader } from "lucide-react";
import React, { useCallback, useEffect, useState } from "react";
import AddWebsiteModal from "./AddWebsiteModal";
import WebContentCard from "./WebContentCard";

const WebContentPage = () => {
  const [webContents, setWebContents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const fetchWebContents = useCallback(async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/webcontent/list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) throw new Error("Failed to fetch web contents");

      const data = await response.json();
      setWebContents(data.webContents);
      setError(null);
    } catch (error) {
      console.error("Fetch error:", error);
      setError("Failed to load web contents. Please try again.");
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchWebContents();
  }, [fetchWebContents]);

  const handleAddWebsite = () => {
    setIsAddModalOpen(true);
  };

  const handleModalClose = () => {
    setIsAddModalOpen(false);
  };

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center h-64 space-y-4">
        <Loader className="w-8 h-8 animate-spin text-indigo-600" />
        <p className="text-sm text-gray-500 dark:text-gray-400">
          Loading web contents...
        </p>
      </div>
    );
  }

  return (
    <div className="space-y-6 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
        <div>
          <h1 className="text-xl sm:text-2xl font-bold text-gray-900 dark:text-white">
            Web Content
          </h1>
          <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
            Add and manage your website content for chat interactions
          </p>
        </div>
        <button
          onClick={handleAddWebsite}
          className="w-full sm:w-auto flex items-center justify-center px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors shadow-sm"
        >
          <Globe className="w-4 h-4 mr-2" />
          Add Website
        </button>
      </div>

      {error && (
        <div className="bg-red-50 dark:bg-red-900/30 p-4 rounded-lg flex items-center text-red-800 dark:text-red-300">
          <AlertCircle className="w-5 h-5 mr-2 flex-shrink-0" />
          <span className="text-sm">{error}</span>
        </div>
      )}

      {webContents.length === 0 && !error ? (
        <div className="text-center py-12">
          <Globe className="mx-auto h-12 w-12 text-gray-400" />
          <h3 className="mt-2 text-sm font-medium text-gray-900 dark:text-white">
            No web content
          </h3>
          <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
            Get started by adding a website to chat with.
          </p>
          <div className="mt-6">
            <button
              onClick={handleAddWebsite}
              className="inline-flex items-center px-4 py-2 text-sm font-medium text-indigo-600 bg-indigo-100 border border-transparent rounded-md hover:bg-indigo-200 dark:bg-indigo-900/30 dark:text-indigo-400 dark:hover:bg-indigo-900/50"
            >
              <Globe className="w-4 h-4 mr-2" />
              Add your first website
            </button>
          </div>
        </div>
      ) : (
        <div className="grid gap-4 sm:gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
          {webContents.map((content) => (
            <WebContentCard
              key={content.contentId}
              content={content}
              onRefresh={fetchWebContents}
            />
          ))}
        </div>
      )}

      <AddWebsiteModal
        isOpen={isAddModalOpen}
        onClose={handleModalClose}
        onSuccess={fetchWebContents}
      />
    </div>
  );
};

export default WebContentPage;