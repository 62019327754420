import React, { useState } from 'react';
import { X, Loader, AlertCircle, Check } from 'lucide-react';

const CollaboratorModal = ({ 
  isOpen, 
  onClose, 
  email, 
  setEmail, 
  role, 
  setRole, 
  onAdd 
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [validUser, setValidUser] = useState(null);

  const checkUserExists = async (email) => {
    try {
      setLoading(true);
      setError(null);
      setValidUser(null);

      const token = localStorage.getItem("token");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/collaborative-documents/check-collaborator`,
        {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ email })
        }
      );

      const data = await response.json();

      if (!response.ok) {
        setError(data.message || 'Failed to verify user.');
        return;
      }

      setValidUser(data);
    } catch (err) {
      setError('Failed to check user existence.');
    } finally {
      setLoading(false);
    }
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setValidUser(null);
    setError(null);
  };

  const handleEmailBlur = () => {
    if (email && email.includes('@')) {
      checkUserExists(email);
    }
  };

  const handleSubmit = async () => {
    if (!validUser) {
      setError('Please enter a valid user email.');
      return;
    }
    await onAdd();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4">
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg w-full max-w-md">
        <div className="flex items-center justify-between px-4 py-3 border-b border-gray-200 dark:border-gray-700">
          <h2 className="text-lg font-medium text-gray-900 dark:text-white">
            Add Collaborator
          </h2>
          <button
            onClick={onClose}
            className="p-2 text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 rounded-full transition-colors"
          >
            <X size={20} />
          </button>
        </div>
        <div className="p-4">
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Email Address
            </label>
            <div className="relative">
              <input
                type="email"
                placeholder="collaborator@example.com"
                value={email}
                onChange={handleEmailChange}
                onBlur={handleEmailBlur}
                className={`w-full px-4 py-2 border ${
                  error ? 'border-red-300' : 'border-gray-200 dark:border-gray-700'
                } rounded-lg focus:ring-2 focus:ring-indigo-500 dark:bg-gray-800 dark:text-white pr-10`}
              />
              {loading && (
                <Loader className="absolute right-3 top-1/2 transform -translate-y-1/2 w-4 h-4 animate-spin text-gray-400" />
              )}
              {validUser && (
                <Check className="absolute right-3 top-1/2 transform -translate-y-1/2 w-4 h-4 text-green-500" />
              )}
            </div>
            {validUser && (
              <p className="mt-1 text-sm text-green-600 dark:text-green-400">
                User found: {validUser.name}
              </p>
            )}
            {error && (
              <p className="mt-1 text-sm text-red-600 dark:text-red-400 flex items-center gap-1">
                <AlertCircle className="w-4 h-4" />
                {error}
              </p>
            )}
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Role
            </label>
            <select
              value={role}
              onChange={(e) => setRole(e.target.value)}
              className="w-full px-4 py-2 border border-gray-200 dark:border-gray-700 rounded-lg focus:ring-2 focus:ring-indigo-500 dark:bg-gray-800 dark:text-white"
            >
              <option value="editor">Editor</option>
              <option value="viewer">Viewer</option>
            </select>
          </div>
          <div className="flex flex-col sm:flex-row justify-end gap-2">
            <button
              onClick={onClose}
              className="px-4 py-2 text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 rounded-lg transition-colors"
            >
              Cancel
            </button>
            <button
              onClick={handleSubmit}
              disabled={!validUser || loading}
              className={`px-4 py-2 rounded-lg transition-colors ${
                !validUser || loading
                  ? 'bg-indigo-400 cursor-not-allowed'
                  : 'bg-indigo-600 hover:bg-indigo-700 text-white'
              }`}
            >
              Add Collaborator
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollaboratorModal;