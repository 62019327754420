// components/modals/DocumentEditorModal.jsx
import React from 'react';
import { X } from 'lucide-react';
import DocumentEditor from '../DocumentEditor';

const DocumentEditorModal = ({ isOpen, document, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4 overflow-y-auto">
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg w-full max-w-5xl my-6 h-[90vh] flex flex-col">
        <div className="flex items-center justify-between px-4 py-3 border-b border-gray-200 dark:border-gray-700">
          <h2 className="text-lg font-medium text-gray-900 dark:text-white">
            {document.title}
          </h2>
          <button
            onClick={onClose}
            className="p-2 text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 rounded-full transition-colors"
          >
            <X size={20} />
          </button>
        </div>
        <div className="flex-1 overflow-hidden">
          <DocumentEditor
            existingDocument={document}
            initialContent={document.content || ""}
            onClose={onClose}
          />
        </div>
      </div>
    </div>
  );
};

export default DocumentEditorModal