// File: src/components/chat/MessageActions.jsx
import React, { useState } from 'react';
import { 
  AlertCircle,
  Check,
  Copy,
  Download,
  Loader,
  RefreshCw,
  Share2,
  ThumbsDown,
  ThumbsUp,
  Trash2
} from 'lucide-react';
import DocumentEditorButton from '../google-docs/DocumentEditorButton';
import DeleteConfirmationModal from '../DeleteConfirmationModal';

const MessageActions = ({
  onCopy,
  onShare,
  onRegenerate,
  onDownload,
  onClear,
  isResponse,
  isRegenerating = false,
  content,
  documentIds,
  isProjectWide,
  isLastMessage,
}) => {
  const [copied, setCopied] = useState(false);
  const [liked, setLiked] = useState(null);
  const [downloading, setDownloading] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleCopy = async () => {
    try {
      await onCopy();
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (error) {
      console.error("Copy failed:", error);
      setShowTooltip(true);
      setTimeout(() => setShowTooltip(false), 2000);
    }
  };

  const handleDownload = async () => {
    if (downloading) return;
    setDownloading(true);
    try {
      await onDownload();
    } catch (error) {
      console.error("Download failed:", error);
      setShowTooltip(true);
      setTimeout(() => setShowTooltip(false), 2000);
    } finally {
      setDownloading(false);
    }
  };

  return (
    <div className="relative flex items-center gap-1 sm:opacity-0 sm:group-hover:opacity-100 opacity-100 transition-opacity">
      <div className="flex flex-wrap items-center gap-1">
        <button
          onClick={handleCopy}
          className="p-1.5 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-md transition-colors"
          title={copied ? "Copied!" : "Copy message"}
        >
          {copied ? <Check size={14} /> : <Copy size={14} />}
        </button>

        {isResponse && (
          <>
            <button
              onClick={onRegenerate}
              className="p-1.5 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-md transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
              title="Regenerate response"
              disabled={isRegenerating}
            >
              <RefreshCw
                size={14}
                className={isRegenerating ? "animate-spin" : ""}
              />
            </button>

            <button
              onClick={handleDownload}
              className="p-1.5 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-md transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
              title="Download response"
              disabled={downloading}
            >
              {downloading ? (
                <Loader size={14} className="animate-spin" />
              ) : (
                <Download size={14} />
              )}
            </button>

            {isProjectWide && isLastMessage && (
              <button
                onClick={() => setShowDeleteModal(true)}
                className="p-1.5 text-gray-500 hover:text-red-600 dark:text-gray-400 dark:hover:text-red-400 hover:bg-red-50 dark:hover:bg-red-900/30 rounded-md transition-colors"
                title="Clear chat history"
              >
                <Trash2 size={14} />
              </button>
            )}

            <DocumentEditorButton content={content} />
          </>
        )}

        <button
          onClick={onShare}
          className="p-1.5 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-md transition-colors"
          title="Share message"
        >
          <Share2 size={14} />
        </button>

        <div className="flex items-center gap-0.5">
          <button
            onClick={() => setLiked(true)}
            className={`p-1.5 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-md transition-colors ${
              liked === true
                ? "text-green-500 dark:text-green-400"
                : "text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
            }`}
            title="Helpful"
          >
            <ThumbsUp size={14} />
          </button>

          <button
            onClick={() => setLiked(false)}
            className={`p-1.5 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-md transition-colors ${
              liked === false
                ? "text-red-500 dark:text-red-400"
                : "text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
            }`}
            title="Not helpful"
          >
            <ThumbsDown size={14} />
          </button>
        </div>
      </div>

      {showTooltip && (
        <div className="absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 bg-red-50 dark:bg-red-900/50 text-red-600 dark:text-red-400 text-xs px-2 py-1 rounded-md flex items-center gap-1">
          <AlertCircle size={12} />
          <span>Action failed. Please try again.</span>
        </div>
      )}

      {showDeleteModal && (
        <DeleteConfirmationModal
          isOpen={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          onConfirm={onClear}
          title="chat history"
        />
      )}
    </div>
  );
};

export default MessageActions;