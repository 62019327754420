import {
  ExternalLink,
  LinkIcon,
  Loader,
  MessageSquare,
  MoreVertical,
  Trash2
} from "lucide-react";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DeleteConfirmationModal from "./DeleteConfirmationModal";

// Action Menu Component
const ActionMenu = ({
  isOpen,
  onClose,
  onOpenChat,
  onDelete,
  isProcessing,
  isLoading,
  contentTitle
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleDeleteClick = (e) => {
    e.stopPropagation();
    setShowDeleteModal(true);
    onClose(); // Close the action menu
  };

  if (!isOpen && !showDeleteModal) return null;

  return (
    <>
      {isOpen && (
        <div
          className="absolute right-0 mt-1 w-48 bg-white dark:bg-gray-800 rounded-md shadow-lg border border-gray-200 dark:border-gray-700 py-1 z-10"
          onClick={(e) => e.stopPropagation()}
        >
          <button
            onClick={onOpenChat}
            disabled={isProcessing}
            className="w-full px-4 py-2 text-left text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 flex items-center gap-2 disabled:opacity-50"
          >
            <MessageSquare className="w-4 h-4" />
            Open Chat
          </button>
          <button
            onClick={handleDeleteClick}
            disabled={isLoading}
            className="w-full px-4 py-2 text-left text-sm text-red-600 dark:text-red-400 hover:bg-red-50 dark:hover:bg-red-900/50 flex items-center gap-2"
          >
            {isLoading ? (
              <Loader className="w-4 h-4 animate-spin" />
            ) : (
              <Trash2 className="w-4 h-4" />
            )}
            Delete
          </button>
        </div>
      )}

      <DeleteConfirmationModal
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={onDelete}
        loading={isLoading}
        title={contentTitle}
      />
    </>
  );
};

// Status Badge Component
const StatusBadge = ({ status }) => {
  const statusStyles = {
    completed: "bg-green-100 dark:bg-green-900 text-green-700 dark:text-green-300",
    failed: "bg-red-100 dark:bg-red-900 text-red-700 dark:text-red-300",
    processing: "bg-yellow-100 dark:bg-yellow-900 text-yellow-700 dark:text-yellow-300"
  };

  return (
    <span
      className={`text-xs px-2 py-1 rounded-full flex items-center gap-1 justify-center ${statusStyles[status]}`}
    >
      {status === "processing" && <Loader className="w-3 h-3 animate-spin" />}
      {status.charAt(0).toUpperCase() + status.slice(1)}
    </span>
  );
};

const WebContentCard = ({ content, onRefresh }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showActions, setShowActions] = useState(false);

  const handleClickOutside = useCallback(
    (e) => {
      if (showActions) setShowActions(false);
    },
    [showActions]
  );

  useEffect(() => {
    if (showActions) {
      document.addEventListener("click", handleClickOutside);
      return () => document.removeEventListener("click", handleClickOutside);
    }
  }, [showActions, handleClickOutside]);

  const handleDelete = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/webcontent/${content.contentId}`,
        {
          method: "DELETE",
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      
      if (!response.ok) {
        throw new Error("Failed to delete web content");
      }
      
      onRefresh();
    } catch (error) {
      console.error("Delete error:", error);
    } finally {
      setLoading(false);
      setShowActions(false);
    }
  };

  const handleOpenChat = () => {
    const chatId = `web-${content.contentId}`;
    navigate(`/chat/${chatId}`);
  };

  const formatUrl = (url) => {
    try {
      const urlObject = new URL(url);
      return urlObject.hostname;
    } catch (error) {
      return url;
    }
  };

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm border border-gray-200 dark:border-gray-700 p-4 transition-all hover:shadow-md flex flex-col">
      <div className="flex items-start justify-between">
        <div className="flex-1 min-w-0">
          <h3 className="text-base font-medium text-gray-900 dark:text-white truncate">
            {content.title || content.url}
          </h3>
          <a
            href={content.url}
            target="_blank"
            rel="noopener noreferrer"
            className="text-sm text-gray-500 dark:text-gray-400 hover:text-indigo-600 dark:hover:text-indigo-400 flex items-center gap-1"
            onClick={(e) => e.stopPropagation()}
          >
            <LinkIcon className="w-4 h-4 flex-shrink-0" />
            <span className="truncate">{formatUrl(content.url)}</span>
            <ExternalLink className="w-4 h-4 flex-shrink-0" />
          </a>
        </div>

        <div className="relative flex-shrink-0">
          <button
            onClick={(e) => {
              e.stopPropagation();
              setShowActions(!showActions);
            }}
            className="p-1 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300 rounded-full transition-colors"
          >
            <MoreVertical className="w-5 h-5" />
          </button>
          <ActionMenu
            isOpen={showActions}
            onClose={() => setShowActions(false)}
            onOpenChat={handleOpenChat}
            onDelete={handleDelete}
            isProcessing={content.processingStatus !== "completed"}
            isLoading={loading}
            contentTitle={content.title || formatUrl(content.url)}
          />
        </div>
      </div>

      <div className="flex items-center justify-between mt-2">
        <div className="flex items-center gap-2">
          <StatusBadge status={content.processingStatus} />
          <span className="text-sm text-gray-500 dark:text-gray-400">
            Added {new Date(content.createdAt).toLocaleDateString()}
          </span>
        </div>
      </div>

      {content.processingStatus === "completed" && (
        <button
          onClick={handleOpenChat}
          className="mt-4 w-full flex items-center justify-center gap-2 px-4 py-2 bg-indigo-50 dark:bg-indigo-900/30 text-indigo-600 dark:text-indigo-400 rounded-lg hover:bg-indigo-100 dark:hover:bg-indigo-900/50 transition-colors"
        >
          <MessageSquare className="w-4 h-4" />
          Start Chatting
        </button>
      )}
    </div>
  );
};

export default WebContentCard;