// components/DocumentCard/index.jsx
import React, { useState, useRef, useEffect } from 'react';
import {
  File,
  Users,
  Clock,
  CalendarRange,
  Download,
  Settings,
  History,
  UserPlus,
  Trash2,
} from 'lucide-react';

const formatDate = (dateString) => {
  if (!dateString) return "";
  return new Intl.DateTimeFormat('en-US', {
    dateStyle: 'medium',
    timeStyle: 'short'
  }).format(new Date(dateString));
};

const DocumentCard = ({ doc, user, onDocumentClick, onMenuAction, isOwner }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div
      className="relative bg-white dark:bg-gray-800 rounded-lg overflow-hidden hover:shadow-lg transition-all duration-300"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* Gradient Border */}
      <div className="absolute inset-0 rounded-lg bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 p-[1px]">
        <div className="absolute inset-0 bg-white dark:bg-gray-800 rounded-[7px]" />
      </div>

      {/* Content */}
      <div className="relative p-4">
        {/* Header */}
        <div className="flex justify-between items-start gap-3">
          <div 
            className="flex-1 min-w-0 cursor-pointer group"
            onClick={() => onDocumentClick(doc)}
          >
            <div className="flex items-center gap-3 mb-2">
              <div className={`p-2 rounded-lg transition-colors duration-300 ${
                isHovered 
                  ? 'bg-indigo-50 dark:bg-indigo-900/30' 
                  : 'bg-gray-50 dark:bg-gray-700/50'
              }`}>
                <File className="w-5 h-5 text-indigo-600 dark:text-indigo-400" />
              </div>
              <div className="flex-1 min-w-0">
                <div className="flex items-center gap-2 mb-0.5">
                  <h3 className="font-semibold text-gray-900 dark:text-white truncate group-hover:text-indigo-600 dark:group-hover:text-indigo-400 transition-colors">
                    {doc.title}
                  </h3>
                  {doc.version > 1 && (
                    <span className="flex-shrink-0 text-xs bg-indigo-50 dark:bg-indigo-900/30 text-indigo-600 dark:text-indigo-400 px-1.5 py-0.5 rounded-full font-medium">
                      v{doc.version}
                    </span>
                  )}
                </div>
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  {isOwner(user, doc.owner) ? (
                    <span className="text-indigo-600 dark:text-indigo-400">Owned by you</span>
                  ) : (
                    <span>Owned by {doc.owner.firstName} {doc.owner.lastName}</span>
                  )}
                </p>
              </div>
            </div>
          </div>

          {/* Menu Button */}
          <div className="relative" ref={dropdownRef}>
            <button
              onClick={(e) => {
                e.stopPropagation();
                setShowDropdown(!showDropdown);
              }}
              className={`p-1.5 rounded-full transition-colors duration-200 ${
                showDropdown 
                  ? 'bg-gray-100 dark:bg-gray-700' 
                  : 'hover:bg-gray-100 dark:hover:bg-gray-700'
              }`}
              aria-label="Document menu"
            >
              <Settings className={`w-4 h-4 ${
                showDropdown 
                  ? 'text-indigo-600 dark:text-indigo-400' 
                  : 'text-gray-500 dark:text-gray-400'
              }`} />
            </button>

            {showDropdown && (
              <div className="absolute right-0 mt-2 w-48 bg-white dark:bg-gray-800 rounded-lg shadow-lg border border-gray-200 dark:border-gray-700 z-50">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    onMenuAction('history', doc);
                    setShowDropdown(false);
                  }}
                  className="flex items-center gap-2 w-full px-3 py-1 text-left text-sm hover:bg-gray-50 dark:hover:bg-gray-700/50 transition-colors duration-200"
                >
                  <History className="w-4 h-4 text-gray-500" />
                  <span>View History</span>
                </button>
                
                {isOwner(user, doc.owner) && (
                  <>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        onMenuAction('addCollaborator', doc);
                        setShowDropdown(false);
                      }}
                      className="flex items-center gap-2 w-full px-3 py-1 text-left text-sm hover:bg-gray-50 dark:hover:bg-gray-700/50 transition-colors duration-200 border-t border-gray-200 dark:border-gray-700"
                    >
                      <UserPlus className="w-4 h-4 text-gray-500" />
                      <span>Add Collaborator</span>
                    </button>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        onMenuAction('delete', doc);
                        setShowDropdown(false);
                      }}
                      className="flex items-center gap-2 w-full px-3 py-1 text-left text-sm text-red-600 hover:bg-red-50 dark:hover:bg-red-900/10 transition-colors duration-200 border-t border-gray-200 dark:border-gray-700"
                    >
                      <Trash2 className="w-4 h-4" />
                      <span>Delete Document</span>
                    </button>
                  </>
                )}
              </div>
            )}
          </div>
        </div>

        {/* Metadata */}
        <div className="grid grid-cols-2 gap-2 mt-3">
          <div className="flex items-center gap-2 text-sm text-gray-600 dark:text-gray-300">
            <Users className="w-4 h-4 text-blue-500" />
            <span className="truncate">{doc.collaborators.length} collaborator{doc.collaborators.length !== 1 ? 's' : ''}</span>
          </div>
          <div className="flex items-center gap-2 text-sm text-gray-600 dark:text-gray-300">
            <Clock className="w-4 h-4 text-green-500" />
            <span className="truncate">{formatDate(doc.lastModified || doc.updatedAt).split(',')[0]}</span>
          </div>
          <div className="flex items-center gap-2 text-sm text-gray-600 dark:text-gray-300">
            <CalendarRange className="w-4 h-4 text-purple-500" />
            <span className="truncate">Created {formatDate(doc.createdAt).split(',')[0]}</span>
          </div>
          {(doc.exportCount > 0) && (
            <div className="flex items-center gap-2 text-sm text-gray-600 dark:text-gray-300">
              <Download className="w-4 h-4 text-orange-500" />
              <span className="truncate">{doc.exportCount} export{doc.exportCount !== 1 ? 's' : ''}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DocumentCard;