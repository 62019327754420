import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Layout from "./components/Layout";
import DocumentsPage from "./components/DocumentsPage";
import ProjectsPage from "./components/projects/ProjectsPage";
import ProjectDetails from "./components/projects/ProjectDetails";
import ChatPage from "./components/ChatPage";
import LoginForm from "./components/auth/LoginForm";
import RegisterForm from "./components/auth/RegisterForm";
import ProtectedRoute from "./components/ProtectedRoute";
import { AuthProvider } from "./context/AuthContext";
import ThemeProvider from "./context/ThemeProvider";
import WebContentPage from "./components/web/WebContentPage";
import GeneralChatPage from "./components/general-chat/GeneralChatPage";
import OTPVerification from "./components/auth/OTPVerification";
import EmailVerification from "./components/auth/EmailVerification";
import ResetPassword from "./components/auth/ResetPassword";
import UserSettings from "./components/auth/UserSettings";
import DashboardPage from "./components/dashboard/DashboardPage";
import DatabasePage from "./components/db-query/DatabasePage";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsOfService from "./components/TermsOfService";
import CollaborativeDocumentList from "./components/google-docs/CollaborativeDocumentList";

const App = () => {
  return (
    <AuthProvider>
      <ThemeProvider>
        <BrowserRouter>
          <Routes>
            {/* Public routes */}
            <Route path="/login" element={<LoginForm />} />
            <Route path="/register" element={<RegisterForm />} />
            <Route path="/verify-otp/:userId" element={<OTPVerification />} />
            <Route
              path="/verify-email/:token"
              element={<EmailVerification />}
            />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />

            {/* Protected routes */}
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <Layout>
                    <Navigate to="/documents" replace />
                  </Layout>
                </ProtectedRoute>
              }
            />

            <Route
              path="/documents"
              element={
                <ProtectedRoute>
                  <Layout>
                    <DocumentsPage />
                  </Layout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/database"
              element={
                <ProtectedRoute>
                  <Layout>
                    <DatabasePage />
                  </Layout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <Layout>
                    <DashboardPage />
                  </Layout>
                </ProtectedRoute>
              }
            />

            <Route
              path="/settings"
              element={
                <ProtectedRoute>
                  <Layout>
                    <UserSettings />
                  </Layout>
                </ProtectedRoute>
              }
            />

            <Route
              path="/projects"
              element={
                <ProtectedRoute>
                  <Layout>
                    <ProjectsPage />
                  </Layout>
                </ProtectedRoute>
              }
            />

            <Route
              path="/webcontent"
              element={
                <ProtectedRoute>
                  <Layout>
                    <WebContentPage />
                  </Layout>
                </ProtectedRoute>
              }
            />

            <Route
              path="/general-chat"
              element={
                <ProtectedRoute>
                  <Layout>
                    <GeneralChatPage />
                  </Layout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/collaborative-documents"
              element={
                <ProtectedRoute>
                  <Layout>
                    <CollaborativeDocumentList />
                  </Layout>
                </ProtectedRoute>
              }
            />

            <Route
              path="/projects/:projectId"
              element={
                <ProtectedRoute>
                  <Layout>
                    <ProjectDetails />
                  </Layout>
                </ProtectedRoute>
              }
            />

            <Route
              path="/chat/:conversationId?"
              element={
                <ProtectedRoute>
                  <Layout>
                    <ChatPage />
                  </Layout>
                </ProtectedRoute>
              }
            />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </AuthProvider>
  );
};

export default App;
