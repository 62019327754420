import React, { useState } from 'react';
import { FileEdit, X, Loader, Check } from 'lucide-react';
import DocumentEditor from './DocumentEditor';

const DocumentEditorButton = ({ content }) => {
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const handleOpenEditor = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsEditorOpen(true);
      setIsLoading(false);
    }, 300);
  };

  const handleCloseEditor = () => {
    setIsEditorOpen(false);
  };

  const handleDocumentCreated = () => {
    handleCloseEditor();
    setShowSuccess(true);
    setTimeout(() => setShowSuccess(false), 3000);
  };

  return (
    <>
      <button
        onClick={handleOpenEditor}
        className="p-1.5 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-md transition-colors flex items-center gap-2 relative"
        title="Create new document from chat"
        disabled={isLoading}
      >
        {isLoading ? (
          <>
            <Loader size={14} className="animate-spin" />
            <span className="text-xs">Creating...</span>
          </>
        ) : (
          <>
            <FileEdit size={14} />
            <span className="text-xs">Create Document</span>
          </>
        )}

        {/* Success indicator */}
        {showSuccess && (
          <div className="absolute -top-8 left-1/2 transform -translate-x-1/2 bg-green-50 dark:bg-green-900/30 text-green-600 dark:text-green-400 text-xs px-2 py-1 rounded-md whitespace-nowrap flex items-center gap-1">
            <Check size={12} />
            Document created
          </div>
        )}
      </button>

      {isEditorOpen && (
        <div className="fixed inset-0 bg-black/50 z-50 backdrop-blur-sm flex items-center justify-center">
          <div className="bg-white dark:bg-gray-900 w-full max-w-5xl h-[90vh] rounded-lg shadow-xl flex flex-col relative">
            <button
              onClick={handleCloseEditor}
              className="absolute right-4 top-4 p-1 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg text-gray-500 dark:text-gray-400 z-10"
              title="Close editor"
            >
              <X className="w-5 h-5" />
            </button>

            <div className="flex-1 overflow-hidden">
              <DocumentEditor 
                initialContent={content} 
                onClose={handleCloseEditor}
                defaultTitle={`Chat Document - ${new Date().toLocaleString()}`}
                onDocumentCreated={handleDocumentCreated}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DocumentEditorButton;