import React, { useCallback, useState } from 'react';
import { useEditor } from '@tiptap/react';
import { useAuth } from "../../context/AuthContext";
import editorExtensions from '../../config/extensions'; 
import { useDocument } from '../../hooks/useDocument';
import { useWebSocket } from '../../hooks/useWebSocket';
import { EditorHeader } from './editor/EditorHeader';
import { EditorToolbar } from './editor/EditorToolbar';
import { EditorContent } from './editor/EditorContent';
import { EditorFooter } from './editor/EditorFooter';
import { ErrorDisplay } from './editor/ErrorDisplay';
import { downloadDocument } from '../../utils/utils';

const DocumentEditor = ({ initialContent, onClose, existingDocument = null }) => {
  const { user } = useAuth();
  const [saving, setSaving] = useState(false);
  const [activeUsers, setActiveUsers] = useState([]);
  const [error, setError] = useState(null);
  const [exporting, setExporting] = useState(false);

  const {
    documentId,
    documentName,
    setDocumentName,
    documentCreated,
    error: documentError,
    updateTitle
  } = useDocument({ existingDocument, initialContent });

  const handleEditorUpdate = ({ editor }) => {
    if (!editor || !documentCreated || !documentId) return;

    setSaving(true);
    const content = editor.getHTML();

    if (ws?.readyState === WebSocket.OPEN) {
      ws.send(
        JSON.stringify({
          type: "change",
          changes: {
            content,
            documentId,
            timestamp: new Date().toISOString(),
          },
        })
      );
    }

    const saveContent = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/collaborative-documents/${documentId}/content`,
          {
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ content }),
          }
        );

        const data = await response.json();
        if (!response.ok)
          throw new Error(data.error || "Failed to update document");

        setSaving(false);
      } catch (err) {
        console.error("Failed to save:", err);
        setError(err.message || "Failed to save changes");
        setSaving(false);
      }
    };

    const timeoutId = setTimeout(saveContent, 1000);
    return () => clearTimeout(timeoutId);
  };

  const editor = useEditor({
    extensions: editorExtensions,
    content: existingDocument?.content || initialContent,
    editorProps: {
      attributes: {
        class: "prose dark:prose-invert max-w-none focus:outline-none",
      },
    },
    onUpdate: handleEditorUpdate,
    // Add document ID to editor storage for auto-completion
    onCreate: ({ editor }) => {
      editor.storage.document = {
        id: documentId
      };
    }
  });

  // Update document ID in editor storage when it changes
  React.useEffect(() => {
    if (editor && documentId) {
      editor.storage.document = {
        id: documentId
      };
    }
  }, [editor, documentId]);

  const { isConnected, error: wsError, ws } = useWebSocket({
    documentId,
    user,
    editor,
    onActiveUsersChange: setActiveUsers
  });

  const handleDownload = useCallback(async (format) => {
    try {
      setExporting(true);
      setError(null);
      const content = editor?.getHTML() || "";
      await downloadDocument(content, documentId, documentName, format);
    } catch (err) {
      console.error("Export failed:", err);
      setError("Failed to export document. Please try again.");
    } finally {
      setExporting(false);
    }
  }, [editor, documentId, documentName]);

  const handleTitleChange = (e) => {
    const newTitle = e.target.value;
    setDocumentName(newTitle);
    updateTitle(newTitle);
  };

  if (!documentId) return null;

  return (
    <div className="h-full flex flex-col bg-white dark:bg-gray-900">
      <EditorHeader
        documentName={documentName}
        onTitleChange={handleTitleChange}
        isConnected={isConnected}
        activeUsers={activeUsers}
        saving={saving}
        onClose={onClose}
      />
      <EditorToolbar editor={editor} />
      <EditorContent 
        editor={editor} 
        documentId={documentId}
      />
      <EditorFooter 
        onClose={onClose} 
        onDownload={handleDownload}
        isExporting={exporting}
      />
      {(documentError || wsError || error) && (
        <ErrorDisplay error={documentError || wsError || error} />
      )}
    </div>
  );
};

export default DocumentEditor;