// src/hooks/useDocument.js
import { useState, useCallback, useEffect } from 'react';
import { generateDefaultTitle, DEFAULT_EDITOR_CONTENT } from '../constants/constants';

export const useDocument = ({ existingDocument, initialContent }) => {
  const [documentId, setDocumentId] = useState(existingDocument?.documentId || null);
  const [documentName, setDocumentName] = useState(
    existingDocument?.title || generateDefaultTitle()
  );
  const [documentCreated, setDocumentCreated] = useState(!!existingDocument);
  const [error, setError] = useState(null);

  useEffect(() => {
    let mounted = true;

    const initializeDocument = async () => {
      if (documentId || !mounted) return;
      
      if (existingDocument) {
        setDocumentId(existingDocument.documentId);
        setDocumentName(existingDocument.title);
        setDocumentCreated(true);
        return;
      }

      try {
        const token = localStorage.getItem("token");
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/collaborative-documents`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              title: documentName,
              content: initialContent || DEFAULT_EDITOR_CONTENT,
            }),
          }
        );

        const data = await response.json();
        if (!response.ok) throw new Error(data.error || "Failed to create document");

        if (mounted) {
          setDocumentId(data.documentId);
          setDocumentCreated(true);
          setDocumentName(data.title || documentName);
        }
      } catch (err) {
        if (mounted) {
          console.error("Failed to create document:", err);
          setError("Failed to create document: " + err.message);
        }
      }
    };

    initializeDocument();
    return () => { mounted = false; };
  }, [existingDocument, documentId, documentName, initialContent]);

  const updateTitle = useCallback(async (newTitle) => {
    if (!documentCreated) return;

    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/collaborative-documents/${documentId}/title`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ title: newTitle }),
        }
      );

      const data = await response.json();
      if (!response.ok) throw new Error(data.error);
    } catch (err) {
      console.error("Failed to update title:", err);
      setError("Failed to update title");
    }
  }, [documentCreated, documentId]);

  return {
    documentId,
    documentName,
    setDocumentName,
    documentCreated,
    error,
    updateTitle
  };
};