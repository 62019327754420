import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  ChevronLeft,
  Edit2,
  File,
  FileText,
  Loader,
  Search,
  Trash2,
  Upload,
  BarChart as ChartIcon,
  MessageSquare,
} from "lucide-react";
import { useAuth } from "../../context/AuthContext";
import ChatInterface from "../ChatInterface";
import EditProjectModal from "../EditProjectModal";
import UploadModal from "../UploadModal";
import ProjectChatStats from "./ProjectChatStats";

const TABS = {
  CHAT: "Project Chat",
  DOCUMENTS: "Documents",
  STATS: "Statistics"
};

const ProjectDetails = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(TABS.CHAT);
  const [searchQuery, setSearchQuery] = useState("");
  const [statsRefreshTrigger, setStatsRefreshTrigger] = useState(0);

  const fetchProjectDetails = useCallback(async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/projects/${projectId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 401) {
        logout();
        navigate("/login");
        return;
      }

      const data = await response.json();
      if (!response.ok) throw new Error(data.error);

      setProject(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, [projectId, logout, navigate]);

  useEffect(() => {
    fetchProjectDetails();
  }, [fetchProjectDetails]);

  const onUploadComplete = () => {
    fetchProjectDetails();
    setActiveTab(TABS.CHAT);
  };

  const handleChatUpdate = () => {
    setStatsRefreshTrigger(prev => prev + 1);
    fetchProjectDetails();
  };

  const handleDeleteProject = async () => {
    if (
      !window.confirm(
        "Are you sure you want to delete this project? All associated documents and conversations will be permanently deleted."
      )
    ) {
      return;
    }

    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/projects/${projectId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 401) {
        logout();
        navigate("/login");
        return;
      }

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.error);
      }

      navigate("/projects");
    } catch (err) {
      setError(err.message);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <Loader className="h-8 w-8 animate-spin text-[#464EB8]" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-6 bg-red-50 text-red-600 rounded-lg">
        <p className="font-medium">{error}</p>
        <button
          onClick={() => navigate("/projects")}
          className="mt-4 text-[#464EB8] hover:text-[#373E93] flex items-center"
        >
          <ChevronLeft size={16} className="mr-1" />
          Back to Projects
        </button>
      </div>
    );
  }

  const canEdit = project?.user === user._id;

  const renderDocumentsList = () => {
    const documents = project?.documents || [];
    const uniqueDocs = Array.from(new Map(documents.map(doc => [doc.documentId, doc])).values());
   
    const filteredDocs = uniqueDocs.filter((doc) => {
      if (!doc?.fileName) return false;
      return doc.fileName.toLowerCase().includes(searchQuery.toLowerCase());
    });
   
    if (filteredDocs.length === 0) {
      return (
        <div className="text-center py-12">
          <FileText className="mx-auto h-12 w-12 text-gray-400 mb-4" />
          <p className="text-gray-600 dark:text-gray-400">
            {searchQuery ? "No documents found matching your search" : "No documents in this project yet"}
          </p>
        </div>
      );
    }
   
    return (
      <div className="grid gap-4 grid-cols-1 lg:grid-cols-2">
        {filteredDocs.map((doc) => (
          <div
            key={doc.documentId}
            className="bg-white dark:bg-gray-800 rounded-lg p-4 border border-gray-200 dark:border-gray-700"
          >
            <div className="flex items-start gap-3">
              <div className="p-2 bg-blue-50 dark:bg-blue-900/20 rounded-lg">
                <File className="h-6 w-6 text-blue-600 dark:text-blue-400" />
              </div>
              <div className="flex-1 min-w-0">
                <h3 className="font-medium text-gray-900 dark:text-white truncate">
                  {doc.fileName}
                </h3>
                <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                  {doc.createdAt 
                    ? new Date(doc.createdAt).toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric'
                      })
                    : 'Date not available'
                  }
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderTabIcon = (tab) => {
    switch (tab) {
      case TABS.CHAT:
        return <MessageSquare size={16} />;
      case TABS.DOCUMENTS:
        return <FileText size={16} />;
      case TABS.STATS:
        return <ChartIcon size={16} />;
      default:
        return null;
    }
  };

  return (
    <div className="h-full flex flex-col">
      {/* Fixed Header */}
      <div className="sticky top-0 z-10 bg-gray-50 dark:bg-gray-900 pb-6">
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm p-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-4">
              <button
                onClick={() => navigate("/projects")}
                className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg text-gray-600 dark:text-gray-400 transition-colors"
              >
                <ChevronLeft size={20} />
              </button>
              <div>
                <h1 className="text-xl font-semibold text-gray-900 dark:text-white">
                  {project.name}
                </h1>
                {project.description && (
                  <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                    {project.description}
                  </p>
                )}
              </div>
            </div>

            <div className="flex items-center gap-2">
              <div className="flex items-center px-3 py-1.5 bg-gray-100 dark:bg-gray-700 rounded-lg text-sm text-gray-600 dark:text-gray-400">
                <FileText size={16} className="mr-2" />
                {project.documentCount || 0} document
                {project.documentCount !== 1 ? "s" : ""}
              </div>
              {canEdit && (
                <>
                  <button
                    onClick={() => setIsEditModalOpen(true)}
                    className="p-2 text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors"
                    title="Edit project"
                  >
                    <Edit2 size={20} />
                  </button>
                  <button
                    onClick={handleDeleteProject}
                    className="p-2 text-gray-600 dark:text-gray-400 hover:bg-red-100 dark:hover:bg-red-900/50 hover:text-red-600 dark:hover:text-red-400 rounded-lg transition-colors"
                    title="Delete project"
                  >
                    <Trash2 size={20} />
                  </button>
                </>
              )}
            </div>
          </div>

          {/* Tabs */}
          <div className="flex space-x-4 mt-6">
            {Object.entries(TABS).map(([key, tab]) => (
              <button
                key={key}
                onClick={() => setActiveTab(tab)}
                className={`px-4 py-2 text-sm font-medium rounded-lg transition-colors flex items-center gap-2 ${
                  activeTab === tab
                    ? "bg-[#464EB8] text-white"
                    : "text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700"
                }`}
              >
                {renderTabIcon(tab)}
                {tab}
              </button>
            ))}
          </div>
        </div>
      </div>

      {/* Main Content Area */}
      <div className="flex-1 overflow-auto">
        <div className="bg-gray-50 dark:bg-gray-900 rounded-lg min-h-0">
          {activeTab === TABS.CHAT && project.projectWideConversation && (
            <ChatInterface
              conversationId={project.projectWideConversation.conversationId}
              projectId={project.projectId}
              isProjectWide={true}
              currentConversation={project.projectWideConversation}
              onChatUpdate={handleChatUpdate}
            />
          )}

          {activeTab === TABS.DOCUMENTS && (
            <div className="p-6">
              {/* Search and Upload */}
              <div className="flex items-center justify-between gap-4 mb-6">
                <div className="flex-1 max-w-lg relative">
                  <Search
                    className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                    size={18}
                  />
                  <input
                    type="text"
                    placeholder="Search documents..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="w-full pl-10 pr-4 py-2 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#464EB8] dark:focus:ring-[#464EB8] focus:border-transparent"
                  />
                </div>
                <button
                  onClick={() => setIsUploadModalOpen(true)}
                  className="flex items-center px-4 py-2 bg-[#464EB8] text-white rounded-lg hover:bg-[#373E93] transition-colors"
                >
                  <Upload size={18} className="mr-2" />
                  Upload Document
                </button>
              </div>

              {/* Documents List */}
              {renderDocumentsList()}
            </div>
          )}

          {activeTab === TABS.STATS && (
            <div className="p-6">
              <ProjectChatStats 
                projectId={projectId} 
                refreshTrigger={statsRefreshTrigger}
              />
            </div>
          )}
        </div>
      </div>

      {/* Modals */}
      {canEdit && (
        <EditProjectModal
          isOpen={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          project={project}
          onUpdate={setProject}
        />
      )}

      <UploadModal
        isOpen={isUploadModalOpen}
        onClose={() => setIsUploadModalOpen(false)}
        projectId={projectId}
        projectName={project.name}
        onUploadComplete={onUploadComplete}
      />
    </div>
  );
};

export default ProjectDetails;